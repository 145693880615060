import ApiUrls from 'src/constants/apiUrls';
import axios from 'src/http/index';

export default class mediaPlanService {
  static async getMediaPlanList(clientId, pageNum) {
    const res = await axios.get(ApiUrls.GET_MEDIA_PLAN(clientId, pageNum));
    return res.data;
  }
  static async getMediaPlanBasicInfo(mediaPlanId) {
    const url = ApiUrls.GET_MEDIA_PLAN_BASIC_INFO(mediaPlanId);
    const response = await axios.get(url);
    return response.data;
  }
  static async createMediaplanRecommendation(payload) {
    const url = ApiUrls.CREATE_MEDIAPLAN_RECOMMENDATION;
    const response = await axios.post(url, payload);
    return response.data;
  }
  static async updateMediaPlanAudience(payload) {
    const url = ApiUrls.UPDATE_MEDIA_PLAN_AUDIENCE;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async triggerRecommendation(payload) {
    const url = ApiUrls.TRIGGER_RECOMMENDATION;
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getMediaPlanFlightingResponse(responseId) {
    const url = ApiUrls.GET_MEDIA_PLAN_FLIGHTING_RESPONSE(responseId);
    const response = await axios.get(url);
    return response.data;
  }
  static async getMetaData(clientId) {
    const url = ApiUrls.GET_MEDIA_PLAN_META_DATA(clientId);
    const response = await axios.get(url);
    return response.data;
  }

  static async getMediaPlanAudienceInfo(mediaPlanId) {
    const url = ApiUrls.GET_MEDIA_PLAN_AUDIENCE_INFO(mediaPlanId);
    const response = await axios.get(url);
    return response.data;
  }

  static async deleteMediaPlanAudience(mediaPlanAudienceId) {
    const url = ApiUrls.DELETE_MEDIA_PLAN_AUDIENCE(mediaPlanAudienceId);
    const response = await axios.delete(url);
    return response.data;
  }

  static async getMediaPlanScenario(mediaPlanId) {
    const url = ApiUrls.GET_MEDIA_PLAN_SCENARIO(mediaPlanId);
    const response = await axios.get(url);
    return response.data;
  }

  static async getMediaPlanOutput(payload) {
    const url = ApiUrls.GET_MEDIA_PLAN_OUTPUT;
    const response = await axios.post(url, payload);
    return response.data;
  }
}
