import { Col, Form, InputNumber, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomGptService } from '../services/customGptServices';
import Style from './createForm.style';

export default function LlmConfig({ metaData, setChosenLLM }) {
  const { t } = useTranslation();
  return (
    <div>
      <div className={Style.advancedSettingHeader}>{t('client_space.llm_configuration')}</div>
      <Row gutter={[30, 0]} style={{ margin: '0 6px' }}>
        <Col xl={12} xs={24}>
          <Form.Item
            name={['llm_config', 'model_name']}
            label={t('client_space.model_name')}
            rules={CustomGptService.validations({ required: true }, 'Model name')}
          >
            <Select
              onChange={(value) => {
                setChosenLLM(value);
              }}
            >
              {metaData?.llm_config?.model_name?.choices?.map((item, idx) => {
                return (
                  <Select.Option value={item} key={`meta-data-${idx}`}>
                    {item}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            name={['llm_config', 'temperature']}
            label={t('client_space.temprature')}
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              'Temperature',
              metaData?.llm_config?.temperature
            )}
          >
            <InputNumber max={1} min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col xl={12} xs={24}>
          <Form.Item
            name={['llm_config', 'max_tokens']}
            rules={CustomGptService.validations(
              { required: true, max_length_number: true },
              'Maximum output words',
              metaData?.llm_config?.max_tokens
            )}
            label={t('client_space.tokens')}
          >
            <InputNumber max={4096} min={0} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
}
