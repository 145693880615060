import { useEffect, useState } from 'react';
import audienceListService from '../../../services/audienceListService';

export default function useAudienceOverviewHook(audienceProfileId) {
  const [overViewIndexingTraitData, setOverviewIndexingTraitData] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [featureSortBy, setFeatureSortBy] = useState('-index_value');
  const [featureListTotalCount, setFeatureListTotalCount] = useState(0);
  const [isFeatureLoading, setIsFeatureLoading] = useState(false);
  const [overViewFeatureType, setOverViewFeatureType] = useState('');

  const getFeatureData = async (orderBy, featureName, featureType, groupByParent) => {
    const profileId = audienceProfileId;
    if (profileId === null) {
      return;
    }
    setIsFeatureLoading(true);
    const featureData = await audienceListService.getAudienceFeatureProfiles(
      profileId,
      orderBy,
      featureName,
      currentPage,
      featureType,
      groupByParent
    );
    setFeatureListTotalCount(featureData.data.total);
    setOverviewIndexingTraitData(featureData.data.data);
    setIsFeatureLoading(false);
  };

  useEffect(() => {
    getFeatureData(featureSortBy, selectedFeature, overViewFeatureType, false);
  }, [currentPage, featureSortBy]);

  return {
    overViewIndexingTraitData,
    setSelectedFeature,
    currentPage,
    setCurrentPage,
    setFeatureSortBy,
    featureSortBy,
    featureListTotalCount,
    isFeatureLoading,
    overViewFeatureType,
    setOverViewFeatureType,
    getFeatureData,
    selectedFeature,
  };
}
