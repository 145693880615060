import { useEffect, useState } from 'react';

const useFeatureSelectionHook = (selectedCategory, swapBeahaviouralFeatures, onClose) => {
  const [drawerSelectedFeatures, setDrawerSelectedFeatures] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const featureIds = selectedCategory.features
      .filter((feature) => feature.is_hidden === false)
      .map((feature) => feature.id);
    setDrawerSelectedFeatures(featureIds);
  }, [selectedCategory]);

  const handleCheckboxChange = (checked, featureId) => {
    setDrawerSelectedFeatures((prevSelected) => {
      if (checked) {
        return prevSelected.length < 5 ? [...prevSelected, featureId] : prevSelected;
      } else {
        return prevSelected.filter((id) => id !== featureId);
      }
    });
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRemoveItem = (featureId) => {
    setDrawerSelectedFeatures((prevSelected) => prevSelected.filter((id) => id !== featureId));
  };

  const handleConfirm = () => {
    swapBeahaviouralFeatures(selectedCategory.id, drawerSelectedFeatures);
    onClose();
  };

  const filteredFeatures = selectedCategory.features.filter((feature) =>
    feature.feature_readable_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return {
    drawerSelectedFeatures,
    searchQuery,
    handleCheckboxChange,
    handleSearchChange,
    handleRemoveItem,
    handleConfirm,
    filteredFeatures,
  };
};

export default useFeatureSelectionHook;
