import { formatNumber, formatNumberWithPercentageAndCeil } from 'src/lib/utils';

export const AUDIENCE_INDEX_COLUMNS = [
  {
    title: 'Feature',
    dataIndex: 'feature_readable_name',
    key: 'feature_readable_name',
    render: (text, record) => `${record.feature_readable_name} - ${record.feature_readable_value}`,
  },
  {
    title: 'Audience Percentage',
    dataIndex: 'audience_feature_percentage',
    key: 'audience_feature_percentage',
    render: (text) => `${text === 0 ? '0%' : formatNumberWithPercentageAndCeil(text)}`,
    sorter: true,
  },
  {
    title: 'Index',
    dataIndex: 'index_value',
    key: 'index_value',
    render: (text) => `${text === 0 ? '0' : formatNumber(text)}`,
    sorter: true,
  },
  {
    title: 'Source',
    dataIndex: 'feature_source',
    key: 'feature_source',
  },
];

export const DEMOGRAPHIC = 'DEMOGRAPHIC';
export const BEHAVIOURAL = 'BEHAVIOURAL';
export const BRAND_AFFINITY = 'BRAND_AFFINITY';
export const GEOGRAPHIC = 'GEOGRAPHIC';
export const DMA = 'DMA';
export const COUNTY = 'County';
export const STATE = 'State';
export const ATTITUDES = [
  'Attitudes on Advertising',
  'Attitudes on Automotive',
  'Attitudes on Buying styles',
  'Attitudes on Fashion & style',
  'Attitudes on Finance',
  'Attitudes on Food',
  'Attitudes on Health',
  'Attitudes on Media',
  'Attitudes General',
  'Attitudes on Shopping',
  'Attitudes on Social media',
  'Attitudes on Technology',
  'Attitudes on Vacation Travel',
];
export const INDEX_VALUE = 'Index Value';
export const PENETRATION_PERCENTAGE = 'Penetration Percentage';
export const AUDIENCE_FEATURE_PERCENTAGE = 'Audience Feature Percentage';
