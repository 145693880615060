import { Input, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Assets from 'src/assets';
import { PrimaryButton } from 'src/components/basic';
import AssistantIconsChoices from '../constant/assistantIconsChoices';
import Style from './assistantIconPicker.style';

const AssistantIconPicker = ({ originalIconName, updateIconNameHandler }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResult, setSearchResult] = useState([]);
  const [searchMinLength] = useState(2);
  const [selectedIconName, setSelectedIconName] = useState(originalIconName);

  const closeModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    if (searchKey.length >= searchMinLength) {
      let filteredIcon = [];
      AssistantIconsChoices.forEach((group, index) => {
        let results = group.icons.filter((name) => {
          return name.includes(searchKey);
        });
        filteredIcon.push(results);
        if (index === AssistantIconsChoices.length - 1) {
          setSearchResult(filteredIcon.flat());
        }
      });
    } else {
      setSearchResult([]);
    }
  }, [searchKey, searchMinLength]);

  const selectIconHandler = (iconName) => {
    setSelectedIconName(iconName);
  };

  const renderIconButton = (iconName, index) => {
    return (
      <button
        key={`assistant-icon-choice-button-${index}`}
        id={iconName}
        className={
          Style.iconChoiceBtn + ` material-symbols-outlined ${selectedIconName === iconName ? ' selected' : ''}`
        }
        onClick={(e) => {
          selectIconHandler(e.target.id);
        }}
      >
        {iconName}
      </button>
    );
  };

  return (
    <>
      <div
        className={Style.assistantPickerButton}
        onClick={() => {
          setModalOpen(true);
        }}
      >
        {t('client_space.choose_an_icon_for_your_assistant')}
        <img className={Style.plusIcon} alt="plus icon" src={Assets.getImage('CirclePlusIcon')} />
      </div>
      <Modal
        maskClosable={true}
        destroyOnClose
        open={modalOpen}
        onCancel={closeModal}
        footer={null}
        className={Style.assistantIconPickerModal}
      >
        <Input
          placeholder={t('search_icons')}
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        ></Input>
        <div className={Style.assistantIconPickerContent}>
          {searchKey.length >= searchMinLength && searchResult.length === 0 && <p>{t('client_space.no_results')}</p>}
          {searchKey.length < searchMinLength ? (
            <>
              {AssistantIconsChoices?.map((group, index) => {
                return (
                  <div className={Style.iconGroupContainer} key={`assistant-icon-choice-group-${index}`}>
                    <p>{group?.name}</p>
                    <div className={Style.iconContainer}>
                      {group?.icons?.map((iconName, index) => {
                        return renderIconButton(iconName, index);
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className={Style.iconContainer}>
              {searchResult.map((iconName, index) => {
                return renderIconButton(iconName, index);
              })}
            </div>
          )}
        </div>
        <PrimaryButton
          onClick={() => {
            if (updateIconNameHandler) {
              updateIconNameHandler(selectedIconName);
            }
            closeModal();
          }}
        >
          {t('client_space.save_icon')}
        </PrimaryButton>
      </Modal>
    </>
  );
};

export default AssistantIconPicker;
