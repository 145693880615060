import { Form, Input, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';

const { Text } = Typography;

const BasicInfoForm = () => {
  const { t } = useTranslation();
  return (
    <div className={Styles.formContainer}>
      {/* Guide section */}
      <div className={Styles.guideSection}>
        <h2 className={Styles.title}>{t('labeler.analysis_title')}</h2>
        <Text type="secondary" className={Styles.subtitle}>
          {t('labeler.analysis_subtitle')}
        </Text>
      </div>

      {/* Form section */}
      <div className={Styles.formSection}>
        <Form.Item
          label={t('labeler.analysis_name')}
          name="name"
          required
          tooltip="Give your analysis a clear, descriptive name"
          rules={[{ required: true, message: t('labeler.analysis_name_error') }]}
        >
          <Input placeholder={t('labeler.analysis_placeholder')} className={Styles.input} />
        </Form.Item>

        <Form.Item label={t('description')} name="description" tooltip={t('labeler.analysis_description_tooltip')}>
          <Input.TextArea
            rows={4}
            placeholder={t('labeler.analysis_desription_placeholder')}
            className={Styles.textarea}
          />
        </Form.Item>
      </div>
    </div>
  );
};

export default BasicInfoForm;
