import React, { useState } from 'react';
import { Table } from 'antd';
import CustomPagination from './Pagination';

const ListingTable = ({ data, onPaginationChange, currentPage, loading, columns }) => {
  const [openActionRow, setOpenActionRow] = useState(null); // Track which row's action menu is open

  // Handle action menu toggle for each row based on its unique id
  const toggleActionMenu = id => {
    setOpenActionRow(openActionRow === id ? null : id); // Toggle the menu for the specific row
  };

  // Close all action menus
  const closeActionMenu = () => {
    setOpenActionRow(null); // Close the menu when clicking outside
  };

  return (
    <div>
      <Table
        className="dark-table"
        columns={columns(openActionRow, toggleActionMenu, closeActionMenu)}
        dataSource={data?.data}
        pagination={false} // Disable default pagination
        rowKey="id" // Use 'id' as the unique row key
        loading={loading}
      />
      <CustomPagination
        currentPage={currentPage}
        data={data}
        onPaginationChange={onPaginationChange}
        pageSize={data?.per_page}
        showSizeChanger={false}
      />
    </div>
  );
};

export default ListingTable;
