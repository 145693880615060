import React from 'react';
import LoadingSpinner from 'src/components/loadingSpinner';
import TopFeatureIndexView from './TopFeatureIndexView';
import useAttitudinalOverviewHook from './useAttitudinalOverviewHook';

const AttitudinalOverview = ({ audienceId, behaviourFeaturesCategories, activeTab }) => {
  const { attitudinalOverviewLoading, attitudinalOverviewData, replaceBehaviouralFeature, swapBeahaviouralFeatures } =
    useAttitudinalOverviewHook({ audienceId, activeTab });

  if (attitudinalOverviewLoading) {
    return <LoadingSpinner centered />;
  }

  return (
    <>
      <p style={{ textAlign: 'left', fontSize: '16px', marginTop: '20px', marginLeft: '20px', color: 'white' }}>
        Overview of consumer attitudes and behaviors, showcasing how common certain behaviors are within the audience
        (penetration) and their relative strength compared to a baseline (index). This information provides valuable
        insights into consumer tendencies and preferences, helping to understand how they engage with various aspects of
        their environment.
      </p>
      <TopFeatureIndexView
        data={attitudinalOverviewData}
        behaviourFeaturesCategories={behaviourFeaturesCategories}
        replaceFunction={replaceBehaviouralFeature}
        swapBeahaviouralFeatures={swapBeahaviouralFeatures}
        forAttitudes={true}
      />
    </>
  );
};
export default AttitudinalOverview;
