import React, { useContext, useEffect, useRef, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ConditionalRender from 'src/components/conditionalRender';
import AppUrls from 'src/constants/appUrls';
import AppContext from "src/context/appContext";
import { PageLayoutContext } from 'src/context/pageLayoutContext';
import Cookie from 'src/lib/cookie';
import { checkBuildNumber } from 'src/lib/utils';
import AddEditClientSpace from './addNewClientSpace/components/AddEditClientSpace';
import ClientSpaceContainer from './clientSpace/ClientSpaceContainer';
import Disclaimer from './disclaimer/Disclaimer';
import FixedTopBar from './fixedTopBar/FixedTopBar';
import Footer from './footer/Footer';
import HomePage from './homePage/components/homepage/HomePage';
import { useHomePage } from './homePage/hooks/useHomePage';
import Style from './loggedInLayout.style';

function LoggedInLayout({ history, match }) {
  const appContext = React.useContext(AppContext.Context);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const { inChatMode, inClientSpaceMode } = useContext(PageLayoutContext);
  const { loading, allClients, addUpdateClient } = useHomePage({ appContext });
  const lastCheckedRef = useRef(0);

  useEffect(() => {
    const cookies = Cookie.get('Disclaimer');
    if (cookies) {
      setOpenDisclaimer(false);
    } else {
      const d = new Date();
      d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
      Cookie.set('Disclaimer', 'true', d);
      setOpenDisclaimer(true);
    }
  }, []);

  useEffect(() => {
    const checkBuildNumberWithDelay = () => {
      const fiveMinutes = 5 * 60 * 1000;
      const now = Date.now();
      if (lastCheckedRef.current === 0 || (now - lastCheckedRef.current >= fiveMinutes)) {
        checkBuildNumber();
        lastCheckedRef.current = now;
      }
    };
    checkBuildNumberWithDelay();
  }, [match.url]);


  useEffect(() => {
    if (!loading && allClients && allClients.length === 1 && !appContext.metaData?.email.includes("galepartners.com")) {
      history.push(AppUrls.CLIENT_HOME(allClients[0]?.id));
    }
  }, [loading, allClients]);


  const handleClose = () => {
    setOpenDisclaimer(false);
  };
  return (
    <>
      <div className={`${Style.contentContainer}`}>
        <FixedTopBar history={history} match={match} />
        <div className={Style.content}>
          <Switch>
            <Route path={AppUrls.HOMEPAGE} children={<HomePage history={history} loading={loading} allClients={allClients} />} />
            <Route path={AppUrls.CREATE_CLIENT} children={<AddEditClientSpace history={history} addUpdateClient={addUpdateClient} />} allClients={allClients} />
            <Route
              path={AppUrls.CLIENT_HOME(':clientId')}
              children={<ClientSpaceContainer history={history} match={match} allClients={allClients} addUpdateClient={addUpdateClient} />}
            />
            <Redirect from='*' to={AppUrls.HOMEPAGE} />
          </Switch>
        </div>
        <ConditionalRender shouldRender={!inChatMode}>
          <Footer transparent={false} relativePosition={inClientSpaceMode} />
        </ConditionalRender>
      </div>
      <Disclaimer modalOpen={openDisclaimer} closeModal={handleClose} />
    </>
  );
}

export default LoggedInLayout;
