import { PlusOutlined } from '@ant-design/icons';
import { Col, Drawer, Input, Modal, Row, Select, Table } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import LoadingSpinner from 'src/components/loadingSpinner';
import AppUrls from 'src/constants/appUrls';
import { removeUnderscores } from 'src/lib/utils';
import { AUDIENCE_STATUS_OPTIONS, FILTER_OPTIONS } from '../../constants/constants';
import AudienceCloneForm from './AudienceCloneForm';
import './audienceListing.style.less';
import AudienceUsersAndPermissions from './AudienceUsersAndPermissions';
import useAudienceListingHook from './useAudienceListingHook';

const { Option } = Select;

export default function AudienceListing({ history, match }) {
  const id = match.params.clientId;
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const {
    loading,
    audienceListData,
    audienceListTotal,
    setCurrentPage,
    currentPage,
    setSearchName,
    filterType,
    setFilterType,
    debouncedSearch,
    statusFilter,
    setStatusFilter,
    cloneModal,
    setCloneModal,
    closeCloneModal,
    handleClone,
    handleAudienceEdit,
    closeUsersAndPermissionsDrawer,
    usersAndPermissionsDrawer,
    getColumns,
    userLists,
    handleClusterClone,
  } = useAudienceListingHook(id, history);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    debouncedSearch.current = setTimeout(() => {
      if (debouncedSearch.current) {
        clearTimeout(debouncedSearch.current);
        setSearchName(e.target.value);
      }
    }, 2000);
  };

  const handleFilterTypeChange = (value) => {
    setFilterType(value);
  };

  const handleStatusFilterChange = (value) => {
    setStatusFilter(value);
  };

  if (loading && audienceListData.length === 0) {
    return <LoadingSpinner centered />;
  }

  return (
    <div style={{ color: '#fff', padding: '20px' }}>
      <Row style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Col>
          <h1 style={{ color: '#fff' }}>{t('audience.audience_listing')}</h1>
        </Col>
        <Col>
          <PrimaryButton onClick={() => history.push(AppUrls.CREATE_AUDIENCE(id))}>
            {t('create')}
            <PlusOutlined />
          </PrimaryButton>
        </Col>
      </Row>
      <Row style={{ marginBottom: '20px', alignItems: 'center' }}>
        <Col span={5} style={{ paddingRight: '10px' }}>
          <label style={{ color: '#fff', marginRight: '8px' }}>{t('audience.search_by_name')}:</label>
          <Input
            placeholder={t('audience.search_by_name')}
            value={search}
            onChange={handleSearch}
            style={{ width: '100%' }}
            disabled={loading}
          />
        </Col>
        <Col span={5} style={{ paddingRight: '10px' }}>
          <label style={{ color: '#fff', marginRight: '8px' }}>{t('audience.filter_by_status')}:</label>
          <Select
            placeholder={t('audience.select_status')}
            value={statusFilter}
            onChange={handleStatusFilterChange}
            style={{ width: '100%', marginRight: '10px' }}
            disabled={loading}
          >
            {AUDIENCE_STATUS_OPTIONS.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={5} style={{ paddingRight: '10px' }}>
          <label style={{ color: '#fff', marginRight: '8px' }}>{t('audience.filter_by_type')}:</label>
          <Select
            placeholder={t('audience.filter_by_type')}
            value={filterType}
            onChange={handleFilterTypeChange}
            style={{ width: '100%' }}
            disabled={loading}
          >
            {FILTER_OPTIONS.map((option) => (
              <Option key={option.value} value={option.value}>
                {removeUnderscores(option.label)}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <Table
        columns={getColumns()}
        dataSource={audienceListData}
        className="dark-table-listing"
        pagination={{
          pageSize: 10,
          total: audienceListTotal,
          showSizeChanger: false,
          current: currentPage,
          position: ['bottomCenter'],
          onChange: (page) => setCurrentPage(page),
        }}
        loading={loading && audienceListData.length > 0}
        rowKey="key"
        childrenColumnName="audienceProfiles"
      />

      <Modal
        maskClosable
        destroyOnClose
        open={cloneModal.visible}
        onCancel={() => setCloneModal({ visible: false, audienceProjectId: null, name: '' })}
        footer={null}
        title={cloneModal?.name}
      >
        <AudienceCloneForm
          data={cloneModal}
          closeCloneModal={closeCloneModal}
          onClone={handleClone}
          handleAudienceEdit={handleAudienceEdit}
          handleClusterClone={handleClusterClone}
        />
      </Modal>
      <Drawer
        width="70%"
        title={usersAndPermissionsDrawer?.data?.name}
        placement="right"
        destroyOnClose
        onClose={closeUsersAndPermissionsDrawer}
        open={usersAndPermissionsDrawer.visible}
        maskClosable={false}
      >
        <AudienceUsersAndPermissions data={usersAndPermissionsDrawer.data} userLists={userLists} />
      </Drawer>
    </div>
  );
}
