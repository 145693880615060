import {
    CheckOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    EyeOutlined,
    FileOutlined,
    PlayCircleOutlined,
    SyncOutlined
} from "@ant-design/icons";
import { Progress, Space, Table, Tooltip } from 'antd';
import React from 'react';
import { Link } from "react-router-dom";
import * as TableStyle from './styles';

function ListingTable({
    clientId,
    tableDataSource,
    onPageChange,
    total,
    currentPage,
    loading,
    onTriggerTask
}) {
    const PAGE_SIZE = 10;

    const statusConfig = {
        DRAFT: {
            icon: <FileOutlined />,
            color: "rgba(245, 121, 0, 0.8)",
            text: "Draft",
        },
        SCHEDULED: {
            icon: <ClockCircleOutlined />,
            color: "rgba(0, 172, 193, 0.8)",
            text: "Scheduled",
        },
        RUNNING: {
            icon: <SyncOutlined spin />,
            color: "rgba(0, 156, 255, 0.9)",
            text: "Running",
        },
        COMPLETED: {
            icon: <CheckOutlined />,
            color: "rgba(50, 205, 50, 0.8)",
            text: "Completed",
        },
        FAILED: {
            icon: <CloseOutlined />,
            color: "rgba(220, 20, 60, 0.8)",
            text: "Failed",
        },
    };

    const TABLE_COLUMNS = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <div className={TableStyle.nameSection}>
                    <Tooltip title={text}>
                    <Link
                            to={`/client/${clientId}/labeler/${record.id}`}
                            className={TableStyle.viewDetailsLink}
                        >
                        <span className={TableStyle.taskName}>{text}</span>
                    </Link>
                    </Tooltip>
                </div>
            ),
        },
        {
            title: 'Progress',
            dataIndex: 'progress',
            key: 'progress',
            render: (record, data) => {
                const { text, color } = statusConfig[data.status];
                return (
                    <Tooltip title={text}>
                        <div className={TableStyle.progressSection}>
                            <Progress
                                percent={record}
                                strokeColor={color}
                                trailColor="rgba(255,255,255,0.1)"
                                status={data.status === "RUNNING" ? "active" : "normal"}
                                format={(record) => (
                                    <span className={TableStyle.metaInfo}>{`${record}%`}</span>
                                )}
                            />
                        </div>
                    </Tooltip>
                );
            }
        },
        {
            title: 'Source',
            dataIndex: 'data_source',
            key: 'data_source',
            render: (record) => (
                <div className={TableStyle.dataSourceSection}>
                    <span className={TableStyle.metaInfo}>{record.type}</span>
                </div>
            )
        },
        {
            title: 'Created On',
            dataIndex: 'created_on',
            key: 'created_on',
            render: (text) => (
                <div className={TableStyle.dateSection}>
                    <span className={TableStyle.metaInfo}>{text}</span>
                </div>
            )
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <div className={TableStyle.actionSection}>
                    <Space size={16}>
                        <Link
                            to={`/client/${clientId}/labeler/${record.id}`}
                            className={TableStyle.viewDetailsLink}
                        >
                            <EyeOutlined /> View
                        </Link>
                        <Link
                            onClick={() => onTriggerTask?.(record.id)}
                            className={TableStyle.viewDetailsLink}
                            style={{ 
                                cursor: record.status === 'RUNNING' ? 'not-allowed' : 'pointer',
                                opacity: record.status === 'RUNNING' ? 0.5 : 1
                            }}
                        >
                            <PlayCircleOutlined /> Run
                        </Link>
                    </Space>
                </div>
            )
        }
    ];

    return (
        <Table
            columns={TABLE_COLUMNS}
            dataSource={tableDataSource}
            className="dark-table"
            style={{ backgroundColor: '#333', color: '#fff', marginBottom: '20px' }}
            pagination={{
                pageSize: PAGE_SIZE,
                total: total,
                showSizeChanger: false,
                current: currentPage,
                position: ['bottomCenter'],
                onChange: (page) => onPageChange(page)
            }}
            loading={loading}
        />
    );
}

export default ListingTable;
