import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Card, Col, DatePicker, Empty, Popover, Row, Select, Spin, Switch } from 'antd';
import ReactEcharts from 'echarts-for-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import AnalysisQuadrantsInsightsComponent from '../analysisQuadrantsInsights/analysisQuadrantsInsightsComponent';
import ArticlesTableDrawer from '../ArticlesTableDrawer';
import FloatLabel from '../floatLabel/floatLabel.component';
import useQuadrantChart from './analysisQuadrants.hooks';
import Style from './analysisQuadrants.style';
import dayjs from 'dayjs';
const { RangePicker } = DatePicker;

const { Option } = Select;

const QuadrantChartComponent = ({ analysisOverview }) => {
  const {
    echartsInstance,
    options,
    chartData,
    loading,
    filterData,
    handleFilterChange,
    handleApply,
    chartEvents,
    highlightedPost,
    setHighlightedPost,
    minStartDate,
    handlePopupScrollHandle,
    handleSearchHandle,
    handleDropdownVisibleChangeHandle,
    handles,
    loadingHandlesOptions,
    colorBy,
    setColorBy,
    sizeBy,
    setSizeBy,
    filteredHandles,
    appliedFilterData,
    handleFilterDataPopoverChange,
  } = useQuadrantChart(analysisOverview);
  const { t } = useTranslation();

  return (
    <div className={Style.tabContent}>
      <Card className={Style.analysisCardView}>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <div className={Style.summarizeButton}>
              <Col>
                <FloatLabel
                  label={t('news_and_culture.color_by')}
                  style={{ backgroundColor: 'var(--color-charcoal-surface-2)' }}
                  value={colorBy}
                  helpText={t('news_and_culture.color_by_help_text_other')}
                >
                  <Select
                    style={{ width: '145px' }}
                    onChange={(val) => setColorBy(val)}
                    value={colorBy}
                    popupClassName={Style.selectDropdown}
                  >
                    <Select.Option value={'platform'}>{t('news_and_culture.platform')}</Select.Option>
                    <Select.Option value={'section'}>{t('news_and_culture.sections')}</Select.Option>
                    <Select.Option value={'post_sentiment'}>{t('news_and_culture.post_sentiment')}</Select.Option>
                    <Select.Option value={'comment_sentiment'}>{t('news_and_culture.comment_sentiment')}</Select.Option>
                    {filteredHandles && filteredHandles.length > 0 && (
                      <Select.Option value={'handles'}>{t('news_and_culture.handles')}</Select.Option>
                    )}
                    {/* <Select.Option value={"comment_sentiment"}>Comment Sentiment</Select.Option> */}
                  </Select>
                </FloatLabel>
              </Col>
              <Col>
                <FloatLabel
                  label={t('news_and_culture.size_by')}
                  style={{ backgroundColor: 'var(--color-charcoal-surface-2)' }}
                  value={sizeBy}
                  helpText={t('news_and_culture.size_by_help_text')}
                >
                  <Select
                    style={{ width: '145px' }}
                    onChange={(val) => setSizeBy(val)}
                    value={sizeBy}
                    popupClassName={Style.selectDropdown}
                  >
                    <Select.Option value={'comments'}>{t('news_and_culture.comments')}</Select.Option>
                    <Select.Option value={'comments_normalized'}>
                      {t('news_and_culture.comments_by_volume')}
                    </Select.Option>
                  </Select>
                </FloatLabel>
              </Col>
              {/* <PrimaryButton onClick={() => setSummaryDrawer(true)}>Analyze Trends</PrimaryButton> */}
            </div>
          </Col>
        </Row>
        <Row gutter={[10, 10]} className={Style.knowledgeGraphFilters}>
          <Col span={4}>
            <Row gutter={[10, 10]}>
              <div className={Style.knowledgeGraphFiltersWrapper}>
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      height: '2rem',
                    }}
                  >
                    <h3 style={{ fontWeight: 'bold' }}>Filter by: </h3>
                  </div>
                </Col>
                <Col>
                  <FloatLabel
                    label={t('news_and_culture.volume')}
                    style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                    value={filterData.volume_metric}
                    helpText={t('news_and_culture.volume_help_text')}
                  >
                    <Select
                      value={filterData.volume_metric}
                      placeholder={t('news_and_culture.select_volume')}
                      className={Style.select}
                      onChange={(value) => handleFilterChange('volume_metric', value)}
                    >
                      <Option value="likes">{t('news_and_culture.likes')}</Option>
                      <Option value="impressions">{t('news_and_culture.impressions')}</Option>
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label="Efficiency"
                    style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                    value={filterData.efficiency_metric}
                    helpText="Select efficiency category."
                  >
                    <Select
                      value={filterData.efficiency_metric}
                      placeholder="Select Efficiency"
                      className={Style.select}
                      onChange={(value) => handleFilterChange('efficiency_metric', value)}
                    >
                      <Option value="comments">{t('news_and_culture.comments')}</Option>
                      <Option value="shares">{t('news_and_culture.shares')}</Option>
                      <Option value="reactions">{t('news_and_culture.reactions')}</Option>
                      <Option value="engagements">{t('news_and_culture.engagements')}</Option>
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label={t('news_and_culture.platform')}
                    style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                    value={filterData.platforms}
                    helpText={t('news_and_culture.platform_help_text')}
                  >
                    <Select
                      onChange={(value) => handleFilterChange('platforms', value)}
                      style={{ width: '100%' }}
                      value={filterData.platforms}
                      allowClear
                      mode="multiple"
                      maxTagCount={2}
                      maxTagPlaceholder={(omittedValues) => (
                        <Popover
                          color="black"
                          overlayClassName={Style.tagPopover}
                          content={
                            <div className={Style.tagWrapper}>
                              {omittedValues.map(({ label }, id) => (
                                <div className={Style.tagText} key={id}>
                                  {label}&nbsp;&nbsp;&nbsp;
                                  <CloseOutlined
                                    onClick={() => {
                                      handleFilterDataPopoverChange('platforms', label);
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <span>+{omittedValues.length}</span>
                        </Popover>
                      )}
                      showArrow
                    >
                      <Select.Option value={'news'}>{t('news_and_culture.news')}</Select.Option>
                      <Select.Option value={'twitter'}>{t('news_and_culture.twitter')}</Select.Option>
                      <Select.Option value={'facebook'}>{t('news_and_culture.facebook')}</Select.Option>
                      <Select.Option value={'instagram'}>{t('news_and_culture.instagram')}</Select.Option>
                      <Select.Option value={'threads'}>{t('news_and_culture.threads')}</Select.Option>
                      <Select.Option value={'tiktok'}>{t('news_and_culture.tiktok')}</Select.Option>
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label={t('news_and_culture.section')}
                    style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                    value={filterData.analysis_section_config_ids || undefined}
                    helpText={t('news_and_culture.section_help_text')}
                  >
                    <Select
                      mode="multiple"
                      showArrow
                      onChange={(value) => handleFilterChange('analysis_section_config_ids', value)}
                      style={{ width: '100%' }}
                      value={filterData.analysis_section_config_ids || undefined}
                      allowClear
                    >
                      {analysisOverview?.section_data?.map((section) => (
                        <Select.Option key={section?.section_id} value={section?.section_id}>
                          {section?.section_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label={t('news_and_culture.handles')}
                    style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                    value={filterData.handles}
                    helpText={t('news_and_culture.handles_help_text')}
                  >
                    <Select
                      mode="tags"
                      style={{ width: '100%' }}
                      showArrow
                      onPopupScroll={handlePopupScrollHandle}
                      value={filterData.handles}
                      onChange={(value) => handleFilterChange('handles', value)}
                      onSearch={handleSearchHandle}
                      maxTagCount={1}
                      maxTagPlaceholder={(omittedValues) => (
                        <Popover
                          color="black"
                          overlayClassName={Style.tagPopover}
                          content={
                            <div className={Style.tagWrapper}>
                              {omittedValues.map(({ label }, id) => (
                                <div className={Style.tagText} key={id}>
                                  {label}&nbsp;&nbsp;&nbsp;
                                  <CloseOutlined
                                    onClick={() => {
                                      handleFilterDataPopoverChange('handles', label);
                                    }}
                                  />
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <span>+{omittedValues.length}</span>
                        </Popover>
                      )}
                      allowClear
                      onDropdownVisibleChange={handleDropdownVisibleChangeHandle}
                      notFoundContent={<></>}
                      dropdownRender={(menu) => {
                        return (
                          <>
                            {handles.length === 0 && !loadingHandlesOptions ? (
                              <Empty description="No data found" style={{ padding: '0.75rem 0' }} />
                            ) : (
                              <>
                                {menu}
                                {loadingHandlesOptions && (
                                  <div
                                    style={{
                                      textAlign: 'center',
                                      padding: '8px',
                                    }}
                                  >
                                    <Spin size="small" />
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        );
                      }}
                    >
                      {handles?.map((elem, index) => {
                        return (
                          <Select.Option key={index} value={elem}>
                            {elem}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </FloatLabel>
                </Col>
                <Col>
                  <FloatLabel
                    label={t('news_and_culture.date_range')}
                    style={{ backgroundColor: 'var(--color-charcoal-surface-1)' }}
                    value={[dayjs(filterData?.start_date), dayjs(filterData?.end_date)]}
                  >
                    <RangePicker
                      onChange={(value) => handleFilterChange('date_range', value)}
                      value={[dayjs(filterData?.start_date), dayjs(filterData?.end_date)]}
                      style={{ width: '100%' }}
                      allowClear={false}
                      disabledDate={(val) => {
                        if (val?.isBefore(dayjs(minStartDate).startOf('d'))) {
                          return true;
                        }
                        if (val?.isAfter(dayjs().endOf('d'))) {
                          return true;
                        }
                        return false;
                      }}
                    />
                  </FloatLabel>
                </Col>
                <Col>
                  {t('news_and_culture.normalize_data')}{' '}
                  <Popover
                    content={t('news_and_culture.normalize_data_help_text')}
                    overlayStyle={{ maxWidth: '250px', fontSize: '11px', zIndex: 9999 }}
                  >
                    &nbsp;
                    <InfoCircleOutlined style={{ fontSize: '12px' }} />
                  </Popover>
                  :&nbsp;&nbsp;
                  <Switch
                    style={{ width: '10%' }}
                    checked={filterData?.normalize_data_by_volume}
                    onChange={(value) => handleFilterChange('normalize_data_by_volume', value)}
                  />
                </Col>
              </div>
              <Col span={24}>
                <PrimaryButton
                  loading={loading}
                  className={Style.applyButton}
                  style={{ width: '100%' }}
                  onClick={handleApply}
                >
                  {t('news_and_culture.apply_filters')}
                </PrimaryButton>
              </Col>
            </Row>
          </Col>
          <Col span={20}>
            {chartData && chartData.length > 0 && (
              <ReactEcharts
                ref={echartsInstance}
                option={options}
                onEvents={chartEvents}
                style={{ height: '595px', border: 'solid 0.5px #ffffff2e', borderRadius: '10px', overflow: 'hidden' }}
              />
            )}
            {!loading && chartData && chartData.length <= 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '50px',
                  width: '100%',
                  height: '100%',
                }}
              >
                <Empty description="No Data" />
              </div>
            )}
            {loading && chartData && chartData.length <= 0 && (
              <div style={{ textAlign: 'center', padding: '50px' }}>
                <Spin size="medium" />
              </div>
            )}
          </Col>
        </Row>
        {/* <Row gutter={[10, 10]}>
          <TrendLineChart
            analysisId={analysisOverview?.id}
            filterData={filterData}
            filterApplyTimestamp={filterApplyTimestamp}
          />
        </Row> */}
        <AnalysisQuadrantsInsightsComponent analysisOverview={analysisOverview} filterData={appliedFilterData} />
      </Card>

      {highlightedPost && (
        <ArticlesTableDrawer
          visible={highlightedPost}
          analysisId={analysisOverview.id}
          onClose={() => setHighlightedPost(null)}
          selectedPostID={highlightedPost}
        />
      )}
    </div>
  );
};

export default React.memo(QuadrantChartComponent);
