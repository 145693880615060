import { DatabaseOutlined, FileTextOutlined, PlusOutlined, RobotOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';
import * as Styles from './styles';

const WelcomeState = ({ onCreateTask }) => {
  return (
    <div className={Styles.welcomeContainer}>
      {/* Main welcome section */}
      <div className={Styles.welcomeHeader}>
        <h1 className={Styles.welcomeTitle}>Welcome to Labeler</h1>
        <p className={Styles.welcomeDescription}>
          Automate your data analysis tasks using AI. Import data from various sources and get structured, analyzed
          results in minutes.
        </p>
      </div>

      {/* Features grid */}
      <div className={Styles.featuresGrid}>
        {/* Import Data Feature */}
        <div className={Styles.featureCard}>
          <DatabaseOutlined className={Styles.featureIcon} />
          <h3 className={Styles.featureTitle}>Import Data</h3>
          <p className={Styles.featureDescription}>
            Connect Google Sheets or databases. Automatically process new data with scheduled runs
          </p>
        </div>
        {/* Arrow */}
        <div className={Styles.ArrowContainer}>
          <div className={Styles.Arrow}></div>
        </div>
        {/* AI Analysis Feature */}
        <div className={Styles.featureCard}>
          <RobotOutlined className={Styles.featureIcon} />
          <h3 className={Styles.featureTitle}>AI Analysis</h3>
          <p className={Styles.featureDescription}>
            Leverage advanced AI to analyze text, images, and web content automatically
          </p>
        </div>
        {/* Arrow */}
        <div className={Styles.ArrowContainer}>
          <div className={Styles.Arrow}></div>
        </div>
        {/* Structured Output Feature */}
        <div className={Styles.featureCard}>
          <FileTextOutlined className={Styles.featureIcon} />
          <h3 className={Styles.featureTitle}>Structured Results</h3>
          <p className={Styles.featureDescription}>
            Preview outputs, get formatted data written back to source, and track progress in real-time
          </p>
        </div>
      </div>

      {/* CTA button */}
      <Button type="primary" icon={<PlusOutlined />} onClick={onCreateTask} className={Styles.createButton}>
        Create Your First Task
      </Button>
    </div>
  );
};

export default WelcomeState;
