import { Form, Input, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import Style from './login.style';

export default function LoginForm({ data, navigateToPassword, displayPassword, doLogin, navigateToEmail, loading }) {
  const { t } = useTranslation();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [isCtaDisabled, setIsCtaDisabled] = useState(true);
  const [showPassword, setShowassword] = useState(false);

  useEffect(() => {
    setShowassword(displayPassword);
  }, [displayPassword]);

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  function formSubmit() {
    if (!showPassword) {
      emailRef.current.submit();
    } else {
      passwordRef.current.submit();
    }
  }

  return (
    <div className={Style.contentContainer}>
      <div className={Style.header}>
        <div className={Style.greetingMsg}>{showPassword ? t('welcome back') : t('sign in')}</div>
      </div>
      <div className={Style.content}>
        <Form
          ref={emailRef}
          initialValues={data}
          onFinish={(val) => {
            navigateToPassword(val);
          }}
        >
          <Form.Item
            name="email"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: 'Email is required',
              },
              {
                validator: (_, value) => {
                  const validEmail = validateEmail(value);
                  if (value) {
                    if (value.includes('@galepartners.com')) {
                      setShowassword(false);
                      navigateToEmail();
                    } else {
                      if (validEmail) {
                        setIsCtaDisabled(true);
                        navigateToPassword({ email: value });
                      }
                    }
                    if (!value.trim().length) {
                      setIsCtaDisabled(true);
                      return Promise.reject(new Error('Email is required'));
                    } else {
                      if (validEmail) {
                        setIsCtaDisabled(false);
                        return Promise.resolve();
                      } else {
                        setIsCtaDisabled(true);
                        return Promise.reject(new Error('Invalid Email'));
                      }
                    }
                  } else {
                    setIsCtaDisabled(true);
                  }

                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder={t('email')} type="email" disabled={false} autoComplete="email" />
          </Form.Item>
        </Form>
        <div
          className={Style.emailContainer}
          style={{
            opacity: showPassword ? 1 : 0,
            paddingBottom: showPassword ? '8px' : '0px',
            maxHeight: showPassword ? 'none' : '0px',
          }}
        >
          <Form
            initialValues={data}
            ref={passwordRef}
            onFinish={(val) => {
              doLogin(val);
            }}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Password is required',
                },
                {
                  validator: (_, value) => {
                    if (value.length < 4) {
                      setIsCtaDisabled(true);
                      return Promise.reject(new Error('Password requires more than 4 characters'));
                    } else {
                      setIsCtaDisabled(false);
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input.Password placeholder={t('password')} />
            </Form.Item>
          </Form>
        </div>
        <Row justify="center">
          <PrimaryButton loading={loading} onClick={formSubmit} disabled={isCtaDisabled}>
            {showPassword ? t('login') : t('next')}
          </PrimaryButton>
        </Row>
      </div>
      <div></div>
    </div>
  );
}
