import { Drawer, Empty, Input, Skeleton, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useArticleTable } from '../hooks/useArticleTable';
import Style from '../NewsAndCulture.style';
import DataSourceContent from './DataSourceContent';
const { Search } = Input;

const ArticleTableDrawer = ({
  selectedSentiment,
  selectedTopics,
  selectedThemes,
  selectedHandles,
  selectedWord,
  selectedLink,
  analysisId,
  visible,
  onClose,
  columnName = '',
  drawerContent,
  postTypes,
  secondary_analysis_ids,
  selectedPostID,
  start_date = null,
  end_date = null,
}) => {
  const {
    columns,
    analysisConfigRawData,
    globalSearch,
    setGlobalSearch,
    handleTableChange,
    detailDrawerVisible,
    setDetailDrawerVisible,
    selectedArticle,
    drawerLoader,
    pagination,
    totalPages,
  } = useArticleTable({
    selectedSentiment,
    selectedTopics,
    selectedThemes,
    selectedHandles,
    selectedWord,
    selectedLink,
    analysisId,
    visible,
    columnName,
    drawerContent,
    postTypes,
    secondary_analysis_ids,
    selectedPostID,
    start_date,
    end_date,
  });
  const { t } = useTranslation();

  return (
    <>
      <Drawer
        title={t('news_and_culture.articles')}
        width="90%"
        placement="right"
        onClose={onClose}
        visible={visible}
        closable={true}
        className={Style.articleTableDrawerContainer}
      >
        <Search
          placeholder={t('search')}
          value={globalSearch}
          onChange={(e) => setGlobalSearch(e.target.value)}
          style={{ marginBottom: 16, width: '300px', display: 'flex' }}
        />
        <Table
          columns={columns}
          dataSource={drawerLoader ? [] : analysisConfigRawData}
          locale={{
            emptyText: drawerLoader ? (
              <Skeleton active={true} paragraph={{ rows: 15 }} />
            ) : (
              <Empty description={t('no_data')} />
            ),
          }}
          rowKey="id"
          scroll={{ x: 1700 }}
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: totalPages,
            showSizeChanger: false,
            position: ['bottomCenter'],
          }}
          loading={drawerLoader}
          onChange={handleTableChange}
          className={`dark-table ${Style.articleTableDrawer}`}
        />
      </Drawer>

      {selectedArticle && (
        <DataSourceContent
          data={[selectedArticle]}
          visible={detailDrawerVisible}
          onClose={() => setDetailDrawerVisible(false)}
          loading={drawerLoader}
          nodeName="Article Details"
        />
      )}
    </>
  );
};

export default ArticleTableDrawer;
