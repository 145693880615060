import React from "react";
import { Typography, Empty } from "antd";
import Style from "./analysisQuadrantsAISummaryStyle";
import useAnalysisAISummaryHooks from "./analysisQuadrantsAISummaryHooks";
import LoadingSpinner from "src/components/loadingSpinner";

const { Title } = Typography;

const AnalysisQuadrantsAISummaryComponent = ({ filterData, analysisOverview }) => {
  const {
    aiSummary,
    loading,
  } = useAnalysisAISummaryHooks({ filterData, analysisOverview });

  if (loading) {
    return <LoadingSpinner centered />;
  } else if (!aiSummary) {
    return <Empty description="No data found" style={{ padding: "0.75rem 0" }} />;
  }

  return (
    <div>
      {Object.entries(aiSummary).map(([key, insights]) => (
        <div key={key} className={Style.summarySection}>
          <Title level={4}>{key.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}</Title>
          <ul>
            {insights.map((insight, index) => (
              <li key={index}>{insight}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default React.memo(AnalysisQuadrantsAISummaryComponent);
