import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppUrls from 'src/constants/appUrls';
import Evaluation from 'src/modules/evaluations/components/evaluation';
import Capabilities from './Capabilities';
import Conversations from './capabilityTools/conversations/Conversations';
import FineTuningTool from './capabilityTools/fineTuningTool/FineTuningTool';
import CreateForm from './CreateForm';
import Style from './customGpt.style';
import UsersAndPermissions from './UsersAndPermissions';

export default function CustomGptTabs({
  id,
  history,
  tabs,
  setTabs,
  initialValues,
  formRef,
  metaData,
  createGpt,
  createGptLoading,
  updateCustomApp,
  clientId,
}) {
  const { t } = useTranslation();

  const backToChatApp = () => {
    history.push(AppUrls.APP_PAGE(clientId, id));
  };

  return (
    <div className={Style.tabContainer}>
      <Tabs
        activeKey={tabs}
        onChange={(val) => {
          history.push(AppUrls.EDIT_CUSTOM_GPT(clientId, id, val));
          setTabs(val);
        }}
      >
        {initialValues?.version !== 'v1' && (
          <>
            <Tabs.TabPane tab={t('client_space.configure')} key="configure">
              <CreateForm
                formRef={formRef}
                initialValues={initialValues}
                metaData={metaData}
                createGpt={createGpt}
                createGptLoading={createGptLoading}
                id={id}
                backToChatAppHandler={backToChatApp}
                clientId={clientId}
                history={history}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('client_space.capabilities')} key="capability" disabled={!id}>
              <Capabilities
                metaData={metaData}
                initialValues={initialValues}
                id={id}
                updateCustomApp={updateCustomApp}
                history={history}
                clientId={clientId}
                backToChatAppHandler={backToChatApp}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('client_space.fine_tuning')} key="fine_tuning" disabled={!id}>
              <FineTuningTool
                id={id}
                aiAppData={initialValues}
                metaData={metaData}
                updateCustomApp={updateCustomApp}
                backToChatAppHandler={backToChatApp}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('client_space.evaluation')} key="evaluate" disabled={!id}>
              <Evaluation appId={id} backToChatAppHandler={backToChatApp} />
            </Tabs.TabPane>
          </>
        )}
        <Tabs.TabPane tab={t('client_space.users')} key="users" disabled={!id}>
          <UsersAndPermissions id={id} backToChatAppHandler={backToChatApp} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('client_space.feedback')} key="feedback" disabled={!id}>
          <Conversations id={id} backToChatAppHandler={backToChatApp} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
