import { Col, Form, Input, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import LoadingSpinner from 'src/components/loadingSpinner';
import Uploader from './Uploader';
import useClientSpaceAndBasicInfoHook from './useClientSpaceAndBasicInfoHook';

const ClientSpaceBasicInfo = ({ metaData, history, clientId, addUpdateClient }) => {
  const { t } = useTranslation();
  const { formRef, createClisentSpace, basicInfo, loading, configureLoading } = useClientSpaceAndBasicInfoHook({
    metaData,
    history,
    clientId,
    addUpdateClient,
  });
  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div>
      <Row justify="end">
        <PrimaryButton
          loading={configureLoading}
          className="small"
          onClick={() => {
            formRef.current.submit();
          }}
          comfy
        >
          {clientId ? t('edit') : t('create')}
        </PrimaryButton>
      </Row>
      <Form
        ref={formRef}
        layout="vertical"
        initialValues={basicInfo}
        requiredMark={false}
        onFinish={(values) => {
          createClisentSpace(values);
        }}
      >
        <Row gutter={[30, 0]}>
          <Col span={24}>
            <Form.Item
              label={t('client_space.client_space_name')}
              name="name"
              rules={[{ required: true, message: t('client_space.client_space_name_error') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('client_space.website_url')} name="website_url">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('client_space.industry')} name="industry">
              <Select>
                {metaData?.industries?.map((item) => {
                  return (
                    <Select.Option key={item?.id} value={item?.id}>
                      {item?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('client_space.tell_us_about_them')}
              name="description"
              rules={[{ required: true, message: t('client_space.description_error') }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t('client_space.client_space_icon')} name="black_white_icon">
              <Uploader onChange={() => {}} initialImageUrl={basicInfo?.black_white_icon || ''} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ClientSpaceBasicInfo;
