import { Col, Collapse, Form, Input, Modal, Row, Select, notification } from 'antd';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import appUrls from 'src/constants/appUrls';
import AppContext from 'src/context/appContext';
import { PageLayoutContext } from 'src/context/pageLayoutContext';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import AssistantIconPicker from 'src/modules/assistantAppIcon/assistantIconPicker/AssistantIconPicker';
import CustomGptApiServices from '../services/customGptApiServices';
import { CustomGptService } from '../services/customGptServices';
import AgentConfig from './AgentConfig';
import ConversationStartersConfig from './ConversationStartersConfig';
import Style from './createForm.style';
import LlmConfig from './LlmConfig';

export default function CreateForm({
  formRef,
  initialValues,
  metaData,
  createGpt,
  createGptLoading,
  id,
  backToChatAppHandler,
  history,
}) {
  const { t } = useTranslation();
  const [appIconName, setAppIconName] = useState(initialValues.app_icon_name ?? '');
  const [chosenLLM, setChosenLLM] = useState(initialValues);
  const appContext = useContext(AppContext.Context);
  const { setDoneEditing } = useContext(PageLayoutContext);

  const archiveAssistant = async () => {
    Modal.warn({
      title: t('client_space.archive_message'),
      okText: t('okay'),
      cancelText: t('cancel'),
      okCancel: true,
      onOk: async () => {
        try {
          await CustomGptApiServices.archive(id);
          history.push(appUrls.HOMEPAGE);
          notification.success({
            message: t('client_space.archive_success'),
            duration: 6,
            placement: 'bottomRight',
          });
        } catch (error) {
          const { msg } = getErrorMessageFromResponse(error);
          notification.error({
            message: msg || t('client_space.archive_error'),
            duration: 6,
            placement: 'bottomRight',
          });
        }
      },
    });
  };
  return (
    <div className={Style.container}>
      <Form
        initialValues={initialValues}
        ref={formRef}
        layout="vertical"
        onFinish={(val) => {
          val.app_icon_name = appIconName;
          setDoneEditing(true);
          createGpt(val);
        }}
      >
        <Row justify="end" className={Style.btnsContainer}>
          <PrimaryButton className="small" onClick={backToChatAppHandler} disabled={!id}>
            {t('client_space.go_to_assistant')}
          </PrimaryButton>
          <SecondaryButton className="small" loading={createGptLoading} onClick={() => formRef.current.submit()}>
            {id ? t('client_space.save_settings') : t('client_space.save_and_continue')}
          </SecondaryButton>
          <ConditionalRender shouldRender={!!id && appContext.metaData.email == initialValues.app_owner_email}>
            <PrimaryButton className="small" disabled={!id} onClick={() => archiveAssistant()}>
              {t('client_space.archive_assistant')}
            </PrimaryButton>
          </ConditionalRender>
        </Row>
        <Row gutter={[30, 0]} className={Style.formContainer}>
          <Col xl={12} xs={24}>
            <Form.Item
              name="assistant_display_name"
              label={t('name')}
              rules={CustomGptService.validations({ bot_name: true, required: true }, t('name'))}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col xl={12} xs={24}>
            <Form.Item
              rules={CustomGptService.validations({ required: true }, 'Client name')}
              name="client_name"
              label={t('client')}
            >
              <Select disabled={true}>
                {metaData?.client_config?.available_clients?.choices?.map((item, idx) => {
                  return (
                    <Select.Option value={item} key={`option-${idx}`}>
                      {item}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={24} xs={24}>
            <Form.Item
              rules={CustomGptService.validations({ required: true }, t('description'))}
              name="description"
              label={t('client_space.description')}
            >
              <Input.TextArea
                autoSize={{ minRows: 1, maxRows: 5 }}
                placeholder={t('client_space.description_placeholder')}
              ></Input.TextArea>
            </Form.Item>
          </Col>
          <Col xl={24} xs={24}>
            <Form.Item
              rules={CustomGptService.validations({ required: true }, t('client_space.instruction_prompt'))}
              name="system_prompt"
              label={t('client_space.instruction_prompt')}
            >
              <Input.TextArea
                autoSize={{ minRows: 8, maxRows: 12 }}
                placeholder={t('client_space.instruction_prompt_placeholder')}
              ></Input.TextArea>
            </Form.Item>
          </Col>
          <Col xl={24} xs={24}>
            <Form.Item name="app_icon_name" initialValue={appIconName} className={Style.assistantIconInvisibleInput}>
              <Input tabIndex={-1} aria-disabled={true} />
            </Form.Item>
            <AssistantIconPicker
              originalIconName={appIconName}
              updateIconNameHandler={(iconName) => {
                setAppIconName(iconName);
              }}
            />
          </Col>
        </Row>
        <Row className={Style.formContainer} gutter={[30, 0]}>
          <Col span={24}>
            <Collapse className={'regularCollapse'}>
              <Collapse.Panel header={'Advanced settings'} forceRender={true}>
                <Form.Item noStyle name={['llm_config']}>
                  <LlmConfig metaData={metaData} setChosenLLM={setChosenLLM} />
                </Form.Item>
                <Form.Item noStyle name={['agent_config']}>
                  <AgentConfig metaData={metaData} chosenLLM={chosenLLM} formRef={formRef} />
                </Form.Item>
                <ConversationStartersConfig />
              </Collapse.Panel>
            </Collapse>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
