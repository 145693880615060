import { Checkbox, Col, Form, Input, List, Row, Select, message } from "antd";
import React from "react";
import useColumnDrawerHook from "./useColumnDrawerHook";
import Style from "./columDrawer.style";
import {
  RoundedOutlinedButton,
  RoundedPrimaryButton,
  SecondaryButton,
} from "src/components/basic";
import TextArea from "antd/lib/input/TextArea";
import { COLUMNS_TYPE } from "src/modules/CustomGpt/constants/constants";
import { DeleteOutlined } from "@ant-design/icons";
import CommonStyle from "../newSqlTool.style";
import ConditionalRender from "src/components/conditionalRender";

export default function ColumnDrawer({
  onChange,
  value,
  setColumnDrawer,
  virtualTable,
  setUnsavedChanges,
}) {
  const { formRef } = useColumnDrawerHook();

  const ListItem = ({ field, idx, remove }) => {
    return (
      <>
        <div
          className={Style.listItem}
          style={{ marginTop: idx === 0 ? "1.7rem" : "" }}
        >
          <div style={{ width: "3%" }}>
            <Form.Item
              {...field}
              name={[field.name, "is_active"]}
              valuePropName="checked"
              fieldKey={[field.fieldKey, "is_active"]}
            >
              <Checkbox />
            </Form.Item>
          </div>
          <div style={{ width: "20%" }}>
            <Form.Item
              {...field}
              name={[field.name, "name"]}
              fieldKey={[field.fieldKey, "name"]}
              rules={[{ required: true, message: "Column name is required" }]}
            >
              <Input placeholder="Enter column name" disabled />
            </Form.Item>
          </div>
          <div style={{ width: "20%" }}>
            <Form.Item
              {...field}
              name={[field.name, "type"]}
              fieldKey={[field.fieldKey, "type"]}
              rules={[{ required: true, message: "Schema Name is required" }]}
            >
              <Select showSearch placeholder="Enter datatype">
                {COLUMNS_TYPE.map((item) => {
                  return <Select.Option value={item}>{item}</Select.Option>;
                })}
              </Select>
            </Form.Item>
          </div>
          <div style={{ width: "30%" }}>
            <Form.Item
              {...field}
              name={[field.name, "description"]}
              fieldKey={[field.fieldKey, "description"]}
            >
              <TextArea
                autoSize={{ maxRows: 1 }}
                placeholder="Enter column description"
              />
            </Form.Item>
          </div>
          <div style={{ width: "20%" }}>
            <Form.Item
              {...field}
              name={[field.name, "synonyms"]}
              fieldKey={[field.fieldKey, "synonyms"]}
            >
              <Select
                allowClear={false}
                mode="tags"
                maxTagCount="responsive"
                style={{ width: "95%" }}
                placeholder="Enter synonyms"
              />
            </Form.Item>
          </div>
          <div style={{ width: "6%" }}>
            <div className={Style.deleteContainer}>
              <div>
                <Form.Item
                  {...field}
                  name={[field.name, "vectorized"]}
                  valuePropName="checked"
                  fieldKey={[field.fieldKey, "vectorized"]}
                >
                  <Checkbox />
                </Form.Item>
              </div>
              <div>
                <DeleteOutlined
                  className="delete-btn"
                  style={{ marginTop: "8px" }}
                  onClick={() => remove(field.name)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className={Style.form}>
      <div className={Style.body}>
        <Form
          ref={formRef}
          initialValues={{ columns: value }}
          onFinish={(val) => {
            onChange(val.columns);
            setColumnDrawer(false);
            setUnsavedChanges(true);
            message.success("Columns added successfully");
          }}
        >
          <Form.List name="columns">
            {(fields, { add, remove }) => {
              return (
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.additional !== curValues.additional
                  }
                  noStyle
                >
                  {({ getFieldsValue }) => {
                    return (
                      <>
                        <Row justify="end">
                          <ConditionalRender shouldRender={!virtualTable}>
                            <Col>
                              <SecondaryButton
                                className={"small"}
                                onClick={() => add()}
                              >
                                Add column
                              </SecondaryButton>
                            </Col>
                          </ConditionalRender>
                        </Row>
                        <List
                          dataSource={fields}
                          header={
                            <div className={Style.listHeader}>
                              <div style={{ width: "3%" }}></div>
                              <div style={{ width: "20%" }}>Name</div>
                              <div style={{ width: "20%" }}>Data type</div>
                              <div style={{ width: "30%" }}>Description</div>
                              <div style={{ width: "20%" }}>Synonyms</div>
                              <div style={{ width: "6%" }}>Indexed</div>
                            </div>
                          }
                          renderItem={(field, idx) => {
                            return <ListItem field={field} idx={idx} remove={remove} />;
                          }}
                        />
                      </>
                    );
                  }}
                </Form.Item>
              );
            }}
          </Form.List>
        </Form>
      </div>
      <Row justify="end">
        <SecondaryButton onClick={() => formRef.current.submit()}>
          Done
        </SecondaryButton>
      </Row>
    </div>
  );
}
