import { notification } from 'antd';
import chroma from 'chroma-js';
import L from 'leaflet';
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { formatNumberWithCeil, getErrorMessageFromResponse } from 'src/lib/utils';
import {
  AUDIENCE_FEATURE_PERCENTAGE,
  COUNTY,
  DMA,
  GEOGRAPHIC,
  INDEX_VALUE,
  PENETRATION_PERCENTAGE,
  STATE,
} from '../../../constants/audienceDetailsConstants';
import audienceListService from '../../../services/audienceListService';

const useGeographicHook = ({ audienceId }) => {
  const [geographicLoading, setGeographicLoading] = useState(false);
  const [shadingLevel, setShadingLevel] = useState(10);
  const [geographicLevel, setGeographicLevel] = useState('DMA');
  const [geographicData, setGeographicData] = useState(null);
  const [dmaData, setDmaData] = useState(null);
  const [countiesData, setCountiesData] = useState(null);
  const [statesData, setStatesData] = useState(null);
  const [heatMapType, setHeatMapType] = useState(INDEX_VALUE);

  const fetchGeographicData = async (featureName) => {
    try {
      const audienceData = await audienceListService.getAudienceFeatureProfiles(
        audienceId,
        '',
        featureName,
        1,
        GEOGRAPHIC,
        undefined,
        10000
      );
      setGeographicData(audienceData.data.data);
      setGeographicLoading(false);
    } catch (error) {
      setGeographicLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getDmaData = async () => {
    try {
      const response = await fetch('/dma.json');
      const data = await response.json();
      setDmaData(data);
    } catch (error) {
      notification.error({
        duration: 5,
        message: 'Failed to fetch DMA data',
        placement: 'bottomRight',
      });
    }
  };

  const getCountyData = async () => {
    try {
      const response = await fetch('/counties.geojson');
      const data = await response.json();
      setCountiesData(data);
    } catch (error) {
      notification.error({
        duration: 5,
        message: 'Failed to fetch Counties data',
        placement: 'bottomRight',
      });
    }
  };

  const getStateData = async () => {
    try {
      const response = await fetch('/usStates.json');
      const data = await response.json();
      setStatesData(data);
    } catch (error) {
      notification.error({
        duration: 5,
        message: 'Failed to fetch States data',
        placement: 'bottomRight',
      });
    }
  };

  const getGradientColors = (numColors, startColor = '#fee8c8', endColor = '#990000') => {
    const scale = chroma.scale([startColor, endColor]).mode('lab');
    return scale.colors(numColors);
  };

  const getColor = (d) => {
    const numColors = shadingLevel;
    const colors = getGradientColors(numColors);
    const maxValue = heatMapType === INDEX_VALUE ? 200 : 100;
    const interval = maxValue / numColors;

    for (let i = 0; i < numColors; i++) {
      if (d <= (i + 1) * interval) {
        return colors[i];
      }
    }
    return colors[colors.length - 1];
  };

  const getFeatureInfo = (feature) => {
    if (feature.properties) {
      let jsonFeatureValue;
      switch (geographicLevel) {
        case DMA:
          jsonFeatureValue = feature.properties.DMA;
          break;
        case STATE:
          jsonFeatureValue = feature.id;
          break;
        case COUNTY:
          jsonFeatureValue = feature.properties.STATEFP + feature.properties.COUNTYFP;
          break;
        default:
          jsonFeatureValue = null;
          break;
      }
      const featureInfo = geographicData.find((f) => f.feature_value === jsonFeatureValue);
      return featureInfo;
    }
  };

  const style = (feature) => {
    const featureInfo = getFeatureInfo(feature);
    let heatMapValue = null;
    switch (heatMapType) {
      case INDEX_VALUE:
        heatMapValue = featureInfo ? formatNumberWithCeil(featureInfo.index_value) : 0;
        break;
      case PENETRATION_PERCENTAGE:
        heatMapValue = featureInfo ? formatNumberWithCeil(featureInfo.penetration_value * 100) : 0;
        break;
      case AUDIENCE_FEATURE_PERCENTAGE:
        heatMapValue = featureInfo ? formatNumberWithCeil(featureInfo.audience_feature_percentage) : 0;
        break;
      default:
        heatMapValue = featureInfo ? formatNumberWithCeil(featureInfo.index_value) : 0;
        break;
    }
    return {
      fillColor: getColor(heatMapValue),
      weight: 1,
      opacity: 1,
      color: 'black',
      fillOpacity: 0.7,
    };
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
      const featureInfo = getFeatureInfo(feature);
      const indexValue = featureInfo ? formatNumberWithCeil(featureInfo.index_value) : 0;
      const penetrationValue = featureInfo ? formatNumberWithCeil(featureInfo.penetration_value * 100) : 0;
      const audienceFeaturePercentage = featureInfo ? formatNumberWithCeil(featureInfo.audience_feature_percentage) : 0;
      const baseFeaturePercentage = featureInfo ? formatNumberWithCeil(featureInfo.base_feature_percentage) : 0;
      layer.bindTooltip(
        `<div>
                    <strong>${geographicLevel === STATE ? feature.properties.name : feature.properties.NAME}</strong><br/>
                    Index Value: ${indexValue}<br/>
                    Penetration Percentage: ${penetrationValue}%<br/>
                    Audience Feature Percentage: ${audienceFeaturePercentage}%<br/>
                    Base Feature Percentage: ${baseFeaturePercentage}%
                </div>`,
        { permanent: false, direction: 'auto' }
      );
    }
  };

  const getGeoData = () => {
    switch (geographicLevel) {
      case COUNTY:
        return countiesData;
      case STATE:
        return statesData;
      case DMA:
      default:
        return dmaData;
    }
  };

  const Legend = () => {
    const map = useMap();

    useEffect(() => {
      const legend = L.control({ position: 'bottomright' });
      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        let grades;
        const maxValue = heatMapType === INDEX_VALUE ? 200 : 100;
        const header = heatMapType;

        switch (shadingLevel) {
          case 5:
            grades = Array.from({ length: 6 }, (_, i) => Math.floor(i * (maxValue / 5)));
            break;
          case 20:
            grades = Array.from({ length: 21 }, (_, i) => Math.floor(i * (maxValue / 20)));
            break;
          case 3:
            grades = Array.from({ length: 4 }, (_, i) => Math.floor(i * (maxValue / 3)));
            break;
          case 10:
          default:
            grades = Array.from({ length: 11 }, (_, i) => Math.floor(i * (maxValue / 10)));
            break;
        }
        const labels = [];

        for (let i = 0; i < grades.length - 1; i++) {
          labels.push(
            `<div style="display: flex; align-items: center;">
                    <span style="background:${getColor(grades[i] + 1)}; width: 20px; height: 20px; display: inline-block; margin-right: 8px;"></span>
                    ${grades[i] + 1}${grades[i + 1] ? `&ndash;${grades[i + 1]}${grades[i + 1] === maxValue ? '+' : ''}` : ''}
                </div>`
          );
        }

        div.innerHTML = `
                <h4>${header}</h4>
                ${labels.join('')}
            `;
        return div;
      };
      legend.addTo(map);
      map.zoomControl.setPosition('bottomleft');

      return () => legend.remove();
    }, [map, shadingLevel, heatMapType]);

    return null;
  };

  useEffect(() => {
    setGeographicLoading(true);
    getDmaData();
    getCountyData();
    getStateData();
  }, [audienceId]);

  useEffect(() => {
    switch (geographicLevel) {
      case COUNTY:
        setGeographicLoading(true);
        fetchGeographicData(COUNTY);
        break;
      case STATE:
        setGeographicLoading(true);
        fetchGeographicData(STATE);
        break;
      case DMA:
      default:
        setGeographicLoading(true);
        fetchGeographicData(DMA);
        break;
    }
  }, [geographicLevel]);

  return {
    geographicLoading,
    shadingLevel,
    setShadingLevel,
    geographicLevel,
    setGeographicLevel,
    heatMapType,
    setHeatMapType,
    getGeoData,
    style,
    onEachFeature,
    Legend,
  };
};
export default useGeographicHook;
