import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import useAudienceCloneForm from './useAudienceCloneForm';

const AudienceCloneForm = ({ data, closeCloneModal, onClone, handleAudienceEdit, handleClusterClone }) => {
  const { formRef, loading, handleClone } = useAudienceCloneForm({
    closeCloneModal,
    onClone,
    data,
    handleAudienceEdit,
    handleClusterClone,
  });
  const { t } = useTranslation();

  return (
    <div>
      <Form ref={formRef} onFinish={handleClone}>
        <Form.Item
          name="cloned_project_name"
          rules={[
            {
              required: true,
              message: t('audience.please_enter_name'),
            },
          ]}
        >
          <Input placeholder={t('audience.enter_name')} />
        </Form.Item>
        <Row justify="space-between" style={{ marginTop: '5rem' }}>
          <Col>
            <SecondaryButton disabled={loading} onClick={() => closeCloneModal()} className="small" comfy>
              {t('cancel')}
            </SecondaryButton>
          </Col>

          <Col>
            <PrimaryButton loading={loading} className="small" onClick={() => formRef.current.submit()}>
              {t('audience.clone_audience')}
            </PrimaryButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AudienceCloneForm;
