import { Checkbox, Collapse } from 'antd';
import React, { useCallback } from 'react';
import { BEHAVIOUR, DEMOGRAPHIC, FIRST_PARTY } from '../../../../constants/queryBuilderConstants';
const { Panel } = Collapse;

const useBrowseFeatureHook = ({
  fieldDirectory,
  browsedSelectedItems,
  setBrowsedSelectedItems,
  ruleType,
  searchQuery,
  setSearchQuery,
}) => {
  const handleCheckboxChange = useCallback(
    (checked, key) => {
      setBrowsedSelectedItems((prevSelected) => {
        if (checked) {
          return [...prevSelected, key];
        } else {
          return prevSelected.filter((item) => item !== key);
        }
      });
    },
    [setBrowsedSelectedItems]
  );

  const handleRemoveItem = useCallback(
    (item) => {
      setBrowsedSelectedItems((prevSelected) => prevSelected.filter((selectedItem) => selectedItem !== item));
    },
    [setBrowsedSelectedItems]
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterTree = (node, query) => {
    return Object.keys(node).reduce((acc, key) => {
      const isLeaf = Object.keys(node[key]).length === 0;

      if (key.toLowerCase().includes(query.toLowerCase())) {
        acc[key] = node[key];
      } else if (!isLeaf) {
        const filteredChild = filterTree(node[key], query);
        if (Object.keys(filteredChild).length > 0) {
          acc[key] = filteredChild;
        }
      }

      return acc;
    }, {});
  };

  const filterFieldList = (list, query) => {
    return list.filter((field) => field.label.toLowerCase().includes(query.toLowerCase()));
  };

  const filterGroupedByCategory = (grouped, query) => {
    return Object.keys(grouped).reduce((acc, category) => {
      const filteredFields = grouped[category].filter((field) =>
        field.label.toLowerCase().includes(query.toLowerCase())
      );
      if (filteredFields.length > 0) {
        acc[category] = filteredFields;
      }
      return acc;
    }, {});
  };

  const renderTree = (node, path = '') => {
    return Object.keys(node).map((key) => {
      const currentPath = path ? `${path} > ${key}` : key;
      const isLeaf = Object.keys(node[key]).length === 0;

      return (
        <div key={currentPath} className="tree-node">
          {isLeaf ? (
            <Checkbox
              checked={browsedSelectedItems.includes(key)}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleCheckboxChange(e.target.checked, key)}
            >
              {key}
            </Checkbox>
          ) : (
            <Collapse>
              <Panel key={currentPath} header={<>{key}</>}>
                {renderTree(node[key], currentPath)}
              </Panel>
            </Collapse>
          )}
        </div>
      );
    });
  };

  const renderFieldDirectoryList = (groupedFields) => {
    return Object.keys(groupedFields).map((key) => {
      const isLeaf = !Array.isArray(groupedFields[key]);

      return (
        <div key={`node-${key}`} className="tree-node">
          {isLeaf ? (
            <Checkbox
              checked={browsedSelectedItems.includes(groupedFields[key].label)}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => handleCheckboxChange(e.target.checked, groupedFields[key].label)}
            >
              {groupedFields[key].label}
            </Checkbox>
          ) : (
            <Collapse>
              <Panel key={`panel-${key}`} header={<>{key}</>}>
                {renderFieldDirectoryList(groupedFields[key])}
              </Panel>
            </Collapse>
          )}
        </div>
      );
    });
  };

  const renderCheckbox = (item) => (
    <div key={item.id} className="tree-node">
      <Checkbox
        checked={browsedSelectedItems.includes(item.label)}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => handleCheckboxChange(e.target.checked, item.label)}
      >
        {item.label}
      </Checkbox>
    </div>
  );

  const renderPanel = (category, items) => (
    <Panel key={category} header={category}>
      {items.map((item) => renderCheckbox(item))}
    </Panel>
  );

  const renderFolderStructure = (filteredGroupedByCategory) => {
    return Object.keys(filteredGroupedByCategory).map((category) => (
      <Collapse key={category}>{renderPanel(category, filteredGroupedByCategory[category])}</Collapse>
    ));
  };

  const getFilteredTree = () => {
    return ruleType === BEHAVIOUR ? filterTree(fieldDirectory, searchQuery) : null;
  };

  const getFilteredDemographicFields = () => {
    if (ruleType !== DEMOGRAPHIC) return null;

    const filteredFields = filterFieldList(fieldDirectory, searchQuery);
    const groupedByCategory = filteredFields.reduce((acc, field) => {
      const { featureCategory, secondLevelCategory, thirdLevelCategory } = field;

      if (featureCategory) {
        if (!acc[featureCategory]) acc[featureCategory] = [];
        if (secondLevelCategory) {
          if (!acc[featureCategory][secondLevelCategory]) acc[featureCategory][secondLevelCategory] = [];
          if (thirdLevelCategory) {
            if (!acc[featureCategory][secondLevelCategory][thirdLevelCategory])
              acc[featureCategory][secondLevelCategory][thirdLevelCategory] = [];
            acc[featureCategory][secondLevelCategory][thirdLevelCategory].push(field);
          } else {
            if (!Array.isArray(acc[featureCategory][secondLevelCategory])) {
              acc[featureCategory][secondLevelCategory] = [];
            }
            acc[featureCategory][secondLevelCategory].push(field);
          }
        } else {
          if (!Array.isArray(acc[featureCategory])) {
            acc[featureCategory] = [];
          }
          acc[featureCategory].push(field);
        }
      } else {
        acc.push(field);
      }

      return acc;
    }, {});

    return groupedByCategory;
  };

  const getGroupedByCategory = () => {
    return ruleType === FIRST_PARTY
      ? fieldDirectory.reduce((acc, field) => {
          if (!acc[field.featureCategory]) {
            acc[field.featureCategory] = [];
          }
          acc[field.featureCategory].push(field);
          return acc;
        }, {})
      : null;
  };

  const getFilteredGroupedByCategory = () => {
    const groupedByCategory = getGroupedByCategory();
    return ruleType !== BEHAVIOUR && ruleType !== DEMOGRAPHIC
      ? filterGroupedByCategory(groupedByCategory, searchQuery)
      : null;
  };

  const filteredTree = getFilteredTree();
  const filteredDemographicFields = getFilteredDemographicFields();
  const filteredGroupedByCategory = getFilteredGroupedByCategory();

  return {
    handleRemoveItem,
    handleSearchChange,
    filteredTree,
    filteredDemographicFields,
    filteredGroupedByCategory,
    renderTree,
    renderFieldDirectoryList,
    renderFolderStructure,
  };
};

export default useBrowseFeatureHook;
