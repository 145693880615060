import { Card, Empty, Input, Skeleton, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRawDataTable } from '../hooks/useRawDataTable';
import Style from '../NewsAndCulture.style';
import DataSourceContent from './DataSourceContent';
const { Search } = Input;

const RawDataContent = () => {
  const {
    columns,
    filteredData,
    globalSearch,
    setGlobalSearch,
    handleTableChange,
    detailDrawerVisible,
    setDetailDrawerVisible,
    selectedArticle,
    drawerLoader,
    pagination,
    totalPages,
  } = useRawDataTable();
  const { t } = useTranslation();

  return (
    <div className={Style.tabContent}>
      <Card className={Style.rawDataCard}>
        <Search
          placeholder={t('search')}
          value={globalSearch}
          onChange={(e) => setGlobalSearch(e.target.value)}
          style={{ marginBottom: 16, width: '300px', display: 'flex' }}
        />
        <Table
          columns={columns}
          dataSource={drawerLoader ? [] : filteredData}
          locale={{
            emptyText: drawerLoader ? (
              <Skeleton active={true} paragraph={{ rows: 15 }} />
            ) : (
              <Empty description={t('no_data')} />
            ),
          }}
          scroll={{ x: 2500 }}
          rowKey="id"
          pagination={{
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: totalPages,
            showSizeChanger: false,
            position: ['bottomCenter'],
          }}
          loading={drawerLoader}
          onChange={handleTableChange}
          className={Style.articleTable}
        />
      </Card>

      {selectedArticle && (
        <DataSourceContent
          data={[selectedArticle]}
          visible={detailDrawerVisible}
          onClose={() => setDetailDrawerVisible(false)}
          loading={drawerLoader}
          nodeName="Article Details"
        />
      )}
    </div>
  );
};

export default RawDataContent;
