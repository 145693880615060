import { Col, Row, Tabs } from 'antd';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import LoadingSpinner from 'src/components/loadingSpinner';
import { numberWithCommas } from 'src/lib/utils';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import MediaPlanFlighting from './MediaPlanFlighting';
import './MediaplanOutput.less';
import useMediaplanOutput from './useMediaplanOutput';

const MediaplanOutput = () => {
  const { loading, gantChartData, chhanelDataOptions, audienceSpend, funnelStage, spendByWeek, goBackToScenario } =
    useMediaplanOutput();
  const { t } = useTranslation();

  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <>
      <NcBreadCrumb />
      <h2>{t('mediaplan.media_output')}</h2>
      <Row justify="end" style={{ marginBottom: '1rem' }}>
        <PrimaryButton className="small" comfy onClick={() => goBackToScenario()}>
          {t('back')}
        </PrimaryButton>
      </Row>
      <Row className="header-container planning">
        <Col span={6}>
          <b>{t('mediaplan.media_plan_name')}:</b> {gantChartData?.mediaplan_name}
        </Col>
        <Col span={6}>
          <b>{t('mediaplan.budget')}:</b> ${numberWithCommas(gantChartData?.mediaplan_budget)}
        </Col>
        <Col span={6}>
          <b>{t('mediaplan.start_date')}:</b> {moment(gantChartData?.mediaplan_start_date).format('MM/DD/YYYY')}
        </Col>
        <Col span={6}>
          <b>{t('mediaplan.end_date')}:</b> {moment(gantChartData?.mediaplan_end_date).format('MM/DD/YYYY')}
        </Col>
      </Row>
      <Tabs>
        <Tabs.TabPane tab="Overview" key="1">
          <div className="charts-row">
            <div className="chart-container">
              <h2>{t('mediaplan.total_spend_by_channel')}</h2>
              <ReactECharts option={chhanelDataOptions} style={{ height: '400px', width: '100%' }} />
            </div>
            <div className="chart-container">
              <h2>{t('mediaplan.total_spend_by_audience')}</h2>
              <ReactECharts option={audienceSpend} style={{ height: '400px', width: '100%' }} />
            </div>
          </div>
          <div className="charts-row">
            <div className="chart-container">
              <h2>{t('mediaplan.total_spend_by_funnel_stage')}</h2>
              <ReactECharts option={funnelStage} style={{ height: '400px', width: '100%' }} />
            </div>
            <div className="chart-container">
              <h2>{t('mediaplan.total_spend_by_week')}</h2>
              <ReactECharts option={spendByWeek} style={{ height: '400px', width: '100%' }} />
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Flighting" key="2">
          <MediaPlanFlighting data={gantChartData} />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default MediaplanOutput;
