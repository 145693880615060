import { css } from '@linaria/core';

export const formContainer = css`
  width: 100%;
`;

export const guideSection = css`
  margin-bottom: 32px;
`;

export const title = css`
  color: var(--color-white);
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 2px;
`;

export const subtitle = css`
  display: block;
  font-size: 14px;
  line-height: 1.6;
  color: var(--color-gale-gray) !important;
`;

export const formSection = css`
  background: var(--color-90-black);
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 32px;

  .ant-form-item {
    margin-bottom: 28px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ant-form-item-label > label {
    color: var(--color-white);
    font-size: 14px;
  }
`;

export const input = css`
  background-color: var(--color-80-black);
  border: 1px solid var(--color-70-black);
  color: var(--color-white);
  font-size: 14px;

  &:hover,
  &:focus {
    border-color: var(--color-link);
    background-color: var(--color-80-black);
  }

  &::placeholder {
    color: var(--color-40-black);
  }
`;

export const textarea = css`
  background-color: var(--color-80-black);
  border: 1px solid var(--color-70-black);
  color: var(--color-white);
  min-height: 120px;
  font-size: 14px;

  &:hover,
  &:focus {
    border-color: var(--color-link);
    background-color: var(--color-80-black);
  }

  &::placeholder {
    color: var(--color-40-black);
  }
`;

export const templatesSection = css`
  width: 100%;
`;

export const templateTitle = css`
  display: block;
  margin-bottom: 16px;
  font-size: 14px;
  color: var(--color-60-gray);
`;

export const templateGrid = css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const templateCard = css`
  background: var(--color-90-black);
  border: 1px solid var(--color-80-black);
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    border-color: var(--color-link);
    transform: translateY(-1px);
  }
`;

export const templateName = css`
  display: block;
  margin-bottom: 4px;
  color: var(--color-white);
`;
