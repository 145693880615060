import { Collapse, Table } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './MediaplanOutput.less';
import useFlightingTable from './useFlightingTable';

const FlightingTable = ({ tabData }) => {
  const { getColumns, formatData } = useFlightingTable();
  const { t } = useTranslation();
  return (
    <div>
      <h3>{t('mediaplan.audiences')}:</h3>
      <div style={{ marginLeft: '1rem' }}>
        {tabData?.map((audience, id) => {
          const audienceName = audience?.audience_name;
          const columns = audience?.channels?.length > 0 ? getColumns(audience?.channels[0]) : [];
          const formattedData = audience?.channels?.length > 0 ? formatData(audience?.channels) : [];
          return (
            <Collapse className={'regularCollapse'} style={{ flexGrow: '1' }} defaultActiveKey={['0']} key={id}>
              <Collapse.Panel header={<b>{audienceName}</b>} key={id}>
                <div className="tableContainer">
                  <Table
                    className="dark-table"
                    columns={columns}
                    dataSource={formattedData}
                    pagination={false}
                    rowKey="id"
                    scroll={{
                      x: 'max-content',
                    }}
                  />
                </div>
              </Collapse.Panel>
            </Collapse>
          );
        })}
      </div>
    </div>
  );
};

export default FlightingTable;
