import React from 'react';
import { Row, Col, Select, Empty } from 'antd';
import ReactECharts from 'echarts-for-react';
import useAnalysisQuadrantsBreakdownHooks from './analysisQuadrantsBreakdownHooks';
import LoadingSpinner from 'src/components/loadingSpinner';
import ArticlesTableDrawer from '../ArticlesTableDrawer';

const AnalysisQuadrantsBreakdownComponent = ({ filterData, analysisOverview }) => {
  const {
    breakdown,
    loading,
    pieChartOption,
    selectedAnalysisType,
    setSelectedAnalysisType,
    onChartEvents,
    selectedValue,
    setSelectedValue,
  } = useAnalysisQuadrantsBreakdownHooks({
    filterData,
    analysisOverview,
  });

  if (loading) {
    return <LoadingSpinner centered />;
  } else if (!breakdown) {
    return <Empty description="No data found" style={{ padding: '0.75rem 0' }} />;
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <Select
            style={{ width: 200, marginBottom: 20 }}
            value={selectedAnalysisType}
            onChange={(value) => setSelectedAnalysisType(value)}
          >
            <Select.Option value="themes">Themes</Select.Option>
            <Select.Option value="topics">Topics</Select.Option>
            <Select.Option value="sentiment">Sentiment</Select.Option>
          </Select>
        </Col>
      </Row>
      <ReactECharts option={pieChartOption} style={{ height: '600px', width: '100%' }} onEvents={onChartEvents} />
      <ArticlesTableDrawer
        visible={selectedAnalysisType && selectedValue ? true : false}
        analysisId={analysisOverview.id}
        onClose={() => setSelectedValue(null)}
        {...(selectedValue ? selectedValue : {})}
      />
    </div>
  );
};

export default React.memo(AnalysisQuadrantsBreakdownComponent);
