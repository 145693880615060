import { Tabs } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import LoadingSpinner from 'src/components/loadingSpinner';
import Style from './addEditClientSpace.style';
import ClientSpaceBasicInfo from './ClientSpaceBasicInfo';
import ClientSpaceSelectApps from './ClientSpaceSelectApps';
import ClientSpaceUserAndPermissions from './ClientSpaceUserAndPermissions';
import useAddEditClientSpaceHook from './useAddEditClientSpaceHook';

const AddEditClientSpace = ({ history, addUpdateClient }) => {
  const { clientId } = useParams();
  const { tab, setTab, loading, metaData } = useAddEditClientSpaceHook();
  const { t } = useTranslation();

  if (loading) {
    return <LoadingSpinner centered />;
  }
  return (
    <div className={Style.container}>
      <Tabs
        activeKey={tab}
        onChange={(val) => {
          setTab(val);
        }}
        destroyInactiveTabPane={true}
      >
        <Tabs.TabPane tab={t('client_space.basic_info')} key="configure">
          <ClientSpaceBasicInfo
            metaData={metaData}
            history={history}
            clientId={clientId}
            addUpdateClient={addUpdateClient}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('client_space.assigned_users')} key="users_and_permissions" disabled={!clientId}>
          <ClientSpaceUserAndPermissions metaData={metaData} clientId={clientId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t('client_space.configure_apps')} key="configure_apps" disabled={!clientId}>
          <ClientSpaceSelectApps metaData={metaData} clientId={clientId} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default AddEditClientSpace;
