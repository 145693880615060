import {
  DatabaseOutlined,
  DeleteColumnOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Checkbox,
  Col,
  Drawer,
  Form,
  Input,
  List,
  Modal,
  Row,
  Select,
} from "antd";
import React from "react";
import {
  RoundedOutlinedButton,
  RoundedPrimaryButton,
  SecondaryButton,
} from "src/components/basic";
import ExploreDatabase from "../exploreDatabase.jsx/ExploreDatabase";
import CommonStyle from "../newSqlTool.style";
import useSqlToolTablesHook from "./useSqlToolTablesHook";
import Style from "./sqlToolTables.style";
import TextArea from "antd/lib/input/TextArea";
import SqlColumn from "./SqlColumn";
import ConditionalRender from "src/components/conditionalRender";
import SqlQuery from "./SqlQuery";

export default function SqlToolTables({
  selectedConnection,
  clientId,
  sqlData,
  mAiApp,
  setSqlData,
  virtualTable = false,
}) {
  const {
    exploreDataBaseDrawer,
    setExploreDataBaseDrawer,
    formRef,
    addConnectionlevelTable,
    loading,
    unsavedChanges,
    setUnsavedChanges,
  } = useSqlToolTablesHook({
    mAiApp,
    selectedConnection,
    setSqlData,
    virtualTable,
  });

  const ListItem = ({
    field,
    idx,
    remove,
    tableName,
    tables,
    setFieldsValue,
    getFieldsValue,
  }) => {
    return (
      <>
        <div
          className={Style.listItem}
          style={{ marginTop: idx === 0 ? "1.7rem" : "" }}
        >
          <div style={{ width: "3%" }}>
            <Form.Item
              {...field}
              name={[field.name, "is_active"]}
              valuePropName="checked"
              fieldKey={[field.fieldKey, "is_active"]}
            >
              <Checkbox />
            </Form.Item>
          </div>
          <ConditionalRender shouldRender={!virtualTable}>
            <div style={{ width: "18%" }}>
              <Form.Item
                {...field}
                name={[field.name, "schema_name"]}
                fieldKey={[field.fieldKey, "schema_name"]}
                rules={[{ required: true, message: "Schema Name is required" }]}
              >
                <Input placeholder="Enter schema name" />
              </Form.Item>
            </div>
          </ConditionalRender>
          <div style={{ width: "18%" }}>
            <Form.Item
              {...field}
              name={[field.name, "table_name"]}
              fieldKey={[field.fieldKey, "table_name"]}
              rules={[
                {
                  validator: (_, value) => {
                    if (virtualTable) {
                      if (value) {
                        if (value.trim().length === 0) {
                          return Promise.reject(
                            new Error("Table name is required")
                          );
                        } else {
                          const tableRegex = /^[A-Za-z][A-Za-z0-9_]*$/;
                          if (!tableRegex.test(value)) {
                            return Promise.reject(
                              new Error(
                                "Table name should start with alphabet and can contain only alphabets, numbers and underscore"
                              )
                            );
                          } else {
                            return Promise.resolve();
                          }
                        }
                      }
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                },
                { required: true, message: "Table Name is required" },
              ]}
            >
              <Input placeholder="Enter table name" />
            </Form.Item>
          </div>
          <div style={{ width: "33%" }}>
            <Form.Item
              {...field}
              name={[field.name, "description"]}
              fieldKey={[field.fieldKey, "description"]}
            >
              <TextArea
                autoSize={{ maxRows: 1 }}
                placeholder="Enter table description"
              />
            </Form.Item>
          </div>
          <div style={{ width: "21%" }}>
            <Form.Item
              {...field}
              name={[field.name, "synonyms"]}
              fieldKey={[field.fieldKey, "synonyms"]}
              rules={[
                {
                  validator: (_, value) => {
                    if (value) {
                      if (value.length === 0) {
                        return Promise.resolve();
                      } else {
                        let error = false;
                        for (let i = 0; i < value.length; i++) {
                          if (value[i].trim().length < 2) {
                            error = true;
                            break;
                          }
                        }
                        if (error) {
                          return Promise.reject(
                            new Error(
                              "Synonyms should be atleast 2 characters long"
                            )
                          );
                        } else {
                          return Promise.resolve();
                        }
                      }
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Select
                mode="tags"
                style={{ width: "95%" }}
                maxTagCount="responsive"
                placeholder="Enter synonyms"
              ></Select>
            </Form.Item>
          </div>
          <ConditionalRender shouldRender={virtualTable}>
            <div style={{ width: "18%" }}>
              <Form.Item
                name={[field.name, "virtual_sql_query"]}
                rules={[
                  {
                    validator: (_, value) => {
                      if (value) {
                        if (value.trim().length === 0) {
                          return Promise.reject(
                            new Error("SQL query is required")
                          );
                        }
                        return Promise.resolve();
                      }
                      return Promise.reject("SQL query is required");
                    },
                  },
                ]}
              >
                <SqlQuery
                  tableName={tableName}
                  selectedConnection={selectedConnection}
                  mAiApp={mAiApp}
                  setFieldValue={setFieldsValue}
                  tables={tables}
                  fieldName={field.name}
                  getFieldsValue={getFieldsValue}
                  setUnsavedChanges={setUnsavedChanges}
                />
              </Form.Item>
            </div>
          </ConditionalRender>
          <div style={{ width: "7%" }}>
            <div className={Style.columnContainer}>
              <div>
                <Form.Item
                  name={[field.name, "columns"]}
                  rules={[
                    {
                      validator: (_, value) => {
                        if (value) {
                          if (value.length === 0) {
                            return Promise.reject(
                              new Error("Column is required")
                            );
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject("Column is required");
                      },
                    },
                  ]}
                >
                  <SqlColumn
                    tableName={tableName}
                    virtualTable={virtualTable}
                    setUnsavedChanges={setUnsavedChanges}
                  />
                </Form.Item>
              </div>
              <div>
                <DeleteOutlined
                  className="delete-btn"
                  style={{ marginTop: "8px" }}
                  onClick={() => remove(field.name)}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ flexGrow: "1" }}>
        <Form
          ref={formRef}
          initialValues={sqlData}
          onFinish={(val) => {
            addConnectionlevelTable(val);
          }}
          onValuesChange={(changedValues, allValues) => {
            if (!unsavedChanges) {
              setUnsavedChanges(true);
            }
          }}
        >
          <Form.List name={virtualTable ? "virtual_tables" : "tables"}>
            {(fields, { add, remove }) => {
              return (
                <Form.Item
                  shouldUpdate={(prevValues, curValues) => {
                    return prevValues.additional !== curValues.additional;
                  }}
                  noStyle
                >
                  {({ getFieldsValue, setFieldsValue }) => {
                    return (
                      <>
                        <ConditionalRender shouldRender={unsavedChanges}>
                          <Row justify="end">
                            <Col className={Style.unsavedChanges}>
                              Unsaved changes
                            </Col>
                          </Row>
                        </ConditionalRender>
                        <Row></Row>
                        <Row
                          gutter={[8, 8]}
                          justify={virtualTable ? "end" : "space-between"}
                        >
                          <ConditionalRender shouldRender={!virtualTable}>
                            <Col>
                              <SecondaryButton
                                className={"small"}
                                onClick={() => setExploreDataBaseDrawer(true)}
                              >
                                Explore database
                              </SecondaryButton>
                            </Col>
                          </ConditionalRender>
                          <Col>
                            <SecondaryButton
                              className={"small"}
                              onClick={() => add({ is_active: true })}
                            >
                              <PlusOutlined />
                              &nbsp;
                              {virtualTable ? "Add Virtual Table" : "Add Table"}
                            </SecondaryButton>
                          </Col>
                        </Row>
                        <Drawer
                          maskClosable={true}
                          onClose={() => setExploreDataBaseDrawer(false)}
                          open={exploreDataBaseDrawer}
                          destroyOnClose
                          width="80%"
                          footer={null}
                          title="Explore Database"
                        >
                          <ExploreDatabase
                            selectedConnection={selectedConnection}
                            clientId={clientId}
                            formRef={formRef}
                            setExploreDataBaseDrawer={setExploreDataBaseDrawer}
                          />
                        </Drawer>
                        <div style={{ overflow: "auto" }}>
                          <List
                            dataSource={fields}
                            style={{ minWidth: 768 }}
                            header={
                              <div className={Style.listHeader}>
                                <div style={{ width: "3%" }}></div>
                                <ConditionalRender shouldRender={!virtualTable}>
                                  <div style={{ width: "18%" }}>
                                    Schema name
                                  </div>
                                </ConditionalRender>
                                <div style={{ width: "18%" }}>Name</div>
                                <div style={{ width: "33%" }}>Description</div>
                                <div style={{ width: "21%" }}>Synonyms</div>
                                <ConditionalRender shouldRender={virtualTable}>
                                  <div style={{ width: "18%" }}>SQL Query</div>
                                </ConditionalRender>
                                <div style={{ width: "7%" }}>Action</div>
                              </div>
                            }
                            renderItem={(field, idx) => {
                              const tables = getFieldsValue([
                                virtualTable ? "virtual_tables" : "tables",
                              ]);
                              let tableName = "";
                              if (virtualTable) {
                                tableName =
                                  tables?.virtual_tables?.[field.name]
                                    ?.table_name;
                              } else {
                                tableName =
                                  tables?.tables?.[field.name]?.table_name;
                              }

                              return (
                                <ListItem
                                  field={field}
                                  idx={idx}
                                  remove={remove}
                                  tableName={tableName}
                                  tables={tables}
                                  setFieldsValue={setFieldsValue}
                                  getFieldsValue={getFieldsValue}
                                />
                              );
                            }}
                          />
                        </div>
                      </>
                    );
                  }}
                </Form.Item>
              );
            }}
          </Form.List>
        </Form>
      </div>
      <Row justify="end" style={{ paddingBottom: "1rem", marginTop: 16 }}>
        <SecondaryButton
          loading={loading}
          className={CommonStyle.button}
          onClick={() => formRef.current.submit()}
        >
          Save
        </SecondaryButton>
      </Row>
    </div>
  );
}
