import ApiUrls from 'src/constants/apiUrls';
import axios from 'src/http/index';
import { AUDIENCES_CREATED } from '../constants/constants';

export default class createAudienceService {
  static async getAudienceRecommendation(prompt) {
    const url = ApiUrls.GET_AUDIENCE_RECOMMENDATIONS;
    const response = await axios.post(url, { query: prompt });
    return response.data;
  }

  static async getAudienceMetadata(clientId, includeInRuleBuilder, isProfileable) {
    const url = ApiUrls.GET_AUDIENCE_META_DATA(clientId, includeInRuleBuilder, isProfileable);
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceRecommendations(clientId, aiPrompt, audienceType) {
    const url = ApiUrls.GET_AUDIENCE_RULE_RECOMMENDATIONS;
    const payload = {
      client_space_id: clientId,
      user_query: aiPrompt,
      audience_type: audienceType,
    };
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getClusterList(clientId) {
    const url = ApiUrls.GET_CLUSTER_LISTING(clientId, 1, '', 1000, AUDIENCES_CREATED);
    const response = await axios.get(url);
    return response.data;
  }

  static async createAudience(payload, profiling, value) {
    const url = ApiUrls.CREATE_AUDIENCE;
    const requestData = {
      ...payload,
      trigger_profiling: profiling,
    };

    if (value > 0) {
      requestData['behavioural_segment_count'] = value;
    }

    const response = await axios.put(url, requestData);
    return response.data;
  }

  static async getAudienceFeature(clientId, searchKey) {
    const url = ApiUrls.GET_AUDIENCE_SEARCH_FEATURE(clientId, searchKey);
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceSize(clientId, payload) {
    const url = ApiUrls.GET_CREATE_AUDIENCE_SIZE(clientId);
    const response = await axios.post(url, payload);
    return response.data;
  }

  static async getAudienceBehaviorProbability(audienceId) {
    const url = ApiUrls.GET_AUDIENCE_BEHAVIOUR_PROBABILITY(audienceId);
    const response = await axios.get(url);
    return response.data;
  }

  static async getAudienceBehaviouralDirectory(payload) {
    const url = ApiUrls.GET_AUDIENCE_BEHAVIOURAL_DIRECTORY;
    const response = await axios.post(url, payload);
    return response.data;
  }
}
