import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { formatNumberWithCeil, getErrorMessageFromResponse } from 'src/lib/utils';
import audienceListService from '../../../services/audienceListService';

const useDemographicHook = ({ audienceFeatures, audienceId }) => {
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(true);
  const [audienceChart, setAudienceChart] = useState({});
  const [selectedFeature, setSelectedFeature] = useState(audienceFeatures?.demographic_features?.[0]?.feature_name);
  const [selectedFeatureReadableName, setSelectedFeatureReadableName] = useState(
    audienceFeatures?.demographic_features?.[0]?.feature_readable_name
  );
  const [genderData, setGenderData] = useState({});
  const [ageData, setAgeData] = useState({});
  const [householdData, setHouseHoldData] = useState({});
  const [educationData, setEducationData] = useState({});
  const [maritalStatusData, setMaritalStatusData] = useState({});
  const [numberOfChildrenData, setNumberOfChildrenData] = useState({});
  const [houseHoldCompositionData, setHouseHoldCompositionData] = useState({});
  const [homeOwnerData, setHomeOwnerData] = useState({});
  const [homeValueData, setHomeValueData] = useState({});
  const [urbanicityData, setUrbanicityData] = useState({});
  const [netAssetData, setNetAssetData] = useState({});
  const [buyingPowerScoreData, setBuyingPowerScoreData] = useState({});
  const [regionalDistributionData, setRegionalDistributionData] = useState({});

  const fetchData = async (featureName, params = {}) => {
    return await audienceListService.getAudienceFeatureProfiles(
      audienceId,
      '',
      featureName,
      1,
      '',
      undefined,
      100,
      params
    );
  };

  const formatBarChartOptions = (title, data) => ({
    title: {
      text: title,
      left: 'center',
      textStyle: {
        color: '#ffffff',
        fontWeight: 'normal',
        fontSize: '1.17em',
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const { name, value, audiencePercentage } = params[0].data;
        return `<b>${name}</b>: ${audiencePercentage}% (Index: ${value})`;
      },
    },
    xAxis: {
      type: 'value',
      axisLine: {
        lineStyle: {
          color: '#ffffff',
        },
      },
      axisLabel: {
        color: '#ffffff',
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'category',
      data: data.map((item) => item.feature_readable_value),
      axisLabel: {
        interval: 0,
        rotate: 30,
        hideOverlap: true,
        formatter: (value) => {
          const len = value.length;
          const ellipsis = len > 17 ? '...' : '';
          if (len > 17) {
            return value.slice(0, 4) + ellipsis + value.slice(-14);
          }
          return value;
        },
        textStyle: {
          color: '#ffffff',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#ffffff',
        },
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        data: data.map((item) => ({
          name: item.feature_readable_value,
          value: Math.ceil(item.index_value),
          audiencePercentage: Math.ceil(item.audience_feature_percentage),
        })),
        label: {
          show: true,
          position: 'insideLeft',
          formatter: ({ data }) => `${data.value}`,
          color: '#ffffff',
          distance: 5,
        },
        itemStyle: {
          color: '#5470c6',
        },
        emphasis: {
          label: {
            show: true,
            position: 'insideLeft',
            formatter: ({ data }) => `${data.value}`,
            color: '#ffffff',
          },
        },
      },
    ],
  });

  const formatPieChartOptions = (title, data) => ({
    title: {
      text: title,
      left: 'center',
      textStyle: {
        color: '#ffffff',
        fontWeight: 'normal',
        fontSize: '1.17em',
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        // Show both audience percentage and index value in the tooltip
        return `${params.name}: ${params.value}% (Index: ${params.data.indexValue})`;
      },
    },
    series: [
      {
        name: title,
        type: 'pie',
        radius: '50%',
        data: data.map((item) => ({
          value: Math.ceil(item.audience_feature_percentage),
          name: item.feature_readable_value,
          indexValue: Math.ceil(item.index_value), // Store index value for label
        })),
        label: {
          show: true,
          formatter: ({ data }) => `${data.name}\n${data.value}% (Index: ${data.indexValue})`,
          color: '#ffffff',
          fontSize: 12,
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  });

  const formatVerticalBarChartOptions = (title, data) => ({
    title: {
      text: title,
      left: 'center',
      textStyle: {
        color: '#ffffff',
        fontWeight: 'normal',
        fontSize: '1.17em',
      },
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const { name, value, audiencePercentage } = params[0].data;
        return `<b>${name}</b>: ${audiencePercentage}% (Index: ${value})`;
      },
    },
    xAxis: {
      type: 'category',
      data: data.map((item) => item.feature_readable_value),
      axisLabel: {
        interval: 0,
        rotate: 30,
        hideOverlap: true,
        formatter: (value) => {
          const len = value.length;
          const ellipsis = len > 17 ? '...' : '';
          if (len > 17) {
            return value.slice(0, 4) + ellipsis + value.slice(-14);
          }
          return value;
        },
        textStyle: {
          color: '#ffffff',
        },
      },
      axisLine: {
        lineStyle: {
          color: '#ffffff',
        },
      },
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        color: '#ffffff',
      },
      axisLine: {
        lineStyle: {
          color: '#ffffff',
        },
      },
      splitLine: {
        show: false,
      },
    },
    series: [
      {
        type: 'bar',
        data: data.map((item) => ({
          name: item.feature_readable_value,
          value: Math.ceil(item.index_value),
          audiencePercentage: Math.ceil(item.audience_feature_percentage),
        })),
        label: {
          show: true,
          position: 'top',
          formatter: ({ data }) => `${data.value}`,
          color: '#ffffff',
          distance: 5,
        },
        itemStyle: {
          color: '#5470c6',
        },
        emphasis: {
          label: {
            show: true,
            position: 'top',
            formatter: ({ data }) => `${data.value}`,
            color: '#ffffff',
          },
        },
      },
    ],
  });

  const defaultLoad = async () => {
    try {
      setPageLoading(true);
      const gender = await fetchData('GENDER');
      const age = await fetchData('age_ranges_rollup');
      const household = await fetchData('est_household_income');
      const education = await fetchData('education');
      const maritalStatus = await fetchData('marital_status_married');
      const numberOfChildren = await fetchData('number_of_children_in_living_unit_rollup');
      const houseHoldComposition = await fetchData('hh_composition');
      const homeOwner = await fetchData('homeowner');
      const homeValue = await fetchData('home_business');
      const urbanicity = await fetchData('census_rural_urban_county_size_code_rollup');
      const netAsset = await fetchData('net_asset_value_rollup');
      const butingPowerScore = await fetchData('BUYING_POWER_SCORE');
      const regionalDistribution = await fetchData('regional_distribution');

      if (gender?.data?.data?.length > 0) {
        const male = gender.data.data.find((item) => item.feature_value === '0');
        const female = gender.data.data.find((item) => item.feature_value === '1');
        setGenderData({
          male: formatNumberWithCeil(male.audience_feature_percentage),
          female: formatNumberWithCeil(female.audience_feature_percentage),
        });
      }

      setAgeData(formatBarChartOptions('Age Distribution', age.data.data));
      setMaritalStatusData(formatPieChartOptions('Marital Status Distribution', maritalStatus.data.data));
      setNumberOfChildrenData(formatBarChartOptions('Number of Children Distribution', numberOfChildren.data.data));
      setHouseHoldCompositionData(
        formatPieChartOptions('Household Composition Distribution', houseHoldComposition.data.data)
      );
      setHomeOwnerData(formatPieChartOptions('Home Owner/Renter Status Distribution', homeOwner.data.data));
      setHomeValueData(formatPieChartOptions('Home Business Distribution', homeValue.data.data));
      setUrbanicityData(formatBarChartOptions('Urbanicity', urbanicity.data.data));
      setHouseHoldData(formatVerticalBarChartOptions('Household Income', household.data.data));
      setNetAssetData(formatVerticalBarChartOptions('Net Asset Value', netAsset.data.data));
      setBuyingPowerScoreData(formatVerticalBarChartOptions('Buying Power Score', butingPowerScore.data.data));
      setEducationData(formatVerticalBarChartOptions('Education', education.data.data));
      setRegionalDistributionData(
        formatVerticalBarChartOptions('Regional Distribution', regionalDistribution.data.data)
      );

      if (audienceFeatures?.demographic_features.length > 0) {
        await getChartData(audienceFeatures.demographic_features[0].feature_name);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const formatStackedBarChart = (data) => {
    const audienceData = data.map((item) => ({
      value: Math.ceil(item.audience_feature_percentage),
      name: item.feature_readable_value,
      indexValue: Math.round(item.index_value),
    }));

    const baselineData = data.map((item) => ({
      value: Math.ceil(item.base_feature_percentage),
      name: item.feature_readable_value,
    }));

    return { audienceData, baselineData };
  };

  const getChartData = async (featureName) => {
    try {
      setLoading(true);
      const audienceChartData = await fetchData(featureName);
      const { audienceData, baselineData } = formatStackedBarChart(audienceChartData.data.data);

      const stackedBarChartOption = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: (params) =>
            params
              .map((item) => {
                if (typeof item.data === 'object') {
                  return `Index Value: ${item.data.indexValue}<br/>${item.seriesName}: ${item.data.value}%`;
                }
                return `${item.seriesName}: ${item.data}%`;
              })
              .join('<br/>'),
        },
        legend: {
          data: ['Audience Percentage', 'Total Population Percentage'],
          textStyle: {
            color: '#ffffff',
          },
        },
        xAxis: {
          type: 'category',
          data: audienceData.map((item) => item.name),
          axisLabel: {
            color: '#ffffff',
          },
          axisLine: {
            lineStyle: {
              color: '#ffffff',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#444444',
            },
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#ffffff',
          },
          axisLine: {
            lineStyle: {
              color: '#ffffff',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#444444',
            },
          },
        },
        series: [
          {
            name: 'Audience Percentage',
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              formatter: '{c}%',
              color: '#ffffff',
            },
            data: audienceData.map((item) => ({ value: item.value, indexValue: item.indexValue })),
            itemStyle: {
              color: '#1f77b4',
            },
            barGap: '-30%',
            barCategoryGap: '50%',
          },
          {
            name: 'Total Population Percentage',
            type: 'bar',
            label: {
              show: true,
              position: 'insideBottom',
              formatter: '{c}%',
              color: '#ffffff',
            },
            data: baselineData.map((item) => item.value),
            itemStyle: {
              color: '#ff7f0e',
            },
            barGap: '-30%',
            barCategoryGap: '50%',
          },
        ],
      };
      setAudienceChart(stackedBarChartOption);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const onFeatureChange = (featureName) => {
    const obj = audienceFeatures.demographic_features?.find((item) => item.feature_name === featureName);
    if (obj) {
      setSelectedFeatureReadableName(obj.feature_readable_name);
    }
    setSelectedFeature(featureName);
    getChartData(featureName);
  };

  useEffect(() => {
    defaultLoad();
  }, []);

  return {
    pageLoading,
    loading,
    audienceChart,
    selectedFeature,
    onFeatureChange,
    selectedFeatureReadableName,
    genderData,
    ageData,
    householdData,
    educationData,
    maritalStatusData,
    numberOfChildrenData,
    houseHoldCompositionData,
    homeOwnerData,
    homeValueData,
    urbanicityData,
    regionalDistributionData,
    netAssetData,
    buyingPowerScoreData,
  };
};

export default useDemographicHook;
