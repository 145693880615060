const theme = {
  token: {
    colorTextPlaceholder: '#C4C4C4',
    colorText: 'white',
    colorPrimary: '#DC5A4B',
  },
  components: {
    DatePicker: {
      cellActiveWithRangeBg: '#DC5A4B1A',
    },
    Slider: {
      railBg: 'white',
      railHoverBg: 'white',
      railSize: 10,
      dotSize: 16,
      handleSize: 14,
    },
    InputNumber: {
      controlWidth: 100,
    },
  },
};

export default theme;
