import React from 'react';
import ReactECharts from 'echarts-for-react';
import { useCommunityGraphHooks } from './communityGraph.hooks';
import { Select, Table } from 'antd';
import Styles from './communityGraph.style';

const { Option } = Select;

const CommunityGraphComponent = ({ communities, setCommunitiesFilter }) => {
  const {
    option,
    onChartEvents,
    DROPDOWN_METRICS,
    setSelectedMetric,
    selectedMetric,
    dataSource,
    columns,
    selectedCommunity,
  } = useCommunityGraphHooks({
    communities,
    setCommunitiesFilter,
  });

  return (
    <div className={Styles.communityGraphContainer}>
      <Select value={selectedMetric} style={{ width: 200, marginBottom: 20 }} onChange={setSelectedMetric}>
        {Object.keys(DROPDOWN_METRICS).map((key, idx) => (
          <Option key={idx} value={DROPDOWN_METRICS[key].value}>
            {DROPDOWN_METRICS[key].label}
          </Option>
        ))}
      </Select>
      <ReactECharts option={option} style={{ height: '700px', width: '100%' }} onEvents={onChartEvents} />
      {selectedCommunity && (
        <div className={Styles.communityDetails}>
          <div className={Styles.communityName}>{selectedCommunity?.name}</div>
          <div>{selectedCommunity?.description}</div>
          <br />
          <div className={Styles.communityStatsTable}>
            <Table className="dark-table" dataSource={dataSource} columns={columns} pagination={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export default CommunityGraphComponent;
