// Constants for operator values and labels
export const OPERATOR_EQUALS = "=";
export const OPERATOR_NOT_EQUALS = "!=";
export const OPERATOR_GREATER_THAN = ">";
export const OPERATOR_LESS_THAN = "<";
export const OPERATOR_GREATER_THAN_OR_EQUAL = ">=";
export const OPERATOR_LESS_THAN_OR_EQUAL = "<=";
export const OPERATOR_CONTAINS = "contains";
export const OPERATOR_STARTS_WITH = "startsWith";
export const OPERATOR_ENDS_WITH = "endsWith";
export const OPERATOR_IN = "in";
export const OPERATOR_NOT_IN = "not in";
export const OPERATOR_BETWEEN = "between";
export const OPERATOR_NOT_BETWEEN = "not between";
export const OPERATOR_LIKE = "like";
export const OPERATOR_NOT_LIKE = "not like";
export const OPERATOR_IS = "is";

// Constants for combinator values
export const COMBINATOR_AND = "and";
export const COMBINATOR_OR = "or";
export const COMBINATOR_ANY = "any";
export const COMBINATOR_MAX = "max";

// Constants for UI text
export const LABEL_ADD_RULE = "Add Rule";
export const LABEL_ADD_GROUP = "Add Group";
export const LABEL_DEMOGRAPHIC_GROUP = "Demographic Group";
export const LABEL_BEHAVIOUR_GROUP = "Enrichment Group";
export const LABEL_FIRST_PARTY_GROUP = "First Party Group";
export const LABEL_QUERY_SUMMARY = "Query Summary:";
export const LABEL_NO_DATA = "No Data";
export const LABEL_ERROR_BOUNDARY_FALLBACK = "Cannot render query builder";
export const AI_LOADING_TEXT = "Please wait while AI finds the best audiences for you...";

// Exported operators array using constants
export const QUERY_OPERATORS = [
    { value: OPERATOR_EQUALS, label: "Equals" },
    { value: OPERATOR_NOT_EQUALS, label: "Not Equals" },
    { value: OPERATOR_GREATER_THAN, label: "Greater Than" },
    { value: OPERATOR_LESS_THAN, label: "Less Than" },
    { value: OPERATOR_GREATER_THAN_OR_EQUAL, label: "Greater Than or Equal" },
    { value: OPERATOR_LESS_THAN_OR_EQUAL, label: "Less Than or Equal" },
    { value: OPERATOR_CONTAINS, label: "Contains" },
    { value: OPERATOR_STARTS_WITH, label: "Starts With" },
    { value: OPERATOR_ENDS_WITH, label: "Ends With" },
    { value: OPERATOR_IN, label: "In" },
    { value: OPERATOR_NOT_IN, label: "Not In" },
    { value: OPERATOR_BETWEEN, label: "Between" },
    { value: OPERATOR_NOT_BETWEEN, label: "Not Between" },
    { value: OPERATOR_LIKE, label: "Like" },
    { value: OPERATOR_NOT_LIKE, label: "Not Like" },
    { value: OPERATOR_IS, label: "Is" },
];

// Exported category operators
export const CATEGORY_OPERATORS = [
    { value: OPERATOR_EQUALS, label: "Equals" },
    { value: OPERATOR_NOT_EQUALS, label: "Not Equals" },
    { value: OPERATOR_IN, label: "In" },
    { value: OPERATOR_NOT_IN, label: "Not In" },
    { value: OPERATOR_CONTAINS, label: "Contains" },
];

// Exported additional category operators
export const ADDITIONAL_CATEGORY_OPERATORS = [
    { value: OPERATOR_GREATER_THAN, label: "Greater Than" },
    { value: OPERATOR_GREATER_THAN_OR_EQUAL, label: "Greater Than or Equal" },
    { value: OPERATOR_LESS_THAN, label: "Less Than" },
    { value: OPERATOR_LESS_THAN_OR_EQUAL, label: "Less Than or Equal" },
];

// Exported numerical operators
export const NUMERICAL_OPERATORS = [
    { value: OPERATOR_EQUALS, label: "Equals" },
    { value: OPERATOR_NOT_EQUALS, label: "Not Equals" },
    { value: OPERATOR_GREATER_THAN, label: "Greater Than" },
    { value: OPERATOR_GREATER_THAN_OR_EQUAL, label: "Greater Than or Equal" },
    { value: OPERATOR_LESS_THAN, label: "Less Than" },
    { value: OPERATOR_LESS_THAN_OR_EQUAL, label: "Less Than or Equal" },
    { value: OPERATOR_BETWEEN, label: "Between" },
    { value: OPERATOR_NOT_BETWEEN, label: "Not Between" },
    { value: OPERATOR_IN, label: "In" },
    { value: OPERATOR_NOT_IN, label: "Not In" },
];

// Exported date operators
export const DATE_OPERATORS = [
    { value: OPERATOR_EQUALS, label: "Equals" },
    { value: OPERATOR_NOT_EQUALS, label: "Not Equals" },
    { value: OPERATOR_GREATER_THAN, label: "Greater Than" },
    { value: OPERATOR_GREATER_THAN_OR_EQUAL, label: "Greater Than or Equal" },
    { value: OPERATOR_LESS_THAN, label: "Less Than" },
    { value: OPERATOR_LESS_THAN_OR_EQUAL, label: "Less Than or Equal" },
    { value: OPERATOR_BETWEEN, label: "Between" },
    { value: OPERATOR_NOT_BETWEEN, label: "Not Between" },
    { value: OPERATOR_IN, label: "In" },
    { value: OPERATOR_NOT_IN, label: "Not In" },
];

// Exported special fields
export const SPECIAL_FIELDS = [
    'ESTIMATED_HOUSEHOLD_INCOME',
    'NET_ASSET_VALUE',
    'DWELLING_UNIT_SIZE',
];

// Exported DataFeatureType using Object.freeze
export const DataFeatureType = Object.freeze({
    BOOLEAN: "boolean",
    CATEGORICAL: "categorical",
    NUMERIC: "numeric",
    DATE: "date",
});

// Initial query structure
export const initialQuery = {
    combinator: COMBINATOR_AND,
    rules: [],
};

// Default behavior values
export const defaultBehaviourValues = [
    {
        value: "0",
        value_description: "False",
    },
    {
        value: "1",
        value_description: "True",
    },
];

export const BEHAVIOUR = "behavioural";
export const DEMOGRAPHIC = "demographic";
export const FIRST_PARTY = "first_party";