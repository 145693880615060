import React from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import AppUrls from 'src/constants/appUrls';
import AppContext from 'src/context/appContext';
import { MEDIA_PLAN_PAGE } from '../../../constants/constants';
import ListingTable from '../components/ListingTable';
import './MediaPlansListing.less';
import useMediaPlanListing from './useMediaPlanListing';

const MediaPlansListing = () => {
  const appContext = React.useContext(AppContext.Context);
  const { t } = useTranslation();
  const { loading, page, onPaginationChange, mediaData, history, clientId, column_media_listing } =
    useMediaPlanListing();

  return (
    <div className="media-listing-parent">
      <div className="page-header-layout">
        <div>
          <h3>
            {MEDIA_PLAN_PAGE.MEDIA_HEADER} : {appContext?.clientSpaceData?.client_space?.name}
          </h3>
          <p className="media-description">{MEDIA_PLAN_PAGE.MEDIA_DESCRIPTION}</p>
        </div>
        <div>
          <PrimaryButton
            icon={<PlusOutlined />}
            onClick={() =>
              history.push({
                pathname: AppUrls.CREATE_MEDIA_PLAN(clientId),
              })
            }
          >
            {t('mediaplan.new_plan')}
          </PrimaryButton>
          <p className="media-btn-description">{MEDIA_PLAN_PAGE.MEDIA_BTN_DESCRIPTION}</p>
        </div>
      </div>
      <div>
        <div style={{ marginTop: 32 }}>
          <ListingTable
            data={mediaData}
            columns={column_media_listing}
            currentPage={page}
            onPaginationChange={onPaginationChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default MediaPlansListing;
