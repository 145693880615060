import { DeleteOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'src/components/basic';
import Style from './createForm.style';

const ConversationStartersConfig = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className={Style.advancedSettingHeader}>{t('client_space.conversation_starters')}</div>
      <div style={{ margin: '0 21px' }}>
        <Form.List name="conversation_starters">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field) => (
                <div key={field.key} className={Style.conversationStarterContainer}>
                  <div style={{ flexGrow: '1' }}>
                    <Form.Item
                      {...field}
                      name={[field.name]}
                      fieldKey={[field.fieldKey, 'conversation_starters']}
                      rules={[{ required: true, message: 'Input is required.' }]}
                    >
                      <Input placeholder={t('client_space.enter_conversation_starter')} />
                    </Form.Item>
                  </div>
                  <DeleteOutlined onClick={() => remove(field.name)}>{t('delete')}</DeleteOutlined>
                </div>
              ))}
              {fields.length < 4 && (
                <SecondaryButton className="small" onClick={() => add()}>
                  Add
                </SecondaryButton>
              )}
            </>
          )}
        </Form.List>
      </div>
    </>
  );
};

export default ConversationStartersConfig;
