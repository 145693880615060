import { DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Col, List, Modal, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import { formatString } from 'src/lib/utils';
import AddUserForm from './AddUserForm';
import Style from './clientSpaceUsersAndPermissions.style';
import useClientSpaceAndPermissionsHook from './useClientSpaceAndPermissionsHook';

const ClientSpaceUserAndPermissions = ({ metaData, clientId }) => {
  const { t } = useTranslation();
  const {
    laoding,
    selectedValues,
    setSelectedValues,
    userMapping,
    updateUserAccess,
    configureLoading,
    setUnsavedChanges,
    addUserModal,
    setAddUserModal,
    ownerRoleId,
    deleteUser,
    userEmail,
  } = useClientSpaceAndPermissionsHook({
    metaData,
    clientId,
  });
  if (laoding) {
    return <LoadingSpinner centered />;
  }
  return (
    <div className={Style.container}>
      <Row gutter={[20, 0]} justify="end" style={{ marginBottom: '1rem' }}>
        <Col>
          <PrimaryButton className="small" comfy onClick={() => setAddUserModal(true)}>
            {t('add_user')}
          </PrimaryButton>
        </Col>
      </Row>
      <Checkbox
        disabled={configureLoading}
        checked={selectedValues.assign_to_all_users}
        onChange={(e) => {
          updateUserAccess('assignAll', e.target.checked);
        }}
        style={{ marginBottom: '1rem' }}
      >
        {t('enable_for_all_gale_users')}
      </Checkbox>
      <List
        className={Style.list}
        loading={configureLoading}
        dataSource={[...(selectedValues?.users || [])]}
        header={
          <div className={Style.listHeader}>
            <div style={{ width: '40%' }}>{t('email')}</div>
            <div style={{ width: '40%' }}>{t('name')}</div>
            <div style={{ width: '26%' }}>{t('role')}</div>
          </div>
        }
        renderItem={(item) => {
          let name = '';
          if (userMapping[item?.email]?.first_name) {
            name = name + userMapping[item?.email]?.first_name;
          }
          if (userMapping[item?.email]?.last_name) {
            name = name + ' ' + userMapping[item?.email]?.last_name;
          }
          let isOwner = false;
          if (item?.role === ownerRoleId) {
            isOwner = true;
          }
          return (
            <div className={Style.listItem}>
              <div style={{ width: '37%' }}>{item?.email}</div>
              <div style={{ width: '37%' }}>{name}</div>
              <div style={{ width: '26%' }} className={Style.deleteContainer}>
                <ConditionalRender shouldRender={isOwner}>{t('client_owner')}</ConditionalRender>
                <ConditionalRender shouldRender={!isOwner}>
                  <Select
                    className="small"
                    value={item?.role}
                    style={{ width: '50%' }}
                    onChange={(val) => {
                      updateUserAccess('changeRole', { email: item?.email, role: val, id: item?.id });
                    }}
                  >
                    {metaData?.client_roles?.map((ele) => {
                      if (ele?.name !== 'CLIENT_OWNER') {
                        return (
                          <Select.Option key={ele?.id} value={ele?.id}>
                            {formatString(ele?.name)}
                          </Select.Option>
                        );
                      }
                    })}
                  </Select>
                </ConditionalRender>
                <div>
                  {isOwner || item?.email === userEmail ? '' : <DeleteOutlined onClick={() => deleteUser(item?.id)} />}
                </div>
              </div>
            </div>
          );
        }}
      />
      <Modal
        title={t('add_user')}
        maskClosable={false}
        destroyOnClose
        open={addUserModal}
        onCancel={() => setAddUserModal(false)}
        footer={null}
      >
        <AddUserForm
          setUnsavedChanges={setUnsavedChanges}
          setSelectedValues={setSelectedValues}
          selectedValues={selectedValues}
          metaData={metaData}
          setAddUserModal={setAddUserModal}
          updateUserAccess={updateUserAccess}
        />
      </Modal>
    </div>
  );
};

export default ClientSpaceUserAndPermissions;
