import { Select, Table, Typography } from 'antd';
import React from 'react';

import './audienceOverview.style.less';
import useMultiplierTabHook from './useMultiplierTabHook';

const { Title } = Typography;

const MultiplierTabComponent = ({ audienceProfileId, clientId }) => {
  const {
    audienceMultiplierData,
    multiplierLoading,
    totalMultiplierData,
    getMultiplierColumns,
    currentPage,
    setCurrentPage,
    handleSortChange,
    channelList,
    selectedChannel,
    setSelectedChannel,
    selectedTacticPartner,
    setSelectedTacticPartner,
    tacticPartnerList,
  } = useMultiplierTabHook({ audienceProfileId, clientId });

  return (
    <div className="traits-table">
      <div className="table-header">
        <Title level={4}>Audience Multipliers</Title>
      </div>
      <div className="controls-row">
        <div className="feature-select-section">
          <label>Select Channel</label>
          <Select
            style={{ width: 300 }}
            placeholder="Select a channel"
            onChange={(value) => setSelectedChannel(value)}
            value={selectedChannel}
          >
            {channelList.map((channel) => (
              <Select.Option key={channel} value={channel}>
                {channel}
              </Select.Option>
            ))}
            <Select.Option value={''}>All</Select.Option>
          </Select>
        </div>
        <div className="feature-select-section">
          <label>Select Tactic/Partner</label>
          <Select
            style={{ width: 300 }}
            placeholder="Select a tactic/partner"
            onChange={(value) => setSelectedTacticPartner(value)}
            value={selectedTacticPartner}
          >
            {tacticPartnerList?.map((tacticPartner) => (
              <Select.Option key={tacticPartner} value={tacticPartner}>
                {tacticPartner}
              </Select.Option>
            ))}
            <Select.Option value={''}>All</Select.Option>
          </Select>
        </div>
      </div>
      <Table
        dataSource={audienceMultiplierData}
        columns={getMultiplierColumns()}
        pagination={{
          total: totalMultiplierData,
          showSizeChanger: false,
          current: currentPage,
          position: ['bottomCenter'],
          onChange: (page) => setCurrentPage(page),
        }}
        className="dark-table"
        loading={multiplierLoading}
        onChange={handleSortChange}
      />
    </div>
  );
};

export default MultiplierTabComponent;
