import { Button, DatePicker, Form, Select, TimePicker } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styles from './styles';

const { Option } = Select;

const ScheduleTaskForm = ({ onScheduleSubmit }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onScheduleSubmit(values);
  };

  return (
    <div className={Styles.darkFormWrapper}>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="startDate"
          label={t('labeler.start_date')}
          rules={[{ required: true, message: t('labeler.start_date_error') }]}
        >
          <DatePicker />
        </Form.Item>
        <Form.Item
          name="startTime"
          label={t('labeler.start_time')}
          rules={[{ required: true, message: t('labeler.start_time_error') }]}
        >
          <TimePicker format="HH:mm" />
        </Form.Item>
        <Form.Item
          name="repeatFrequency"
          label={t('labeler.repeat_frequency')}
          rules={[{ required: true, message: t('labeler.repeat_frequency_error') }]}
        >
          <Select>
            <Option value="NONE">{t('labeler.do_not_repeat')}</Option>
            <Option value="HOURLY">{t('labeler.hourly')}</Option>
            <Option value="DAILY">{t('labeler.daily')}</Option>
            <Option value="WEEKLY">{t('labeler.weekly')}</Option>
            <Option value="MONTHLY">{t('labeler.monthly')}</Option>
          </Select>
        </Form.Item>
        <Form.Item name="endDate" label="End Date (Optional)">
          <DatePicker />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {t('labeler.schedule_task_caps')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ScheduleTaskForm;
