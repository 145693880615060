import { CopyOutlined } from '@ant-design/icons';
import { Form, Input, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DATA_SOURCES, DATA_SOURCE_TYPE, SERVICE_ACCOUNT_EMAIL } from '../../constants';
import * as Styles from './styles';

const DataSourceSelection = ({ form }) => {
  const [selectedSource, setSelectedSource] = useState(null);
  const [tooltipText, setTooltipText] = useState('Copy to clipboard');
  const { t } = useTranslation();

  const handleSelect = (type) => {
    const selectedSource = DATA_SOURCES.find((source) => source.type === type);
    if (selectedSource && !selectedSource.disabled) {
      setSelectedSource(type);
      form.setFieldsValue({ dataSourceType: type });
      form.resetFields(['googleSheetUrl', 'databaseConnection']);
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(SERVICE_ACCOUNT_EMAIL).then(() => {
      setTooltipText('Copied!');
      setTimeout(() => setTooltipText('Copy to clipboard'), 2000);
    });
  };

  return (
    <div className={Styles.container}>
      <Form.Item name="dataSourceType" rules={[{ required: true, message: t('labeler.data_source_error') }]}>
        <div className={Styles.featuresGrid}>
          {DATA_SOURCES.map((source) => (
            <div
              key={source.type}
              className={`${Styles.featureCard} ${
                selectedSource === source.type ? Styles.selectedCard : ''
              } ${source.disabled ? Styles.disabledCard : ''}`}
              onClick={() => handleSelect(source.type)}
            >
              <div className={Styles.featureContent}>
                <div className={Styles.featureIcon}>{source.icon}</div>
                <h3 className={Styles.featureTitle}>{source.title}</h3>
                <p className={Styles.featureDescription}>
                  {source.disabled ? t('labeler.coming_soon') : t('labeler.connect')}
                </p>
                {source.disabled && <span className={Styles.soonBadge}>{t('labeler.soon')}</span>}
              </div>
            </div>
          ))}
        </div>
      </Form.Item>

      {selectedSource === DATA_SOURCE_TYPE.GOOGLE_SHEETS && (
        <div className={Styles.inputContainer}>
          <Form.Item
            label={t('labeler.google_sheet_url')}
            name="googleSheetUrl"
            rules={[
              { required: true, message: t('labeler.google_sheet_url_error') },
              { type: 'url', message: t('labeler.invalid_url_error') },
            ]}
          >
            <Input placeholder={t('labeler.google_sheet_url_placeholder')} />
          </Form.Item>

          <div className={Styles.infoBox}>
            <span className={Styles.infoText}>{t('labeler.important_message_google_sheet_url')}</span>
            <div className={Styles.serviceAccountContainer}>
              <span className={Styles.serviceAccount}>{SERVICE_ACCOUNT_EMAIL}</span>
              <Tooltip title={tooltipText}>
                <CopyOutlined className={Styles.copyIcon} onClick={handleCopy} />
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataSourceSelection;
