import { DeleteOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Spin, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import Style from '../NewsAndCulture.style';
import CreateAnalysisQuery from '../components/CreateAnalysisQuery';
import { LuceneQueryComponent } from '../components/CreateListingComponents/lucentQueryRowItem';
import NcBreadCrumb from '../components/NcBreadCrumb';
import DateRangePickerWithPresets from '../components/RangePicker';
import { rangePresets } from '../constants';
import useCreateListing from '../hooks/useCreateListing';

const { TextArea } = Input;

const CreateListingScreen = () => {
  const {
    currentStep,
    sections,
    isFormComplete,
    showActionSteps,
    form,
    handleBack,
    addSection,
    removeSection,
    addNewsQuery,
    addFacebookQuery,
    addTwitterQuery,
    removeQuery,
    handleBlur,
    mode,
    analysisCategories,
    buttonLoading,
    saveDraftLoading,
    pageLoading,
    handleSectionNameBlur,
    onFinish,
    onSaveDraft,
    onRangeChange,
    setCurrentStep,
    initialAnalysisData,
    onRadioChange,
    schedule,
    disabledDate,
    drawerVisible,
    setDrawerVisible,
    setQueryData,
    queryData,
    addUpdateQuery,
    addTiktokQuery,
    addInstagramQuery,
    addThreadsQuery,
    addUpdateQueryInstagram,
    instaFormData,
    setInstaFormData,
  } = useCreateListing();

  // const isViewOnly = mode === 'Done' && isScheduled === false;
  // making this true for time being
  const { t } = useTranslation();
  const isViewOnly = initialAnalysisData ? (initialAnalysisData.can_edit ? false : true) : false;

  const UpdatedAnalysisCategories = analysisCategories.map((option) => ({
    label: option.category,
    value: option.value,
  }));

  const renderQuery = (queries, sectionId, queryType, queryTypeHumanReadable, addQuery, sectionIndex) => {
    if (queries.length === 0) {
      return (
        <>
          <div className={!isViewOnly ? `${Style.noQueryParent}` : `${Style.viewOnly}`}>
            <p>
              {t('news_and_culture.no_queries_added_for')} {queryTypeHumanReadable}.{' '}
            </p>
          </div>
          {!isViewOnly && (
            <div className={Style.addbtnParent}>
              <SecondaryButton className={'small'} onClick={() => addQuery(sectionIndex)} disabled={pageLoading}>
                {t('add')} {queryTypeHumanReadable} {t('query')}
                <PlusOutlined />
              </SecondaryButton>
            </div>
          )}
        </>
      );
    }
    return (
      <>
        {queries.map((query, queryIndex) => {
          if (query?.itemData?.social_media_data_source === 'MANUAL_UPLOAD') {
            return (
              <div
                key={queryIndex}
                className={!isViewOnly ? `${Style.noQueryParent}` : `${Style.viewOnly}`}
                style={{ marginBottom: '10px' }}
              >
                <p>{t('news_and_culture.manually_uploaded_sub_query')}</p>
              </div>
            );
          }
          return (
            <div key={query.key}>
              <LuceneQueryComponent
                queryText={query.lucene_query}
                queryIndex={queryIndex}
                setDrawerVisible={setDrawerVisible}
                query={query}
                setQueryData={setQueryData}
                removeQuery={removeQuery}
                sectionIndex={sectionIndex}
                queryType={queryType}
                sectionId={sectionId}
                isViewOnly={isViewOnly}
                maxCount={query.max_count}
                setInstaFormData={setInstaFormData}
              />
            </div>
          );
        })}
        {!isViewOnly && (
          <div className={Style.addbtnParent}>
            <SecondaryButton className={'small'} onClick={() => addQuery(sectionIndex)} disabled={pageLoading}>
              {t('add')} {queryTypeHumanReadable} {t('query')}
              <PlusOutlined />
            </SecondaryButton>
          </div>
        )}
      </>
    );
  };

  const TagCategoriesMapping = ({ categories, removeCategory }) => {
    return (
      <>
        {categories.map((category) => (
          <Card key={category.key} className={Style.categoryCard}>
            {!isViewOnly && (
              <div className={Style.sectionActions}>
                <Tooltip title={t('news_and_culture.remove_category')}>
                  <DeleteOutlined
                    onClick={() => removeCategory(category.name)}
                    className="delete-btn"
                    disabled={pageLoading}
                  />
                </Tooltip>
              </div>
            )}
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  {...category}
                  label={t('news_and_culture.category_name')}
                  name={[category.name, 'category_name']}
                  rules={[
                    {
                      required: true,
                      message: 'Category name is required',
                    },
                  ]}
                >
                  <Input disabled={isViewOnly} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  {...category}
                  label={t('news_and_culture.category_description')}
                  name={[category.name, 'category_prompt']}
                >
                  <Input disabled={isViewOnly} />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        ))}
      </>
    );
  };

  return (
    <div className={Style.newsAndCultureContainer}>
      {pageLoading && (
        <div className={Style.pageLoaderContainer}>
          <Spin tip="Loading..." size="medium" />
        </div>
      )}
      <NcBreadCrumb
        className={Style.breadCrumbCreateScreen}
        name={initialAnalysisData?.name || ''}
        screen={currentStep}
      />
      <div className={Style.formContainer}>
        <Card className={Style.formCard}>
          <Form form={form} layout="vertical" onFinish={onFinish} disabled={pageLoading}>
            {currentStep === 1 && (
              <>
                <Form.Item
                  label={t('name')}
                  name="name"
                  rules={[{ required: true, message: 'Please input your name!' }]}
                >
                  <Input onBlur={() => handleBlur('name')} disabled={pageLoading || isViewOnly} />
                </Form.Item>
                <Form.Item
                  label={t('news_and_culture.tell_us_more_about_the_client')}
                  name="clientDetails"
                  rules={[{ required: false }]}
                >
                  <TextArea rows={2} onBlur={() => handleBlur('clientDetails')} disabled={pageLoading || isViewOnly} />
                </Form.Item>
                <Form.Item
                  label={t('news_and_culture.tell_us_more_about_the_problem_statement')}
                  name="problemStatement"
                  rules={[{ required: false }]}
                >
                  <TextArea
                    rows={2}
                    onBlur={() => handleBlur('problemStatement')}
                    disabled={pageLoading || isViewOnly}
                  />
                </Form.Item>
                {showActionSteps && (
                  <Form.Item
                    label={t('news_and_culture.tell_us_about_your_actionable_steps')}
                    name="actionSteps"
                    rules={[{ required: false }]}
                  >
                    <TextArea rows={2} disabled={pageLoading || isViewOnly} />
                  </Form.Item>
                )}
                <Form.Item>
                  {!isViewOnly ? (
                    <Button type="primary" htmlType="submit" loading={buttonLoading}>
                      {t('save_next')}
                    </Button>
                  ) : (
                    <Button type="primary" onClick={() => setCurrentStep(2)} disabled={pageLoading}>
                      {t('next')}
                    </Button>
                  )}
                </Form.Item>
              </>
            )}

            {currentStep === 2 && (
              <>
                <SecondaryButton
                  className={'small'}
                  onClick={handleBack}
                  disabled={pageLoading}
                  style={{ marginBottom: 16, padding: '6px 16px' }}
                >
                  <LeftOutlined style={{ position: 'relative', top: '1.5px' }} /> {t('back')}
                </SecondaryButton>
                <p className={Style.staticText}>
                  <span style={{ fontWeight: 500, fontSize: '15px' }}>{t('name')}:</span> {form.getFieldValue('name')}
                </p>
                {form.getFieldValue('clientDetails') && (
                  <p className={Style.staticText}>
                    <span style={{ fontWeight: 500, fontSize: '15px' }}>{t('news_and_culture.client_details')}:</span>{' '}
                    {form.getFieldValue('clientDetails')}
                  </p>
                )}
                {form.getFieldValue('problemStatement') && (
                  <p className={Style.staticText}>
                    <span style={{ fontWeight: 500, fontSize: '15px' }}>
                      {t('news_and_culture.problem_statement')}:
                    </span>{' '}
                    {form.getFieldValue('problemStatement')}
                  </p>
                )}
                {form.getFieldValue('actionSteps') && (
                  <p className={Style.staticText}>
                    <span style={{ fontWeight: 500, fontSize: '15px' }}>{t('news_and_culture.action_steps')}:</span>{' '}
                    {form.getFieldValue('actionSteps')}
                  </p>
                )}
                {sections.map((section, sectionIndex) => (
                  <>
                    <div key={section.key} className={Style.sectionContainer}>
                      {!isViewOnly && (
                        <div className={Style.sectionActions}>
                          {sectionIndex > 0 && (
                            <Tooltip title={t('news_culture.delete_query')}>
                              <DeleteOutlined
                                onClick={() => removeSection(section.sectionId)}
                                className="delete-btn"
                                disabled={pageLoading}
                              />
                            </Tooltip>
                          )}
                        </div>
                      )}
                      <Row className={Style.sectionSubParent}>
                        <div
                          style={{
                            fontWeight: 500,
                            width: '100%',
                            fontSize: '16px',
                          }}
                        >{`Section ${sectionIndex + 1}: `}</div>
                        <Row gutter={[8, 8]} className={Style.sectionHeader}>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t('news_and_culture.section_name')}
                              name={`sectionName_${sectionIndex}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please input query name!',
                                },
                              ]}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Input onBlur={handleSectionNameBlur} disabled={pageLoading || isViewOnly} />
                            </Form.Item>
                          </Col>
                          <Col xs={24} lg={12}>
                            <Form.Item
                              label={t('news_and_culture.query_date')}
                              name={`queryDate_${sectionIndex}`}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please select a date!',
                                },
                              ]}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <DateRangePickerWithPresets
                                format="YYYY-MM-DD"
                                className={Style.dateRangePicker}
                                presets={rangePresets}
                                onRangeChange={(dates) => onRangeChange(sectionIndex, dates)}
                                disabled={pageLoading || isViewOnly}
                                value={form.getFieldValue(`queryDate_${sectionIndex}`)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Row>
                      <div className={Style.queryContainerParent}>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>{t('news_and_culture.news_queries')}: </div>
                          <div className={Style.queryContainer}>
                            {renderQuery(
                              section.newsQueries,
                              section.sectionId,
                              'newsQueries',
                              'News',
                              addNewsQuery,
                              sectionIndex
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>{t('news_and_culture.facebook_queries')}: </div>
                          <div className={Style.queryContainer}>
                            {renderQuery(
                              section.facebookQueries,
                              section.sectionId,
                              'facebookQueries',
                              'Facebook',
                              addFacebookQuery,
                              sectionIndex
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>{t('news_and_culture.twitter_queries')}: </div>
                          <div className={Style.queryContainer}>
                            {renderQuery(
                              section.twitterQueries,
                              section.sectionId,
                              'twitterQueries',
                              'Twitter',
                              addTwitterQuery,
                              sectionIndex
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>{t('news_and_culture.tiktok_queries')}: </div>
                          <div className={Style.queryContainer}>
                            {renderQuery(
                              section.tiktokQueries,
                              section.sectionId,
                              'tiktokQueries',
                              'TikTok',
                              addTiktokQuery,
                              sectionIndex
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>{t('news_and_culture.threads_queries')}: </div>
                          <div className={Style.queryContainer}>
                            {renderQuery(
                              section.threadsQueries,
                              section.sectionId,
                              'threadsQueries',
                              'Threads',
                              addThreadsQuery,
                              sectionIndex
                            )}
                          </div>
                        </div>
                        <div className={Style.queryContainerSubParent}>
                          <div className={Style.queryHeaderSubParent}>{t('news_and_culture.instagram_queries')}: </div>
                          <div className={Style.queryContainer}>
                            {renderQuery(
                              section.instagramQueries,
                              section.sectionId,
                              'instagramQueries',
                              'Instagram',
                              addInstagramQuery,
                              sectionIndex
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
                {!isViewOnly && (
                  <SecondaryButton style={{ fontSize: '1rem' }} onClick={addSection} disabled={pageLoading}>
                    {t('news_and_culture.add_section')}
                    <PlusOutlined />
                  </SecondaryButton>
                )}
                <div className={Style.customTagsContainer}>
                  {!isViewOnly && <h3>{t('news_and_culture.custom_attributes')}</h3>}
                  <Form.List name="custom_tags">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map((field) => (
                          <Card key={field.key} className={Style.customTagCard}>
                            {!isViewOnly && (
                              <div className={Style.sectionActions}>
                                <Tooltip title={t('news_and_culture.remove_tag')}>
                                  <DeleteOutlined
                                    onClick={() => remove(field.name)}
                                    className="delete-btn"
                                    disabled={pageLoading}
                                  />
                                </Tooltip>
                              </div>
                            )}
                            <Row gutter={16}>
                              <Col span={12}>
                                <Form.Item
                                  {...field}
                                  label={t('news_and_culture.attribute_name')}
                                  name={[field.name, 'tag_name']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Attribute name is required',
                                    },
                                  ]}
                                >
                                  <Input disabled={isViewOnly} />
                                </Form.Item>
                              </Col>
                              <Col span={24}>
                                <Form.Item
                                  {...field}
                                  label={t('news_and_culture.attribute_description')}
                                  name={[field.name, 'tag_prompt']}
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Attribute description is required',
                                    },
                                  ]}
                                >
                                  <Input disabled={isViewOnly} />
                                </Form.Item>
                              </Col>
                            </Row>

                            <Form.List name={[field.name, 'tag_categories']}>
                              {(categories, { add: addCategory, remove: removeCategory }) => (
                                <>
                                  <TagCategoriesMapping categories={categories} removeCategory={removeCategory} />
                                  {!isViewOnly && (
                                    <SecondaryButton
                                      type="default"
                                      onClick={addCategory}
                                      icon={<PlusOutlined />}
                                      className={'small'}
                                      disabled={pageLoading}
                                    >
                                      {t('news_and_culture.add_category')}
                                    </SecondaryButton>
                                  )}
                                </>
                              )}
                            </Form.List>
                          </Card>
                        ))}
                        {!isViewOnly && (
                          <SecondaryButton
                            type="default"
                            onClick={() => add()}
                            icon={<PlusOutlined />}
                            className={'small'}
                            disabled={pageLoading}
                          >
                            {t('news_and_culture.add_tag')}
                          </SecondaryButton>
                        )}
                      </>
                    )}
                  </Form.List>
                </div>
                {!isViewOnly && (
                  <>
                    <div className={Style.scheduleContainer}>
                      <div className={Style.scheduleQs}>
                        <p>{t('news_and_culture.schedule_analysis')}?</p>
                        <Form.Item name={'radioBtn'}>
                          <Radio.Group onChange={onRadioChange}>
                            <Radio value={1}>{t('yes')}</Radio>
                            <Radio value={0}>{t('no')}</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      {schedule === 1 && (
                        <div className={Style.scheduleOptionsContainer}>
                          <Form.Item
                            label={t('news_and_culture.end_date')}
                            name="scheduleEndDate"
                            className={Style.scheduleFormRow}
                          >
                            <DatePicker disabledDate={disabledDate} style={{ width: '100%' }} />
                          </Form.Item>
                          <Form.Item
                            label={t('news_and_culture.no_of_hours_between_runs')}
                            name="scheduleInterval"
                            className={Style.scheduleFormRow}
                            // validate integer
                            rules={[
                              {
                                required: true,
                                message: 'Please select cadence',
                              },
                            ]}
                          >
                            <InputNumber placeholder={t('news_and_culture.enter_integer')} style={{ width: '100%' }} />
                            {/* <Select
                              maxTagCount={3}
                              options={scheduleIntervals}
                              placeholder="Select cadence"
                              style={{ width: "100%" }}
                            /> */}
                          </Form.Item>
                        </div>
                      )}
                    </div>
                  </>
                )}
                {!isViewOnly && (
                  <div className={Style.addSectionContainer}>
                    <Form.Item className={Style.addSectionButtons}>
                      <Row className={Style.addSectionButtonRow}>
                        <PrimaryButton
                          htmlType="button"
                          onClick={onSaveDraft}
                          disabled={!isFormComplete || pageLoading}
                          loading={saveDraftLoading}
                        >
                          {mode === 'Edit' ? t('news_and_culture.update_draft') : t('news_and_culture.save_draft')}
                        </PrimaryButton>
                        <PrimaryButton
                          htmlType="submit"
                          onClick={() => form.submit()}
                          disabled={!isFormComplete || pageLoading}
                          loading={buttonLoading}
                        >
                          {t('news_and_culture.run_analysis')}
                        </PrimaryButton>
                      </Row>
                    </Form.Item>
                  </div>
                )}
              </>
            )}
          </Form>
        </Card>
      </div>
      {drawerVisible && (
        <CreateAnalysisQuery
          drawerVisible={drawerVisible}
          setDrawerVisible={setDrawerVisible}
          queryData={queryData}
          addUpdateQuery={addUpdateQuery}
          addUpdateQueryInstagram={addUpdateQueryInstagram}
          instaFormData={instaFormData}
          setInstaFormData={setInstaFormData}
          UpdatedAnalysisCategories={UpdatedAnalysisCategories}
        />
      )}
    </div>
  );
};

export default CreateListingScreen;
