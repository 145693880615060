/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
import React from "react";
import { Router } from "react-router-dom";
import AppRouter from "src/rootRouter";
import { createBrowserHistory } from "history";
import AppContext from "src/context/appContext";
import { initHttp } from "src/http";

import "./styles/index.less";
import LoadingSpinner from "./components/loadingSpinner";

import * as Sentry from "@sentry/react";
import { captureConsoleIntegration } from "@sentry/integrations";
import { PageLayoutWrapper } from "./context/pageLayoutContext";
import { ConfigProvider } from "antd";
import theme from "./styles/theme";

const isProduction = process.env.NODE_ENV === "production";
if (isProduction) {
  Sentry.init({
    dsn: "https://90c314d127c7f705a91650f68648c6c1@o4504208781606912.ingest.us.sentry.io/4507020977700864",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        // http://localhost:3000/
        tracePropagationTargets: [/^https:\/\/ai\.gale\.technology\//],
      }),
      captureConsoleIntegration({
        // array of methods that should be captured
        levels: ["log"],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const history = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // isLoading: true,
      hasError: false,
    };
  }
  componentDidMount() {
    initHttp();
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="full-page-loading-container">
          <div className="ant-image">
            <img alt="logo" style={{ width: "250px" }} src="/images/logo.svg" />
            <div
              className="ant-spin-placeholder"
              style={{ height: "47px", width: "100%" }}
            />
          </div>
        </div>
      );
    }
    return (
      <React.Suspense fallback={<LoadingSpinner />}>
        <AppContext>
          <PageLayoutWrapper>
            <ConfigProvider theme={theme}>
              <Router history={history}>
                <AppRouter history={history} />
              </Router>
            </ConfigProvider>
          </PageLayoutWrapper>
        </AppContext>
      </React.Suspense>
    );
  }
}

export default App;
