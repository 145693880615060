import { css } from '@linaria/core';

export const welcomeContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 48px 24px;
`;

export const welcomeHeader = css`
  text-align: center;
  margin-bottom: 48px;
`;

export const welcomeTitle = css`
  font-size: 32px;
  font-weight: 600;
  color: var(--color-white);
  margin-bottom: 16px;
`;

export const welcomeDescription = css`
  font-size: 16px;
  color: var(--color-60-gray);
  max-width: 600px;
  margin: 0 auto;
`;

export const featuresGrid = css`
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 48px;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const featureCard = css`
  background-color: var(--color-90-black);
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--color-80-black);
  transition: border-color 0.3s ease;
  flex: 1;
`;

export const featureIcon = css`
  font-size: 24px;
  color: var(--color-link);
  margin-bottom: 16px;
`;

export const featureTitle = css`
  font-size: 18px;
  font-weight: 500;
  color: var(--color-white);
  margin-bottom: 8px;
`;

export const featureDescription = css`
  font-size: 14px;
  color: var(--color-60-gray);
  line-height: 1.5;
`;

export const createButton = css`
  height: 40px;
  padding: 0 24px;
  font-size: 16px;
`;

export const ArrowContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    display: contents;
  }
`;

export const Arrow = css`
  display: block;
  margin: 30px auto;
  width: 17px;
  height: 17px;
  border-top: 2px solid var(--color-40-black);
  border-left: 2px solid var(--color-40-black);
  transform: rotate(135deg);

  &::before {
    content: "";
    display: block;
    width: 2px;
    height: 32px;
    background-color: var(--color-40-black);
    transform: rotate(-45deg) translate(10px, 4px);
    left: 0;
    top: 0;
  }

  @media (max-width: 768px) {
    margin: 30px auto 20px
    transform: rotate(225deg);
  }
`;
