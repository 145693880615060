import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { BEHAVIOURAL } from '../../../constants/audienceDetailsConstants';
import audienceListService from '../../../services/audienceListService';

const useAttitudinalOverviewHook = ({ audienceId, activeTab }) => {
  const [attitudinalOverviewLoading, setAttitudinalOverviewLoading] = useState(true);
  const [attitudinalOverviewData, setAttitudinalOverviewData] = useState(null);

  const fetchAttitudinalOverviewData = async () => {
    try {
      const audienceData = await audienceListService.getAudienceFeatureProfiles(
        audienceId,
        '',
        '',
        1,
        BEHAVIOURAL,
        true
      );
      setAttitudinalOverviewData(audienceData.data);
      setAttitudinalOverviewLoading(false);
    } catch (error) {
      setAttitudinalOverviewLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getReplaceFeaturePayload = (newFeature, parentFeatureId) => {
    return {
      audience_profile_id: audienceId,
      parent_feature_id: parentFeatureId,
      new_parent_feature_name: newFeature.leaf_node,
      new_parent_feature_directory_filters: newFeature.directory_path,
      profile_type: BEHAVIOURAL,
    };
  };

  const getSwapFeaturePayload = (parentFeatureId, selectedFeatureIds) => {
    return {
      audience_profile_id: audienceId,
      feature_profile_ids: selectedFeatureIds,
      parent_feature_id: parentFeatureId,
      profile_type: BEHAVIOURAL,
    };
  };

  const replaceBehaviouralFeature = async (newFeature, parentfeatureId) => {
    try {
      setAttitudinalOverviewLoading(true);
      const payload = getReplaceFeaturePayload(newFeature, parentfeatureId);
      await audienceListService.replaceAudienceBehaviouralFeature(payload);
      await fetchAttitudinalOverviewData();
    } catch (error) {
      setAttitudinalOverviewLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const swapBeahaviouralFeatures = async (parentFeatureId, selectedFeatureIds) => {
    try {
      setAttitudinalOverviewLoading(true);
      const payload = getSwapFeaturePayload(parentFeatureId, selectedFeatureIds);
      await audienceListService.swapFeatures(payload);
      await fetchAttitudinalOverviewData();
      notification.success({
        message: 'Features swapped successfully',
        duration: 5,
        placement: 'bottomRight',
      });
    } catch (error) {
      setAttitudinalOverviewLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    if (activeTab === '3' && attitudinalOverviewData !== null) {
      fetchAttitudinalOverviewData();
    } else if (attitudinalOverviewData === null) {
      setAttitudinalOverviewLoading(true);
      fetchAttitudinalOverviewData();
    }
  }, [audienceId, activeTab]);

  return {
    attitudinalOverviewLoading,
    attitudinalOverviewData,
    replaceBehaviouralFeature,
    swapBeahaviouralFeatures,
  };
};
export default useAttitudinalOverviewHook;
