import { LineChartOutlined } from '@ant-design/icons';
import { Col, Collapse, Form, Row, Slider, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import LoadingSpinner from 'src/components/loadingSpinner';
import appUrls from 'src/constants/appUrls';
import { numberWithCommas } from 'src/lib/utils';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import './MediaplanScenarioPlan.less';
import useMediaPlanScenarioPlan from './useMediaPlanScenarioPlan';

export const MediaplanScenarioPlan = () => {
  const { loading, scenario, formRef, getColumns, history, clientId, responseId } = useMediaPlanScenarioPlan();
  const formatter = (value) => `$${numberWithCommas(value)}`;
  const { t } = useTranslation();
  if (loading) {
    return <LoadingSpinner centered />;
  }

  const ScenarioAudienceCollapse = ({ idx, name, getFieldsValue }) => {
    const audiences = getFieldsValue('scenario');
    const audienceName = audiences?.scenario?.[idx]?.audience_name;
    const max = audiences?.scenario?.[idx]?.budget;
    const min = 0;
    let formattedColumns = [];
    if (audiences?.scenario?.[idx]?.channels?.length > 0) {
      const columns = Object.keys(audiences?.scenario?.[idx]?.channels[0]);
      let filteredColumns = columns.filter((column) => column !== 'channel_name');
      formattedColumns = getColumns(filteredColumns);
    }
    return (
      <>
        <Collapse className={'regularCollapse'} style={{ flexGrow: '1' }} defaultActiveKey={['0']}>
          <Collapse.Panel forceRender header={<b>{audienceName}</b>} key={idx}>
            <h4>
              {t('mediaplan.audience_budget')}: <b>${numberWithCommas(max)}</b>
            </h4>
            <Form.List name={[name, 'channels']}>
              {(channels) => (
                <>
                  {channels.map((channel, index) => {
                    const channelTableData = audiences?.scenario?.[idx]?.channels?.[index];
                    return (
                      <>
                        <Row key={channel.key} gutter={[40, 20]} className="channel-row">
                          <Col xl={6} sm={24} md={24}>
                            <div>
                              <span>
                                <b>{channelTableData?.channel_name}</b>
                              </span>
                            </div>
                            <Form.Item
                              name={[channel.name, 'budget']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Please set a budget!',
                                },
                              ]}
                            >
                              <Slider
                                min={min}
                                disabled
                                max={scenario?.mediaplan_budget}
                                tooltip={{
                                  formatter,
                                }}
                                marks={{
                                  [min]: `$${numberWithCommas(min)}`,
                                  [scenario?.mediaplan_budget]: `$${numberWithCommas(scenario?.mediaplan_budget)}`,
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xl={18} sm={24} md={24}>
                            <Table
                              className="dark-table"
                              columns={formattedColumns || []}
                              dataSource={[channelTableData]}
                              pagination={false}
                              rowKey="id"
                              scroll={{
                                x: 'max-content',
                              }}
                            />
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </>
              )}
            </Form.List>
          </Collapse.Panel>
        </Collapse>
      </>
    );
  };
  return (
    <div>
      <NcBreadCrumb />
      <h2>{t('mediaplan.scenario_planning')}</h2>
      <Row justify="end" gutter={[20, 0]} style={{ marginBottom: '1rem' }}>
        <Col>
          <SecondaryButton
            comfy
            className="small"
            onClick={() => history.push(appUrls.MEDIA_PLAN_AUDIENCE_INFO(clientId, scenario?.mediaplan_id))}
          >
            {t('back')}
          </SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            className="small"
            comfy
            onClick={() => history.push(appUrls.MEDIA_PLAN_SCENARIO_OUTPUT_SCREEN(clientId, responseId))}
          >
            {t('mediaplan.dashboard')} &nbsp;
            <LineChartOutlined />
          </PrimaryButton>
        </Col>
      </Row>
      <Row className="header-container planning">
        <Col span={6}>
          <b>{t('mediaplan.media_plan_name')}:</b> {scenario?.mediaplan_name}
        </Col>
        <Col span={6}>
          <b>{t('mediaplan.budget')}:</b> ${numberWithCommas(scenario?.mediaplan_budget)}
        </Col>
        <Col span={6}>
          <b>{t('mediaplan.start_date')}:</b> {moment(scenario?.mediaplan_start_date).format('MM/DD/YYYY')}
        </Col>
        <Col span={6}>
          <b>{t('mediaplan.end_date')}:</b> {moment(scenario?.mediaplan_end_date).format('MM/DD/YYYY')}
        </Col>
      </Row>
      <h4>{t('mediaplan.audiences')}:</h4>
      <div style={{ marginLeft: '1rem' }}>
        <Form initialValues={scenario} requiredMark={false} ref={formRef}>
          <Form.List name="scenario">
            {(fields) => {
              return (
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldsValue }) => {
                    return (
                      <>
                        {fields.map(({ name }, idx) => {
                          return (
                            <ScenarioAudienceCollapse key={idx} idx={idx} name={name} getFieldsValue={getFieldsValue} />
                          );
                        })}
                      </>
                    );
                  }}
                </Form.Item>
              );
            }}
          </Form.List>
        </Form>
      </div>
    </div>
  );
};
