import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Button, Tooltip, notification } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import NcApiServices from '../services/NcApiServices';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { generatePayloadForNodeDetails } from '../utils';
import { isEqual, debounce } from 'lodash';
import Style from '../NewsAndCulture.style';
import { analysisFilterObject } from '../constants';
import GlobalTag from 'src/components/globalTag/GlobalTag';
import { TAG_COLOR_NAME } from 'src/components/globalTag/globalTagConstant';
import moment from 'moment';
import { CustomCheckboxFilterDropdown } from '../components/CustomCheckboxFilterDropdown.jsx';

export const useArticleTable = ({
  selectedThemes,
  selectedTopics,
  selectedSentiment,
  selectedHandles,
  selectedWord,
  selectedLink,
  analysisId,
  visible,
  columnName,
  drawerContent,
  postTypes,
  secondary_analysis_ids,
  selectedPostID,
  start_date,
  end_date,
}) => {
  const [tableUIFilters, setTableUIFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState({
    headline: [],
    excerpt: [],
    interaction_score: [],
    ai_themes: [],
    ai_topics: [],
    ai_entities: [],
    ai_keywords: [],
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState('');
  const [detailDrawerVisible, setDetailDrawerVisible] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [analysisConfigRawData, setAnalysisConfigRawData] = useState([]);
  const [drawerLoader, setDrawerLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [apiFilters, setApiFilters] = useState(null);

  const prevApiFilters = useRef(apiFilters);
  const prevPagination = useRef(pagination.current);
  const prevSearchTerm = useRef(null);

  useEffect(() => {
    if (visible) {
      setApiFilters({
        themes: selectedThemes,
        topics: selectedTopics,
        sentiment: selectedSentiment,
        nodes: selectedWord ? [selectedWord] : [],
        links: selectedLink ? [selectedLink] : [],
        post_id: selectedPostID,
        handles: selectedHandles,
        start_date: start_date,
        end_date: end_date,
        post_types: postTypes,
        secondary_analysis_ids: secondary_analysis_ids,
      });
      setPagination({ current: 1, pageSize: 5 });
    }
  }, [
    visible,
    selectedWord,
    selectedLink,
    selectedHandles,
    selectedPostID,
    selectedThemes,
    selectedTopics,
    selectedSentiment,
    start_date,
    end_date,
    postTypes,
    secondary_analysis_ids,
  ]);

  useEffect(() => {
    if (!visible) {
      setTableUIFilters({});
      setSearchTerm('');
    }
  }, [visible]);

  useEffect(() => {
    if (analysisConfigRawData.length > 0) {
      setFilterOptions({
        headline: generateFilters('headline', analysisConfigRawData),
        excerpt: generateFilters('excerpt', analysisConfigRawData),
        interaction_score: generateFilters('interaction_score', analysisConfigRawData, true),
        ai_themes: generateFilters('ai_themes', analysisConfigRawData, true),
        ai_topics: generateFilters('ai_topics', analysisConfigRawData, true),
        ai_entities: generateFilters('ai_entities', analysisConfigRawData, true),
        ai_keywords: generateFilters('ai_keywords', analysisConfigRawData, true),
        analysis_platform: analysisFilterObject,
      });
    }
  }, [analysisConfigRawData]);

  useEffect(() => {
    const fetchData = () => {
      const payload = generatePayloadForNodeDetails({
        analysis_id: analysisId,
        handles: apiFilters.handles ? apiFilters.handles : [],
        nodes: apiFilters.nodes ? apiFilters.nodes : [],
        links: apiFilters.links ? apiFilters.links : [],
        post_id: apiFilters.post_id,
        themes: tableUIFilters?.ai_themes || apiFilters?.themes || [],
        topics: tableUIFilters?.ai_topics || apiFilters?.topics || [],
        sentiment: apiFilters?.sentiment || null,
        entities: tableUIFilters?.ai_entities || [],
        keywords: tableUIFilters?.ai_keywords || [],
        search_term: debouncedSearchTerm || null,
        analysis_type: tableUIFilters?.analysis_platform || null,
        post_types: apiFilters.post_types,
        start_date: apiFilters.start_date,
        end_date: apiFilters.end_date,
        secondary_analysis_ids: apiFilters.secondary_analysis_ids,
      });
      if (drawerContent && columnName) {
        const top10Keys = Object.keys(drawerContent).slice(0, 10);
        payload[columnName] = top10Keys;
      }

      getAnalysisConfigRawData(payload, pagination.current);
    };
    if (
      visible &&
      apiFilters &&
      (!isEqual(apiFilters, prevApiFilters.current) ||
        prevPagination.current !== pagination.current ||
        columnName ||
        debouncedSearchTerm !== prevSearchTerm.current)
    ) {
      fetchData();
      prevApiFilters.current = apiFilters;
      prevPagination.current = pagination.current;
      prevSearchTerm.current = debouncedSearchTerm;
    }
  }, [
    visible,
    pagination.current,
    debouncedSearchTerm,
    tableUIFilters,
    analysisId,
    columnName,
    drawerContent,
    apiFilters,
  ]);

  useEffect(() => {
    return () => {
      // Cleanup function to clear states
      setAnalysisConfigRawData([]);
      setTotalPages(0);
      setPagination({ current: 1, pageSize: 5 });
      setFilterOptions({
        headline: [],
        excerpt: [],
        interaction_score: [],
        ai_themes: [],
        ai_topics: [],
        ai_entities: [],
        ai_keywords: [],
      });
      setTableUIFilters({});
      setSearchTerm('');
      setDebouncedSearchTerm('');
    };
  }, []);

  const getAnalysisConfigRawData = async (payload, page) => {
    try {
      setDrawerLoader(true);
      const res = await NcApiServices.getAnalysisConfigRawData(payload, page);
      if (res?.success) {
        setAnalysisConfigRawData(res?.data?.data);
        setTotalPages(res?.data?.total);
      }
      setDrawerLoader(false);
    } catch (error) {
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
      setDrawerLoader(false);
    }
  };
  const handleTableChange = (pagination, filters) => {
    setTableUIFilters(filters);
    setPagination(pagination);
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setDebouncedSearchTerm(value);
      setPagination({ current: 1, pageSize: 5 });
    }, 800),
    []
  );

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm, handleSearch]);

  const generateFilters = (key, data, isArray = false) => {
    const uniqueValues = isArray
      ? [...new Set(data.flatMap((item) => item?.processed_data?.[key]))]
      : [...new Set(data.map((item) => item?.processed_data?.[key]))];

    return uniqueValues
      .filter((value) => value !== undefined)
      .map((value) => ({
        text: truncateText(value, 100),
        value,
      }));
  };

  const truncateText = (text, length) => {
    if (typeof text !== 'string') return '';
    return text.length > length ? `${text.slice(0, length)}...` : text;
  };

  const columns = useMemo(
    () => [
      {
        title: 'Published Date',
        dataIndex: ['processed_data', 'publish_date'],
        key: 'publish_date',
        render: (text) => (text ? moment(text).format('LLL') : <span>---</span>),
        width: 300,
      },
      {
        title: 'Headline/Excerpt',
        dataIndex: ['processed_data'],
        key: 'headline',
        render: (processed_data) => {
          const { headline, excerpt } = processed_data ?? {};
          return headline ? (
            <Tooltip className={Style.tooltipStyle} title={headline}>{`${headline.slice(0, 100)}...`}</Tooltip>
          ) : excerpt ? (
            <Tooltip className={Style.tooltipStyle} title={excerpt}>{`${excerpt.slice(0, 100)}...`}</Tooltip>
          ) : (
            <span>---</span>
          );
        },
        width: 300,
      },
      {
        title: 'Platform Details',
        dataIndex: 'analysis_platform',
        key: 'analysis_platform',
        filters: filterOptions?.analysis_platform,
        filteredValue: tableUIFilters?.analysis_platform ?? null,
        onFilter: (value, record) =>
          record?.analysis_platform === null
            ? record?.analysis_type?.includes(value)
            : record?.analysis_platform?.includes(value),
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => (
          <CustomCheckboxFilterDropdown {...props} filters={filterOptions.analysis_platform} />
        ),
        render: (_, record) => {
          const { link, publisher } = record?.processed_data ?? {};
          const { analysis_platform } = record ?? {};
          return (
            <div>
              <p>
                <b>Platform Name: </b> {analysis_platform !== null ? analysis_platform : 'news'}
              </p>
              <p>
                <b>Publisher: </b>
                {publisher ? publisher : '---'}
              </p>
              <p>
                <b>View Article:</b>
                <Button
                  type="link"
                  icon={<LinkOutlined />}
                  href={link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ border: 'none' }}
                />
              </p>
            </div>
          );
        },
        width: 300,
        zIndex: '10005 !important',
      },
      {
        title: 'Themes',
        dataIndex: ['processed_data', 'ai_themes'],
        key: 'ai_themes',
        filters: filterOptions?.ai_themes,
        filteredValue: tableUIFilters?.ai_themes ?? null,
        onFilter: (value, record) => record?.processed_data?.ai_themes?.includes(value),
        filterSearch: true,
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => <CustomCheckboxFilterDropdown {...props} filters={filterOptions.ai_themes} />,
        render: (tags) => (
          <div className={Style.tagsContainer}>
            {tags.map((tag, index) => (
              <GlobalTag color={TAG_COLOR_NAME.BLUE} key={index}>
                {tag}
              </GlobalTag>
            ))}
          </div>
        ),
        width: 200,
      },
      {
        title: 'Topics',
        dataIndex: ['processed_data', 'ai_topics'],
        key: 'ai_topics',
        filters: filterOptions?.ai_topics,
        filteredValue: tableUIFilters?.ai_topics ?? null,
        onFilter: (value, record) => record?.processed_data?.ai_topics?.includes(value),
        filterSearch: true,
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => <CustomCheckboxFilterDropdown {...props} filters={filterOptions.ai_topics} />,
        render: (tags) => (
          <div className={Style.tagsContainer}>
            {tags.map((tag, index) => (
              <GlobalTag color={TAG_COLOR_NAME.GREEN} key={index}>
                {tag}
              </GlobalTag>
            ))}
          </div>
        ),
        width: 200,
      },
      {
        title: 'Entities',
        dataIndex: ['processed_data', 'ai_entities'],
        key: 'ai_entities',
        filters: filterOptions?.ai_entities,
        filteredValue: tableUIFilters?.ai_entities ?? null,
        onFilter: (value, record) => record?.processed_data?.ai_entities?.includes(value),
        filterSearch: true,
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => <CustomCheckboxFilterDropdown {...props} filters={filterOptions.ai_entities} />,
        render: (tags) => (
          <div className={Style.tagsContainer}>
            {tags.map((tag, index) => (
              <GlobalTag color={TAG_COLOR_NAME.ORANGE} key={index}>
                {tag}
              </GlobalTag>
            ))}
          </div>
        ),
        width: 200,
      },
      {
        title: 'Keywords',
        dataIndex: ['processed_data', 'ai_keywords'],
        key: 'ai_keywords',
        filters: filterOptions?.ai_keywords,
        filteredValue: tableUIFilters?.ai_keywords ?? null,
        onFilter: (value, record) => record?.processed_data?.ai_keywords?.includes(value),
        filterSearch: true,
        filterDropdownStyle: { width: 300 },
        filterDropdown: (props) => <CustomCheckboxFilterDropdown {...props} filters={filterOptions.ai_keywords} />,
        render: (tags) => (
          <div className={Style.tagsContainer}>
            {tags.map((tag, index) => (
              <GlobalTag color={TAG_COLOR_NAME.PURPLE} key={index}>
                {tag}
              </GlobalTag>
            ))}
          </div>
        ),
        width: 200,
      },
      {
        title: 'Social Interaction',
        key: 'social_interaction',
        render: (_, record) => {
          const { likes, shares, views, comments } = record?.processed_data ?? {};
          const { interaction_score } = record ?? {};
          return (
            <div>
              <p>
                <b>Likes:</b> {likes !== null ? likes : '---'}
              </p>
              <p>
                <b>Comments:</b> {comments !== null ? comments : '---'}
              </p>
              <p>
                <b>Shares:</b> {shares !== null ? shares : '---'}
              </p>
              <p>
                <b>Views:</b> {views !== null ? views : '---'}
              </p>
              <p>
                <b>Score:</b> {interaction_score !== null ? interaction_score : '---'}
              </p>
            </div>
          );
        },
        width: 300,
      },
      {
        title: '',
        key: 'view',
        render: (_, record) => (
          <div className={Style.buttonLayout}>
            <Button
              type="link"
              style={{ border: 'none' }}
              onClick={() => {
                setSelectedArticle(record);
                setDetailDrawerVisible(true);
              }}
            >
              View Detail
            </Button>
          </div>
        ),
      },
    ],
    [tableUIFilters, filterOptions]
  );

  return {
    columns,
    analysisConfigRawData,
    globalSearch: searchTerm,
    setGlobalSearch: setSearchTerm,
    handleTableChange,
    detailDrawerVisible,
    setDetailDrawerVisible,
    selectedArticle,
    setSelectedArticle,
    drawerLoader,
    pagination,
    totalPages,
  };
};
