import React from 'react';
import { FormOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';

// Function to format breadcrumb items
export const formatBreadcrumb = (breadcrumb, name, onClick, screen) => {
  let overviewLink = null;
  if (breadcrumb === 'analysis-overview') {
    overviewLink = (
      <div>
        <span>{name}</span>
        <Tooltip title="View Query" placement="bottom">
          {name && (
            <FormOutlined
              style={{ marginLeft: '8px', cursor: 'pointer', position: 'relative', top: '2px' }}
              onClick={onClick}
            />
          )}
        </Tooltip>
      </div>
    );
  }
  const breadcrumbMapping = {
    'news-culture': 'Sensing Culture',
    'analysis-overview': overviewLink ? <span>{overviewLink}</span> : 'Analysis Overview',
    'create-listing': screen === 1 && !name ? 'Create Listing' : name ? `${name}` : <Spin size="small"></Spin>,
    'update-listing': name ? `${name}` : <Spin size="small"></Spin>,
  };

  return (
    breadcrumbMapping[breadcrumb] ||
    breadcrumb
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  );
};

export const formatStatus = (status) => {
  const statusMapping = {
    IN_PROGRESS: 'In Progress',
    null: 'Draft',
    ERROR: 'Failed',
    DONE: 'Done',
    IN_QUEUE: 'In Queue',
  };
  return statusMapping[status] || 'In Progress';
};

export const generatePayloadForNodeDetails = ({
  analysis_id,
  analysis_section_config_id = null,
  topics = [],
  themes = [],
  entities = [],
  handles = [],
  nodes = [],
  links = [],
  sentiment = null,
  keywords = [],
  search_term = null,
  analysis_type = null,
  post_types = null,
  secondary_analysis_ids = null,
  post_id = null,
  start_date = null,
  end_date = null,
}) => {
  return {
    analysis_id,
    analysis_section_config_id,
    topics,
    themes,
    entities,
    handles,
    nodes,
    links,
    sentiment,
    keywords,
    search_term,
    analysis_type,
    post_types,
    secondary_analysis_ids,
    post_id,
    start_date,
    end_date,
  };
};

export const toSentenceCase = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
