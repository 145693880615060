import { css } from "@linaria/core";

const summarySection = css`
  margin-bottom: 20px;
`;


export default {
  summarySection,
};
