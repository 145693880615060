import { Col, Modal, notification, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import JsonView from 'react18-json-view';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';

const AppConfigModal = ({ appConfig, updateApps, configureLoading }) => {
  const { t } = useTranslation();
  const [jsonData, setJsonData] = useState(appConfig?.data?.jsonData || {});
  const [addJsonModal, setAddJsonModal] = useState(false);
  const [json, setJson] = useState('');

  return (
    <div style={{ height: '450px', overflowY: 'auto' }}>
      <Row justify="end" gutter={[20, 0]}>
        <Col>
          <SecondaryButton
            className="small"
            onClick={() => {
              setJson('');
              setAddJsonModal(true);
            }}
          >
            {t('client_space.import_json')}
          </SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton
            loading={configureLoading}
            className="small"
            comfy
            onClick={() => updateApps(appConfig?.data?.appType, 'config', jsonData)}
          >
            {t('save')}
          </PrimaryButton>
        </Col>
      </Row>
      <JsonView src={jsonData} dark={true} editable={false} style={{ padding: '20px' }} enableClipboard={true} />
      <Modal
        title={t('client_space.import_json')}
        maskClosable={false}
        destroyOnClose
        open={addJsonModal}
        onCancel={() => setAddJsonModal(false)}
        footer={null}
        width="40%"
      >
        <TextArea
          autoSize={{ minRows: 6, maxRows: 9 }}
          placeholder={t('client_space.paste_your_json_here')}
          value={json}
          onChange={(val) => {
            setJson(val.target.value);
          }}
        />

        <Row justify="end" style={{ marginTop: '1rem' }}>
          <Col>
            <PrimaryButton
              onClick={() => {
                try {
                  const parsedJson = JSON.parse(json);
                  setJsonData(parsedJson);
                  setAddJsonModal(false);
                } catch (error) {
                  notification.error({
                    duration: 5,
                    placement: 'bottomRight',
                    message: t('invalid_json'),
                  });
                }
              }}
            >
              {t('import')}
            </PrimaryButton>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default AppConfigModal;
