import React from 'react';
import './audienceOverview.style.less';
import TraitsView from './TraitsView';
import useAudienceOverviewHook from './useAudienceOverviewHook';

const AudienceOverview = ({ audienceFeatures, handleSearch, searchFeatureLoading, audienceProfileId }) => {
  const {
    overViewIndexingTraitData,
    setSelectedFeature,
    currentPage,
    setCurrentPage,
    setFeatureSortBy,
    featureSortBy,
    featureListTotalCount,
    isFeatureLoading,
    overViewFeatureType,
    setOverViewFeatureType,
    getFeatureData,
    selectedFeature,
  } = useAudienceOverviewHook(audienceProfileId);

  return (
    <TraitsView
      data={overViewIndexingTraitData}
      getFeatureData={getFeatureData}
      audienceFeatures={audienceFeatures}
      setSelectedFeature={setSelectedFeature}
      selectedFeature={selectedFeature}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      setFeatureSortBy={setFeatureSortBy}
      featureSortBy={featureSortBy}
      featureListTotalCount={featureListTotalCount}
      isFeatureLoading={isFeatureLoading}
      overViewFeatureType={overViewFeatureType}
      setOverViewFeatureType={setOverViewFeatureType}
      handleSearch={handleSearch}
      searchFeatureLoading={searchFeatureLoading}
    />
  );
};

export default AudienceOverview;
