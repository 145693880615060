import { BarChartOutlined, TableOutlined } from '@ant-design/icons';
import { Button, Select, Table, Tooltip, Typography } from 'antd';
import ReactECharts from 'echarts-for-react';
import React, { useState } from 'react';
import { formatNumberWithCeil } from 'src/lib/utils';
import {
  AUDIENCE_INDEX_COLUMNS,
  BEHAVIOURAL,
  BRAND_AFFINITY,
  DEMOGRAPHIC,
} from '../../../constants/audienceDetailsConstants';
import './audienceOverview.style.less';

const { Title } = Typography;

const TraitsView = ({
  data,
  getFeatureData,
  audienceFeatures,
  setSelectedFeature,
  selectedFeature,
  currentPage,
  setCurrentPage,
  setFeatureSortBy,
  featureSortBy,
  featureListTotalCount,
  isFeatureLoading,
  overViewFeatureType,
  setOverViewFeatureType,
  handleSearch,
  searchFeatureLoading,
}) => {
  const [view, setView] = useState('table');

  const handleFeatureChange = (value) => {
    setSelectedFeature(value);
    setOverViewFeatureType('');
    getFeatureData(featureSortBy, value, '', false);
  };

  const handleFeatureTypeChange = (value) => {
    setOverViewFeatureType(value);
    setSelectedFeature('');
    getFeatureData(featureSortBy, '', value, false);
  };

  const toggleView = () => {
    setView(view === 'table' ? 'chart' : 'table');
  };

  const handleSortChange = (pagination, filters, sorter) => {
    if (Object.keys(sorter).length > 0) {
      const order = sorter.order === 'ascend' ? sorter.field : `-${sorter.field}`;
      setFeatureSortBy(order);
    }
  };

  const getChartOptions = () => {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      legend: {
        textStyle: {
          color: '#ffffff',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: {
        type: 'value',
        axisLabel: {
          textStyle: {
            color: '#ffffff',
          },
        },
      },
      yAxis: {
        type: 'category',
        data: data.map((item) => item.feature_readable_name + ' - ' + item.feature_readable_value),
        axisLabel: {
          textStyle: {
            color: '#ffffff',
          },
        },
      },
      series: [
        {
          name: 'Audience Percentage',
          type: 'bar',
          data: data.map((item) => formatNumberWithCeil(item.audience_feature_percentage)),
        },
      ],
    };
  };

  return (
    <div className="traits-table">
      <div className="table-header">
        <Title level={4}>Top Audience Features</Title>
        <Button
          icon={
            view === 'table' ? (
              <BarChartOutlined style={{ fontSize: '20px' }} />
            ) : (
              <TableOutlined style={{ fontSize: '20px' }} />
            )
          }
          onClick={toggleView}
          className="toggle-view-button"
        />
      </div>
      <div className="controls-row">
        <div className="feature-select-section">
          <label>Select Feature</label>
          <Select
            style={{ width: 300 }}
            placeholder="Select a feature"
            onChange={handleFeatureChange}
            value={selectedFeature}
            showSearch
            onSearch={handleSearch}
            loading={searchFeatureLoading}
            filterOption={(input, option) => {
              const optionText = option.key || option.value;
              return optionText.toLowerCase().includes(input.toLowerCase());
            }}
          >
            <Select.Option value={''}>All</Select.Option>
            <Select.OptGroup label={<span style={{ color: '#C4C4C4', fontSize: '16px' }}>Demographic Features</span>}>
              {audienceFeatures.demographic_features.map((field) => (
                <Select.Option key={field.feature_readable_name} value={field.feature_name}>
                  <Tooltip title={field.feature_readable_name}>
                    <span>{field.feature_readable_name}</span>
                  </Tooltip>
                </Select.Option>
              ))}
            </Select.OptGroup>
            {audienceFeatures.first_party_features.length > 0 && (
              <Select.OptGroup label={<span style={{ color: '#C4C4C4', fontSize: '16px' }}>First Party Features</span>}>
                {audienceFeatures.first_party_features.map((field) => (
                  <Select.Option key={field.feature_readable_name} value={field.feature_name}>
                    <Tooltip title={field.feature_readable_name}>
                      <span>{field.feature_readable_name}</span>
                    </Tooltip>
                  </Select.Option>
                ))}
              </Select.OptGroup>
            )}
            <Select.OptGroup label={<span style={{ color: '#C4C4C4', fontSize: '16px' }}>Behaviour Features</span>}>
              {audienceFeatures.behaviour_features.map((field) => (
                <Select.Option key={field.feature_readable_name} value={field.feature_name}>
                  <Tooltip title={field.feature_readable_name}>
                    <span>{field.feature_readable_name}</span>
                  </Tooltip>
                </Select.Option>
              ))}
            </Select.OptGroup>
          </Select>
        </div>
        <div className="feature-select-section">
          <label>Select Feature Type</label>
          <Select
            style={{ width: 300 }}
            placeholder="Select a feature Type"
            onChange={handleFeatureTypeChange}
            value={overViewFeatureType}
          >
            <Select.Option value={DEMOGRAPHIC}>{DEMOGRAPHIC}</Select.Option>
            <Select.Option value={BEHAVIOURAL}>{BEHAVIOURAL}</Select.Option>
            <Select.Option value={BRAND_AFFINITY}>BRAND AFFINITY</Select.Option>
            <Select.Option value={''}>All</Select.Option>
          </Select>
        </div>
      </div>
      {view === 'table' ? (
        <Table
          dataSource={data}
          columns={AUDIENCE_INDEX_COLUMNS}
          pagination={{
            total: featureListTotalCount,
            showSizeChanger: false,
            current: currentPage,
            position: ['bottomCenter'],
            onChange: (page) => setCurrentPage(page),
          }}
          className="dark-table"
          loading={isFeatureLoading}
          onChange={handleSortChange}
        />
      ) : (
        <ReactECharts
          option={getChartOptions()}
          style={{ height: 600, width: '100%' }}
          showLoading={isFeatureLoading}
        />
      )}
    </div>
  );
};

export default TraitsView;
