import { css } from 'linaria';

const rowStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: nowrap;
  margin-top: 16px;
`;

const colStyle = css`
  &.ant-col {
    display: flex;
    flex-direction: column;
    height: 80vh;
    max-height: 80vh;
    overflow-y: auto;
    border-right: solid 1px white;
  }
`;

const labelStyle = css`
  display: flex;
  color: white;
  fontsize: 1.3rem;
`;

const selectStyle = css`
  width: 200px;
`;
const radioStyle = css`
  .ant-radio-group {
    display: flex;
    flex-direction: row;
  }
  .ant-radio-wrapper {
    color: white;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #555555;
  }
  .ant-radio-inner {
    border-color: white;
  }
  .ant-radio-inner::after {
    background-color: #555555;
  }
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #555555;
  }
`;

const inputStyle = css`
  max-width: 240px;
`;

const datePickerStyle = css`
  max-width: 240px;
`;

const horizontalDivider = css`
  &.ant-divider-horizontal {
    border-top: solid 0.5px white;
    opacity: 50%;
    width: 92%;
    min-width: 92%;
    margin: 8px 0 24px;
  }
`;

const paginationParent = css`
  display: block;
  margin: 0 auto;
  position: relative;
  top: 20px;
  .ant-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  & .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
    background-color: transparent;
    border: 0 none;
    & .anticon {
      color: #d9d9d9;
      opacity: 70%;
    }
  }
  & .ant-pagination .ant-pagination-item {
    background-color: transparent;
    border: 0 none;
    a {
      color: #d9d9d9;
      opacity: 50%;
    }
  }
  & .ant-pagination .ant-pagination-item-active {
    background-color: transparent;
    border: 0 none;
    a {
      color: #d9d9d9;
      font-weight: bold;
      opacity: 100%;
    }
  }
  & .ant-pagination .ant-pagination-next .ant-pagination-item-link {
    background-color: transparent;
    border: 0 none;
    & .anticon {
      color: #d9d9d9;
      opacity: 70%;
    }
  }
`;

const viewByParent = css`
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  display: none; // For now the functionality for view by session is on hold.
`;

// Card Styles
const cardMain = css`
  width: 100%;
  min-width: 300px;
  &.ant-card {
    width: 100%;
    margin-bottom: 8px;
    border-radius: 10px;
    color: var(--color-white);
    background: var(--color-charcoal-surface-3);
    cursor: pointer;
    box-shadow: none;
    .ant-card-body {
      padding: 0 0 16px 0;
    }
  }
  @media screen and (min-width: 992px) {
    min-width: 0;
    &.ant-card {
      margin-bottom: 0px;
      margin-right: 8px;
    }
  }
`;

const cardDivFirst = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-charcoal-surface-1);
  color: var(--color-white);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 6px 16px;
  min-height: 57px;
`;

const cardDivSecond = css`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  color: #191919;
  .anticon {
    font-size: 1rem;
  }
`;

const cardOutlinedButton = css`
  &.ant-btn.ant-btn-default {
    height: 32px;
    border-radius: 8px;
    position: relative;
    top: 2px;
    // border: solid 1px #222;
    // color: #222;
  }
`;

const cardBodyFirst = css`
  display: flex;
  padding: 16px;
  padding-bottom: 0;
  flex-direction: column;
  align-items: baseline;
`;

const cardBodyHeader = css`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;
const cardBodyHeaderText = css`
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  right: 2px;
`;

const cardBody2Parent = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
const cardBody2 = css`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: 14px;
  margin-botto: 4px;
  width: 100%;
`;
const cardCategory = css`
  margin-bottom: 4px;
  font-size: 12px;
  // overflow: auto;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // width: 90%;
`;

const cardCategories = css`
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 500;
  text-transform: capitalize;
`;

const cardTime = css`
  font-size: 10px;
  margin-bottom: 0px;
  color: var(--color-white);
`;

const userInfo = css`
  margin-left: 8px;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-white);
`;

/* App.css */
const fullHeight = css`
  height: fit-content;
  display: flex;
  border-radius: 10px;
  width: 100%;
  @media screen and (min-width: 992px) {
    height: calc(100vh - 378px);
  }
`;

const scrollableLeft = css`
  overflow-y: auto;
  height: 100%;
  flex: 0 0 auto;
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  color: black;
  margin-bottom: 16px;

  @media screen and (min-width: 992px) {
    margin-bottom: 0;
  }
`;

const scrollableRight = css`
  overflow-y: auto;
  flex: 0 0 auto;
  height: 100%;
  flex-basis: 70%;
  border-radius: 10px;
  background-color: var(--color-charcoal-surface-2);
  padding: 16px 40px;
`;

const verticalDivider = css`
  width: 1px;
  background-color: #ccc;
  height: 100%;
`;

const content = css`
  height: 100%;
  .ant-space-item {
    width: 100%;
    display: flex;
  }
`;

const chatMessageContainer = css`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const feedbackContainer = css`
  & .collapse_c7hb0xd.ant-collapse .ant-collapse-item.ant-collapse-item.ant-collapse-item .ant-collapse-content {
    max-width: 900px;
    width: 100%;
  }
  & .c7hb0xd.ant-collapse .ant-collapse-item.ant-collapse-item.ant-collapse-item .ant-collapse-content {
    max-width: 900px;
    width: 100%;
  }
`;

const chatDesc = css`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  font-weight: 400;
  width: calc(100% - 3.4rem);
  margin-bottom: 0.5rem;
`;

const messageLabel = css`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: baseline;
  font-weight: 600;
  color: black;
  margin-bottom: 0.2rem;
  font-size: 16px;
  color: var(--color-white);
`;

const messageLabelBot = css`
  font-weight: 600;
  margin-bottom: 0.2rem;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 8px;
`;

const timeAndInfoParent = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const reactionContainer = css`
  margin-top: 0.2rem;
  font-size: 1rem;
  & > * {
    opacity: 0.7;
    transition: transform 0.2s;
    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
`;

const timeTooltip = css`
  border-radius: 0.5rem;
  padding-bottom: 0.5rem !important;
  .ant-tooltip-content {
    border-radius: 0.5rem;
    font-size: 10px;
    .ant-tooltip-inner {
      border-radius: 0.5rem;
      margin: 5px;
      display: flex;
      align-items: center;
    }
  }
`;

const timestampTime = css`
  color: #1c1c1c;
  font-size: 0.7rem;
  margin-left: 8px;
  color: var(--color-gale-gray);
  margin-right: 30px;
`;

const timeStampTimeChat = css`
  float: right;
  visibility: unset;
  color: #1c1c1c;
  font-size: 0.7rem;
`;

const filterContainer = css`
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  background: var(--color-80-black);
  gap: 8px;
  align-content: flex-start;
  min-height: 30px;
  transition: min-height 1s ease-in 1s;

  &.expand {
    gap: 12px;
    padding: 16px;
    width: 90%;
    min-height: 100vh;
    transition: min-height 0.1s ease-in;

    > * {
      width: 100%;
      max-width: none;
    }
  }

  @media screen and (min-width: 992px) {
    width: 100%;
    background: transparent;
    transform: transitionX(0);
  }
`;

const filterDrawer = css`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  transform: translateX(-100%);
  width: 100%;
  height: fit-content;
  transition: transform 0.1s;

  &.expand {
    transform: translateX(0);
    transition: transform 0.2s;
  }

  @media screen and (min-width: 992px) {
    position: relative;
    transform: translateX(0);
    min-height: none;
  }
`;
const filterParent = css`
  padding: 10px 0 24px;
  background: rgba(0, 0, 0, 0.4);
  min-height: 30px;
  width: 100%;
  opacity: 0;
  transition: min-height 1s ease-in 1s;

  &.expand {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
    padding: 0;
    width: 100vw;
    min-height: 100vh;
    transition: min-height 0.1s ease-in;
  }

  @media screen and (min-width: 992px) {
    opacity: 1;
    width: 100%;
    position: relative;
    background: transparent;
  }
`;
const filterMenuBtn = css`
  border: none;
  background: transparent;
  color: var(--color-white);
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    color: var(--color-primary);
  }
`;
const noMessageContainer = css`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const helpContainer = css`
  margin-top: 7vh;
  @media screen and (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const helpImage = css`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-size: 18px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const dislikedBackground = css`
  &.chatDesc {
    color: lightCoral;
  }
`;

const likedBackground = css`
  .chatDesc {
    color: lightGreen;
  }
`;

const pointerMessage = css`
  scroll-behavior: smooth;
`;

const modalMaskStyle = css`
  background-color: rgba(0, 0, 0, 0.4);
`;

const modalContentStyle = css`
  // background-color: white !important;
`;
const questionContainer = css`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const questionLabel = css`
  width: fit-content;
  float: right;
  max-width: 80%;
  margin-bottom: 8px;
  border-radius: 16px;
  background: var(--color-charcoal-surface-3);
  padding: 12px 16px;
  color: var(--color-gale-gray);
  margin-right: 30px;
`;

export default {
  rowStyle,
  colStyle,
  labelStyle,
  selectStyle,
  inputStyle,
  datePickerStyle,
  horizontalDivider,
  paginationParent,
  viewByParent,
  cardMain,
  cardDivFirst,
  cardDivSecond,
  cardOutlinedButton,
  cardBodyFirst,
  cardBodyHeader,
  cardBodyHeaderText,
  cardBody2Parent,
  cardBody2,
  cardCategory,
  cardTime,
  cardCategories,
  fullHeight,
  scrollableLeft,
  scrollableRight,
  verticalDivider,
  content,
  chatMessageContainer,
  feedbackContainer,
  chatDesc,
  messageLabel,
  questionContainer,
  questionLabel,
  reactionContainer,
  timeTooltip,
  timestampTime,
  timeStampTimeChat,
  filterContainer,
  filterParent,
  filterDrawer,
  filterMenuBtn,
  radioStyle,
  userInfo,
  noMessageContainer,
  helpContainer,
  helpImage,
  dislikedBackground,
  likedBackground,
  pointerMessage,
  messageLabelBot,
  timeAndInfoParent,
  modalMaskStyle,
  modalContentStyle,
};
