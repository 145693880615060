import { Button } from 'antd';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import CenteredAiLoader from 'src/components/centeredAiLoader';
import LoadingSpinner from 'src/components/loadingSpinner';
import { ASK_AI, CLUSTER_BASED, RULE_BASED, VIEWER } from '../../../constants/constants';
import {
  AI_LOADING_TEXT,
  LABEL_ERROR_BOUNDARY_FALLBACK,
  LABEL_QUERY_SUMMARY,
} from '../../../constants/queryBuilderConstants';
import AudienceSize from './AudienceSize';
import './dataSourceTabLayout.style.less';
import QueryBuilder from './QueryBuilder';
import RulesRecommendationModal from './RulesRecommendationModal';
import useDataSourceTabLayout from './useDataSourceTabLayout';

const DataSourceTabLayout = ({
  clientId,
  audienceDescription,
  selectedAudienceData,
  setSelectedAudienceData,
  audienceSize,
  baseSize,
  audienceSizeLoading,
  getAudienceSize,
  errors,
  setErrors,
  behaviourDirectory,
  fromAudience,
  currentStep,
  selectedAudienceType,
  createAudienceLoading,
  onHandleBackFromDataSoruceTable,
  onHandleNextFromDataSoruceTable,
  handleSubmitFromDataSourceTable,
  audienceRole,
}) => {
  const {
    loading,
    rulesRecommendations,
    ruleRecommendationLoading,
    searchLoading,
    handleSearch,
    fields,
    addFeaturesToFields,
    conditions,
    setConditions,
    isModalVisible,
    aiPrompt,
    setAiPrompt,
    handleAskAiClick,
    handleApplyRecommendations,
    handleCancel,
    handleAskRecommendation,
    generateQuery,
  } = useDataSourceTabLayout({
    clientId,
    selectedAudienceData,
    audienceDescription,
    setSelectedAudienceData,
    getAudienceSize,
  });
  const { t } = useTranslation();
  useEffect(() => {
    setAiPrompt(audienceDescription);
  }, [audienceDescription]);

  const renderLoadingSpinner = () => {
    if (loading) return <LoadingSpinner centered />;
    if (ruleRecommendationLoading && !isModalVisible) return <CenteredAiLoader text={AI_LOADING_TEXT} />;
    return null;
  };

  const renderBackButton = () => {
    if (fromAudience && currentStep > 0 && selectedAudienceType !== CLUSTER_BASED) {
      return (
        <Button
          className={fromAudience ? 'small' : ''}
          type="default"
          onClick={onHandleBackFromDataSoruceTable}
          style={{ marginRight: '10px' }}
          size="small"
          disabled={createAudienceLoading}
        >
          {t('back')}
        </Button>
      );
    }
    return null;
  };

  const renderNextButton = () => {
    if (
      (selectedAudienceType === RULE_BASED && currentStep < 2) ||
      (selectedAudienceType === CLUSTER_BASED && currentStep === 0)
    ) {
      return (
        <Button
          className={fromAudience ? 'small' : ''}
          type="primary"
          onClick={onHandleNextFromDataSoruceTable}
          size="small"
        >
          {t('next')}
        </Button>
      );
    }
    return null;
  };

  const renderSubmitButton = () => {
    if (selectedAudienceType !== CLUSTER_BASED) {
      return (
        <Button
          className={fromAudience ? 'small' : ''}
          type="primary"
          onClick={() => handleSubmitFromDataSourceTable(false)}
          size="small"
          loading={createAudienceLoading}
          disabled={createAudienceLoading}
        >
          {audienceRole === VIEWER
            ? t('next')
            : currentStep === 2
              ? t('audience.save_and_continue')
              : t('audience.save_as_draft')}
        </Button>
      );
    }
    return null;
  };

  const renderButtons = () => (
    <div className="buttonContainer">
      <div className="buttonLeft">
        <Button className={fromAudience ? 'small' : ''} type="primary" onClick={handleAskAiClick}>
          {ASK_AI}
        </Button>
      </div>
      <div className="buttonRight">
        {renderBackButton()}
        {renderNextButton() || renderSubmitButton()}
      </div>
    </div>
  );

  return (
    <div id="data-source-tab-layout">
      {renderLoadingSpinner() || (
        <>
          {renderButtons()}
          <ErrorBoundary fallback={<div className="errorBoundaryFallback">{LABEL_ERROR_BOUNDARY_FALLBACK}</div>}>
            <div className="boolean-builder">
              <AudienceSize
                audienceSize={audienceSize}
                baseSize={baseSize}
                audienceSizeLoading={audienceSizeLoading}
                getAudienceSize={getAudienceSize}
                t={t}
              />
              <QueryBuilder
                conditions={conditions}
                setConditions={setConditions}
                fields={fields}
                searchLoading={searchLoading}
                handleSearch={handleSearch}
                isReadOnly={audienceRole === VIEWER}
                errors={errors}
                setErrors={setErrors}
                behaviourDirectory={behaviourDirectory}
                addFeaturesToFields={addFeaturesToFields}
              />
              <div id="query-output">
                <h2>{LABEL_QUERY_SUMMARY}</h2>
                <pre>{generateQuery(conditions)}</pre>
              </div>
            </div>
          </ErrorBoundary>
          <RulesRecommendationModal
            isModalVisible={isModalVisible}
            loading={ruleRecommendationLoading}
            aiPrompt={aiPrompt}
            handleAiPromptChange={(e) => setAiPrompt(e.target.value)}
            rulesRecommendations={rulesRecommendations}
            handleAskRecommendation={handleAskRecommendation}
            handleApplyRecommendations={handleApplyRecommendations}
            handleCancel={handleCancel}
          />
        </>
      )}
    </div>
  );
};

export default DataSourceTabLayout;
