import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { BRAND_AFFINITY } from '../../../constants/audienceDetailsConstants';
import audienceListService from '../../../services/audienceListService';

const useBrandAffinitiesHook = ({ audienceId, activeTab }) => {
  const [brandAffinitiesLoading, setBrandAffinitiesLoading] = useState(true);
  const [brandAffinitiesData, setBrandAffinitiesData] = useState(null);

  const fetchBrandAffinitiesData = async () => {
    try {
      const audienceData = await audienceListService.getAudienceFeatureProfiles(
        audienceId,
        '',
        '',
        1,
        BRAND_AFFINITY,
        true
      );
      setBrandAffinitiesData(audienceData.data);
      setBrandAffinitiesLoading(false);
    } catch (error) {
      setBrandAffinitiesLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getReplaceFeaturePayload = (newFeature, parentFeatureId) => {
    return {
      audience_profile_id: audienceId,
      parent_feature_id: parentFeatureId,
      new_parent_feature_name: newFeature.leaf_node,
      new_parent_feature_directory_filters: newFeature.directory_path,
      profile_type: BRAND_AFFINITY,
    };
  };

  const replaceBrandAffinities = async (newFeature, parentfeatureId) => {
    try {
      setBrandAffinitiesLoading(true);
      const payload = getReplaceFeaturePayload(newFeature, parentfeatureId);
      await audienceListService.replaceAudienceBehaviouralFeature(payload);
      await fetchBrandAffinitiesData();
    } catch (error) {
      setBrandAffinitiesLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  const getSwapFeaturePayload = (parentFeatureId, selectedFeatureIds) => {
    return {
      audience_profile_id: audienceId,
      feature_profile_ids: selectedFeatureIds,
      parent_feature_id: parentFeatureId,
      profile_type: BRAND_AFFINITY,
    };
  };

  const swapBeahaviouralFeatures = async (parentFeatureId, selectedFeatureIds) => {
    try {
      setBrandAffinitiesLoading(true);
      const payload = getSwapFeaturePayload(parentFeatureId, selectedFeatureIds);
      await audienceListService.swapFeatures(payload);
      await fetchBrandAffinitiesData();
      notification.success({
        message: 'Features swapped successfully',
        duration: 5,
        placement: 'bottomRight',
      });
    } catch (error) {
      setBrandAffinitiesLoading(false);
      const { msg } = getErrorMessageFromResponse(error);
      notification.error({
        duration: 5,
        message: msg,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    if (activeTab === '4' && brandAffinitiesData !== null) {
      fetchBrandAffinitiesData();
    } else if (brandAffinitiesData === null) {
      setBrandAffinitiesLoading(true);
      fetchBrandAffinitiesData();
    }
  }, [activeTab]);

  return {
    brandAffinitiesLoading,
    brandAffinitiesData,
    replaceBrandAffinities,
    swapBeahaviouralFeatures,
  };
};
export default useBrandAffinitiesHook;
