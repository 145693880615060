
import React from "react";
import {
  Row,
  Col,
  Typography,
  Tabs,
  Input,
  Popover,
  Select,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import Style from './knowledgeGraphStats.style';
import { useKnowledgeGraphStatHooks } from "./knowledgeGraphStats.hooks";
import VennDiagramComponent from "../vennDiagram/vennDiagram.component";
import OpportunitiesComponent from "../opportunities/opportunities.component";
import CommunityGraphComponent from "../communitiesGraph/communityGraph.component";
import NodePerformanceComponent from "../nodePerformance/nodePerformance.component";
import MarkdownRender from "src/modules/ConversationContainer/MarkdownRender";
import { PrimaryButton } from "src/components/basic";

const { Title } = Typography;
const { TabPane } = Tabs;


const KnowledgeGraphComponentStats = ({
  knowledgeGraphStats,
  handleNodePerformanceNodeClick,
  setNodesFilter,
  setCommunitiesFilter,
  analysisId,
  jobTriggers,
  containerRef,
  analysisOverview,
  analysisListData,
}) => {
  const {
    communities_descriptions,
  } = knowledgeGraphStats || {};

  const {
    onChartEvents,
    overlapType,
    setOverlapType,
    overlapAnalysisIDs,
    setOverlapAnalysisIDs,
    overlappingRegionsData,
    onChangeOverlappingRegions,
    overlappingRegionsColumnName,
    overlappingRegionsSearchText,
    overlappingRegionsDataLoading,
    setOverlappingRegionsSearchText,
    setOverlappingRegionsColumnName,
    applyFilters,
    filterOverlapAnalysisIds
  } = useKnowledgeGraphStatHooks({
    analysisId,
    handleNodePerformanceNodeClick,
  });
  const vennCategories = [
    {
      name: "Topics",
      value: "topics",
    },
    // {
    //   name: "Entities",
    //   value: "entities",
    // },
    {
      name: "Themes",
      value: "themes",
    },
    // {
    //   name: "Keywords",
    //   value: "keywords",
    // },
    {
      name: "Nodes",
      value: "nodes",
    },
    {
      name: "Handles",
      value: "handles",
    },
  ];

  return (
    <div className={Style.knowledgeGraphStatsContainer}>
      <Title level={3}>Insights</Title>

      <Tabs defaultActiveKey={"1"}>
        <TabPane tab="Opportunities" key="1">
          <OpportunitiesComponent
            analysisID={analysisId}
            jobTriggers={jobTriggers}
            setNodesFilter={setNodesFilter}
            setCommunitiesFilter={setCommunitiesFilter}
            containerRef={containerRef}
            analysisOverview={analysisOverview}
          />
        </TabPane>
        {communities_descriptions &&
          Object.values(communities_descriptions) &&
          Object.values(communities_descriptions)[0].likes !== undefined && (
            <TabPane tab="Communities" key="2">
              <CommunityGraphComponent
                communities={communities_descriptions}
                setCommunitiesFilter={setCommunitiesFilter}
              />
            </TabPane>
          )}

        <TabPane tab="Node Performance" key="3">
          <NodePerformanceComponent analysisID={analysisId} onChartEvents={onChartEvents} />
        </TabPane>
        <TabPane tab="Venn Diagram" key="7">
          <Row gutter={[10, 10]}>
            <Col span={5}>
              <Row gutter={[0, 10]} className={Style.knowledgeGraphFilters}>
                <Col span={24}>
                  <Select
                    mode="single"
                    style={{ width: "100%" }}
                    showArrow
                    placeholder="Select Grouping Type"
                    value={overlapType}
                    onChange={(value) => setOverlapType(value)}
                    maxTagCount={1}
                  >
                    <Select.Option key={1} value={"section"}>
                      Section
                    </Select.Option>
                    <Select.Option key={2} value={"analysis"}>
                      Analysis
                    </Select.Option>
                  </Select>
                </Col>
                <Col span={24}>
                  <Select
                    mode="single"
                    style={{ width: "100%" }}
                    showArrow
                    placeholder="Select Type"
                    value={overlappingRegionsColumnName}
                    onChange={(value) => setOverlappingRegionsColumnName(value)}
                    maxTagCount={1}
                  >
                    {vennCategories?.map((elem, idx) => {
                      return (
                        <Select.Option key={idx} value={elem.value}>
                          {elem.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
                {overlapType === "analysis" && (
                  <Select
                    mode="multiple"
                    style={{ width: "100%" }}
                    showArrow
                    placeholder="Select Analysis overlap"
                    value={overlapAnalysisIDs || []}
                    onChange={(value) => setOverlapAnalysisIDs(value)}
                    maxTagCount={1}
                    maxTagPlaceholder={(omittedValues) => (
                      <Popover
                        color="black"
                        overlayClassName={Style.tagPopover}
                        content={
                          <div className={Style.tagWrapper}>
                            {omittedValues.map(({ label, value }) => (
                              <div className={Style.tagText}>
                                {label}&nbsp;&nbsp;&nbsp;
                                <CloseOutlined
                                  onClick={() => {
                                    filterOverlapAnalysisIds(value);
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        }
                      >
                        <span>+{omittedValues.length}</span>
                      </Popover>
                    )}
                    allowClear
                    optionFilterProp="children"
                  >
                    {analysisListData?.map((elem, idx) => {
                      return (
                        <Select.Option key={idx} value={elem.id}>
                          {elem.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
                <Col span={24}>
                  <Input.Search
                    placeholder="Search"
                    value={overlappingRegionsSearchText}
                    onChange={(e) => setOverlappingRegionsSearchText(e.target.value)}
                  />
                </Col>
                <Col span={24}>
                  <PrimaryButton
                    className={Style.applyButton}
                    loading={overlappingRegionsDataLoading}
                    style={{ marginTop: "0", width: "100%", marginBottom: "1rem" }}
                    onClick={() => applyFilters()}
                  >
                    Apply filters
                  </PrimaryButton>
                </Col>
              </Row>
            </Col>
            <Col span={19}>
              {overlappingRegionsData && (
                <VennDiagramComponent
                  data={overlappingRegionsData}
                  columnName={overlappingRegionsColumnName}
                  loading={overlappingRegionsDataLoading}
                  analysisId={analysisId}
                />
              )}
            </Col>
          </Row>
          {overlappingRegionsData?.[0]?.summary && (
            <Row justify="center">
              <div>
                <Title level={3}>Summary</Title>
                {overlappingRegionsData.map(
                  (item, index) =>
                    item.summary && (
                      <div key={index}>
                        <Title level={4}>{item.sections.join(" & ")}</Title>
                        <MarkdownRender children={item.summary} />
                      </div>
                    ),
                )}
              </div>
            </Row>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};

export default React.memo(KnowledgeGraphComponentStats);
