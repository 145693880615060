import React from "react";
import { Typography, Tabs } from "antd";
import Style from "./analysisQuadrantsInsightsStyle";
import AnalysisQuadrantsBreakdownComponent from "../analysisQuadrantsBreakdown/analysisQuadrantsBreakdownComponent";
import AnalysisQuadrantsAISummaryComponent from "../analysisQuadrantsAISummary/analysisQuadrantsAISummaryComponent";

const { Title } = Typography;
const { TabPane } = Tabs;

const AnalysisQuadrantsInsightsComponent = ({ filterData, analysisOverview }) => {
  return (
    <div className={Style.insightsContainer}>
      <Title level={3}>Insights</Title>

      <Tabs defaultActiveKey="1">
        <TabPane tab="AI Summary" key="1">
          <AnalysisQuadrantsAISummaryComponent
            filterData={filterData}
            analysisOverview={analysisOverview}
          />
        </TabPane>
        <TabPane tab="Breakdown" key="2">
          <AnalysisQuadrantsBreakdownComponent
            filterData={filterData}
            analysisOverview={analysisOverview}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default React.memo(AnalysisQuadrantsInsightsComponent);
