import { Button, Input, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Assets from 'src/assets';

const { TextArea } = Input;

const renderRules = (rules) => {
  const { t } = useTranslation();
  return rules.map((rule, index) => (
    <li key={index}>
      <strong>{rule.field_readable_name && `${rule.field_readable_name}: `}</strong>
      {rule.reasoning}
      <ul>
        {rule.operator && (
          <li>
            <strong>{t('audience.operator')}:</strong> {rule.operator}
          </li>
        )}
        {rule.value && (
          <li>
            <strong>{t('audience.value')}: </strong>
            {Array.isArray(rule.value)
              ? rule.value.map((item, index) => (
                  <span key={index}>
                    {item.value_description}
                    {index < rule.value.length - 1 ? ', ' : ''}
                  </span>
                ))
              : typeof rule.value === 'object'
                ? rule.value.value_description
                : rule.value}
          </li>
        )}
      </ul>
      {rule.rules && <ul>{renderRules(rule.rules)}</ul>}
    </li>
  ));
};

const RulesRecommendationModal = ({
  isModalVisible,
  loading,
  aiPrompt,
  rulesRecommendations,
  handleAskRecommendation,
  handleApplyRecommendations,
  handleAiPromptChange,
  handleCancel,
}) => (
  <Modal
    title="AI Recommendations"
    visible={isModalVisible}
    onOk={handleAskRecommendation}
    footer={[
      loading ? (
        <span key="loading" style={{ alignItems: 'center' }}>
          Analyzing...{' '}
          <img
            src={Assets.getImage('AiLoader')}
            alt="AI"
            style={{ width: '20px', height: '20px', marginLeft: '10px' }}
          />{' '}
        </span>
      ) : (
        <Button
          key="submit"
          type="primary"
          onClick={
            Object.keys(rulesRecommendations).length === 0 ? handleAskRecommendation : handleApplyRecommendations
          }
        >
          {Object.keys(rulesRecommendations).length === 0 ? 'Recommend' : 'Apply'}
        </Button>
      ),
    ]}
    onCancel={handleCancel}
    width={600}
    centered
  >
    {Object.keys(rulesRecommendations).length > 0 ? (
      <div>
        <h3 style={{ color: '#fff' }}>Recommended Rules:</h3>
        <p>{rulesRecommendations.reasoning}</p>
        <ul>{renderRules(rulesRecommendations.rules)}</ul>
      </div>
    ) : (
      <TextArea
        rows={4}
        value={aiPrompt}
        onChange={handleAiPromptChange}
        placeholder="Enter your prompt for AI"
        style={{ marginBottom: '20px' }}
      />
    )}
  </Modal>
);

export default RulesRecommendationModal;
