import React from 'react';
import { Select } from 'antd';
import ReactECharts from 'echarts-for-react';
import useKnowledgeGraphHooks from './knowledgeGraph.hooks';
import {
  ExpandOutlined,
  CloseSquareOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import Style from './knowledgeGraph.style';

const KnowledgeGraphComponent = ({ nodes, links, categories, onNodeClick, onLinkClick, knowledgeGraphChanged }) => {
  const {
    option,
    onChartEvents,
    chartRef,
    setHighlightedNode,
    highlightedNode,
    setIsFullScreen,
    isFullScreen,
    isPaused,
    setPaused,
    resetZoom,
  } = useKnowledgeGraphHooks({
    nodes,
    links,
    categories,
    onNodeClick,
    onLinkClick,
    knowledgeGraphChanged,
  });

  return (
    <div className={`${isFullScreen ? Style.fullScreen : ''}`}>
      <Select
        className={Style.selectFilter}
        showSearch={true}
        showArrow
        placeholder="Highlight On-Screen Node"
        value={highlightedNode?.id}
        allowClear
        onClear={() => setHighlightedNode(null)}
        onChange={(value) => (value ? setHighlightedNode({ id: value }) : setHighlightedNode(null))}
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        options={nodes?.map((elem) => ({ value: elem.id, label: elem.name }))}
      />
      <ReactECharts
        className={`${isFullScreen ? Style.fullScreenKnowledgeGraph : ''}`}
        option={option}
        style={{
          height: '655px',
          width: '100%',
          zIndex: 2,
          border: 'solid 0.5px #ffffff2e',
          borderRadius: '10px',
          overflow: 'hidden',
        }}
        onEvents={onChartEvents}
        ref={chartRef}
      />
      <div className={Style.expandButton}>
        <ReloadOutlined onClick={() => resetZoom()} />
        {isPaused ? (
          <PlayCircleOutlined onClick={() => setPaused(false)} />
        ) : (
          <PauseCircleOutlined onClick={() => setPaused(true)} />
        )}
        {isFullScreen ? (
          <CloseSquareOutlined onClick={() => setIsFullScreen(!isFullScreen)} />
        ) : (
          <ExpandOutlined onClick={() => setIsFullScreen(!isFullScreen)} />
        )}
      </div>
    </div>
  );
};

export default React.memo(KnowledgeGraphComponent);
