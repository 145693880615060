import { Alert, Slider, Typography } from 'antd';
import ReactECharts from 'echarts-for-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { justFormatNumber } from 'src/lib/utils';
import './behaviourProbability.style.less';
import useBehaviourProbabilityHook from './useBehaviourProbabilityHook';

const { Title, Text } = Typography;

const BehaviourProbabilityComponent = ({ behaviourProbabilityData, setBehaviourSegmentCount }) => {
  const { t } = useTranslation();

  const {
    sliderValue,
    handleSliderChange,
    adjustedAudienceSize,
    adjustedPercentage,
    likelihood,
    showLowPrecisionMessage,
    chartOptions,
  } = useBehaviourProbabilityHook(behaviourProbabilityData, setBehaviourSegmentCount);

  if (Object.keys(behaviourProbabilityData).length === 0) {
    return (
      <div className="behaviour-probability-container">
        <Title level={3} className="behaviour-probability-title">
          {t('audience.audience_size_and_precision_adjustment')}
        </Title>
        <Text className="behaviour-probability-text">{t('audience.audience_defintion_warning')}</Text>
      </div>
    );
  }

  if (behaviourProbabilityData.behavioural_probability === null) {
    return (
      <div className="behaviour-probability-container">
        <Title level={3} className="behaviour-probability-title">
          {t('audience.audience_size_and_precision_adjustment')}
        </Title>
        <Text className="behaviour-probability-text">{t('audience.e1_features_selected')}</Text>
      </div>
    );
  }

  const maxSegments = behaviourProbabilityData.behavioural_probability.length;
  const sliderMax = maxSegments;

  return (
    <div className="behaviour-probability-container">
      <Title level={3} className="behaviour-probability-title">
        {t('audience.audience_probability_and_size_adjustment')}
      </Title>
      <Text className="behaviour-probability-text">
        {t('audience.the_audience_size_is')}: {justFormatNumber(adjustedAudienceSize)}, {t('audience.which_is')}{' '}
        {adjustedPercentage}
        {t('audience.percent_of_total_population')}
      </Text>
      <div className="behaviour-probability-slider">
        <Slider
          min={1}
          max={sliderMax}
          value={sliderValue}
          onChange={handleSliderChange}
          marks={{
            1: t('audience.slider_min'),
            [sliderMax]: t('audience.slider_max'),
          }}
        />
      </div>
      <Text className="behaviour-probability-text">
        {t('audience.this_audience_is')} {likelihood}{' '}
        {t('audience.times_as_likely_on_average_as_the_total_population_to_satisfy_this_query')}
      </Text>
      {showLowPrecisionMessage && (
        <Alert
          message={t('audience.low_precision_warning')}
          description={t('audience.low_precision_warning_description')}
          type="warning"
          showIcon
          className="low-precision-alert"
        />
      )}
      <div className="selected-segments">
        <ReactECharts option={chartOptions} style={{ height: 300, width: '100%' }} />
      </div>
    </div>
  );
};

export default BehaviourProbabilityComponent;
