import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Drawer, Input, List } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberWithCeil } from 'src/lib/utils';
import '../../createAudience/ruleBased/BrowseFeatures/browseBehaviourFeatures.style.less';
import useFeatureSelectionHook from './useFeatureSelectionHook';

const FeatureSelectionDrawer = ({ selectedCategory, visible, onClose, swapBeahaviouralFeatures }) => {
  const {
    drawerSelectedFeatures,
    searchQuery,
    handleCheckboxChange,
    handleSearchChange,
    handleRemoveItem,
    handleConfirm,
    filteredFeatures,
  } = useFeatureSelectionHook(selectedCategory, swapBeahaviouralFeatures, onClose);
  const { t } = useTranslation();

  return (
    <Drawer
      title="Select Features"
      placement="right"
      onClose={onClose}
      visible={visible}
      width="60%"
      footer={
        <div className="drawer-footer">
          <Button onClick={onClose}>{t('cancel')}</Button>
          <Button onClick={handleConfirm} type="primary" disabled={drawerSelectedFeatures.length !== 5}>
            {t('apply')}
          </Button>
        </div>
      }
    >
      <Input
        className="dark-theme-input"
        placeholder="Search..."
        prefix={<SearchOutlined />}
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ marginBottom: '16px' }}
      />
      <div className="drawer-content">
        <div className="tree-container">
          <table className="feature-table">
            <thead>
              <tr>
                <th>{t('audience.feature')}</th>
                <th>{t('audience.pen')}</th>
                <th>{t('audience.index')}</th>
                <th>{t('audience.select')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredFeatures.map((feature) => (
                <tr key={feature.id} className="tree-node">
                  <td>{feature.feature_readable_name}</td>
                  <td>{formatNumberWithCeil(feature.audience_feature_percentage)}%</td>
                  <td>{formatNumberWithCeil(feature.index_value)}</td>
                  <td>
                    <Checkbox
                      checked={drawerSelectedFeatures.includes(feature.id)}
                      onChange={(e) => handleCheckboxChange(e.target.checked, feature.id)}
                      disabled={drawerSelectedFeatures.length >= 5 && !drawerSelectedFeatures.includes(feature.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="selected-items">
          <h3>{t('audience.selectedFeatures')}</h3>
          <List
            dataSource={drawerSelectedFeatures}
            renderItem={(featureId) => {
              const feature = selectedCategory.features.find((f) => f.id === featureId);
              return (
                <List.Item
                  key={featureId}
                  actions={[
                    <Button
                      key={featureId + 'remove'}
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => handleRemoveItem(featureId)}
                    />,
                  ]}
                >
                  {feature ? `${feature.feature_readable_name}` : ''}
                </List.Item>
              );
            }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default FeatureSelectionDrawer;
