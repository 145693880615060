import { notification } from 'antd';
import { useEffect, useState } from 'react';
import AppUrls from 'src/constants/appUrls';
import { getErrorMessageFromResponse } from 'src/lib/utils';
import { RULE_BASED, VIEWER } from '../../constants/constants';
import audienceListService from '../../services/audienceListService';
import createAudienceService from '../../services/createAudienceService';

export default function useCreateAudienceHook(
  id,
  history,
  audienceId,
  audienceConfigId,
  isEdit,
  setCurrentStep,
  currentStep,
  projectId,
  projectName
) {
  const [selectedAudienceType, setSelectedAudienceType] = useState(RULE_BASED); // Default to RULE_BASED
  const [audienceRecommendations, setAudienceRecommendations] = useState({});
  const [audienceName, setAudienceName] = useState('');
  const [audienceDescription, setAudienceDescription] = useState('');
  const [selectedAudienceData, setSelectedAudienceData] = useState({
    purpose: '',
    audienceType: '',
  });
  const [createAudienceLoading, setCreateAudienceLoading] = useState(false);
  const [createdAudience, setCreatedAudience] = useState(null);
  const [audienceSize, setAudienceSize] = useState(0);
  const [audienceSizeLoading, setAudienceSizeLoading] = useState(false);
  const [baseSize, setBaseSize] = useState(0);
  const [audienceLoading, setAudienceLoading] = useState(false);
  const [behaviourProbabilityData, setBehaviourProbabilityData] = useState({});
  const [behaviouralSegmentCount, setBehaviourSegmentCount] = useState(0);
  const [audienceIdInDraft, setAudienceIdInDraft] = useState(null);
  const [errors, setErrors] = useState({});
  const [behaviourDirectory, setBehaviourDirectory] = useState({});
  const [audienceRole, setAudienceRole] = useState(null);
  const [audienceProfileId, setAudienceProfileId] = useState(null);
  const [audienceProjectList, setAudienceProjectList] = useState([]);
  const [audienceProjectName, setAudienceProjectName] = useState('');
  const [audienceProjectId, setAudienceProjectId] = useState('');

  const getAudienceRecommendationFromAi = async (prompt) => {
    try {
      const audienceRecommendation = await createAudienceService.getAudienceRecommendation(prompt);
      setAudienceRecommendations(audienceRecommendation);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAudienceBehaviorProbability = async (audienceId) => {
    try {
      const audienceProbability = await createAudienceService.getAudienceBehaviorProbability(audienceId);
      setBehaviourProbabilityData(audienceProbability.data);
      setCurrentStep(3);
      setCreateAudienceLoading(false);
    } catch (error) {
      const { details, msg } = getErrorMessageFromResponse(error);
      const errorMessage = details?.error || msg || 'Failed to get audience behaviour probability';
      notification.error({
        key: details?.resource_name || 'Error',
        message: errorMessage,
        duration: 5,
        placement: 'bottomRight',
      });
      setCurrentStep(3);
    }
  };

  const getAudienceBehaviouralDirectoryPayload = (clientId) => {
    return {
      client_space_id: clientId,
      with_features: true,
    };
  };

  const getAudiencePayload = (clientSpaceId, name, description, jsonData, audienceProjectId, audienceProjectName) => {
    return {
      ...(audienceProjectId.length > 0
        ? { audience_project_id: audienceProjectId }
        : { audience_project_name: audienceProjectName }),
      client_space_id: clientSpaceId,
      name: name,
      description: description,
      audience_type: jsonData.audienceType,
      json_data: jsonData,
    };
  };

  const validateRule = (rule, index, parentIndex) => {
    let error = {};
    if (!rule.field) {
      error.field = 'Field cannot be empty';
    }
    if (!rule.operator) {
      error.operator = 'Operator cannot be empty';
    }
    if (!rule.value || (Array.isArray(rule.value) && rule.value.length === 0)) {
      error.value = 'Value cannot be empty';
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [`${parentIndex}-${index}`]: error,
    }));
    return Object.keys(error).length === 0;
  };

  const validateConditions = (condition, parentIndex = '') => {
    return condition.rules.every((rule, index) => {
      if (rule.rules) {
        return validateConditions(rule, `${parentIndex}-${index}`);
      } else {
        return validateRule(rule, index, parentIndex);
      }
    });
  };

  const handleSuccess = (
    res,
    audienceData,
    currentStep,
    clientId,
    history,
    setAudienceIdInDraft,
    fetchAudienceBehaviorProbability,
    setCreateAudienceLoading
  ) => {
    setCreatedAudience(res.data);
    notification.success({
      message: 'Audience saved successfully.',
      duration: 6,
      placement: 'bottomRight',
    });
    if (audienceData.audienceType === RULE_BASED && currentStep === 2) {
      setAudienceIdInDraft(res.data.id);
      fetchAudienceBehaviorProbability(res.data.audience_profiles[0].id);
    } else {
      history.push(AppUrls.AUDIENCE_TOOL(clientId));
      setCreateAudienceLoading(false);
    }
  };

  const handleError = (error) => {
    setCreateAudienceLoading(false);
    const { details, msg } = getErrorMessageFromResponse(error);
    const errorMessage =
      details?.resource_name === 'e1_probability'
        ? "Sorry, that is a very niche audience you're trying to find! Can you broaden your definition so we can find you some relevant people in our Backbone?"
        : details?.error || msg || 'Failed to create Audience';

    notification.error({
      key: details?.resource_name || 'Error',
      message: errorMessage,
      duration: 5,
      placement: 'bottomRight',
    });
  };

  const handleValidationError = () => {
    const errorMessage = 'Please fill in all required fields and ensure all conditions are met.';
    notification.error({
      key: 'Error',
      message: errorMessage,
      duration: 5,
      placement: 'bottomRight',
    });
  };

  const createAudiences = async (audienceName, audienceDescription, audienceData, profiling) => {
    try {
      if (isValidAudience(audienceData)) {
        if (canCreateAudience(audienceRole)) {
          await createOrUpdateAudience(audienceName, audienceDescription, audienceData, profiling);
        } else if (isViewerWithRuleBasedAudience(audienceRole, audienceData)) {
          setCreateAudienceLoading(true);
          fetchAudienceBehaviorProbability(audienceProfileId);
        }
      } else {
        handleValidationError();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const isValidAudience = (audienceData) => {
    return (
      (audienceData.audienceType === RULE_BASED && validateConditions(audienceData.rules)) ||
      audienceData.audienceType !== RULE_BASED
    );
  };

  const canCreateAudience = (audienceRole) => {
    return audienceRole === null || audienceRole !== VIEWER;
  };

  const isViewerWithRuleBasedAudience = (audienceRole, audienceData) => {
    return audienceRole !== null && audienceRole === VIEWER && audienceData.audienceType === RULE_BASED;
  };

  const createOrUpdateAudience = async (audienceName, audienceDescription, audienceData, profiling) => {
    setCreateAudienceLoading(true);
    const clientId = id;
    const formattedPayload = getAudiencePayload(
      clientId,
      audienceName,
      audienceDescription,
      audienceData,
      audienceProjectId,
      audienceProjectName
    );
    if (isEdit || audienceIdInDraft !== null) {
      formattedPayload.audience_config_id = audienceIdInDraft !== null ? audienceIdInDraft : audienceId;
    }
    const res = await createAudienceService.createAudience(formattedPayload, profiling, behaviouralSegmentCount);
    handleSuccess(
      res,
      audienceData,
      currentStep,
      clientId,
      history,
      setAudienceIdInDraft,
      fetchAudienceBehaviorProbability,
      setCreateAudienceLoading
    );
  };

  const getProjectList = (data) => {
    return data.map((project) => ({
      id: project.id,
      name: project.name,
    }));
  };

  const getAudienceSize = async () => {
    if (selectedAudienceData.audienceType === RULE_BASED && validateConditions(selectedAudienceData.rules)) {
      try {
        setAudienceSizeLoading(true);
        const res = await createAudienceService.getAudienceSize(id, selectedAudienceData);
        setAudienceSize(res.data.audience_size);
        setBaseSize(res.data.base_size);
        setAudienceSizeLoading(false);
      } catch (error) {
        const { details, msg } = getErrorMessageFromResponse(error);
        const errorMessage =
          details?.resource_name === 'e1_probability'
            ? "Sorry, that is a very niche audience you're trying to find! Can you broaden your definition so we can find you some relevant people in our Backbone?"
            : details?.error || msg || 'Failed to get audience size';

        notification.error({
          key: details?.resource_name || 'Error',
          message: errorMessage,
          duration: 5,
          placement: 'bottomRight',
        });
        setAudienceSize(0);
        setAudienceSizeLoading(false);
      }
    } else {
      const errorMessage = 'Please fill in all required fields and ensure all conditions are met.';
      notification.error({
        key: 'Error',
        message: errorMessage,
        duration: 5,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setAudienceLoading(true);
        if (isEdit && audienceId) {
          setAudienceLoading(true);
          const audience = await audienceListService.getAudienceByid(audienceId);
          const audienceConfig = audience?.data?.audience_config.find((config) => config.id === audienceConfigId);

          const initialSelectedAudienceData = {
            purpose: audienceConfig.type_based_config.json_data.purpose || '',
            audienceType: audienceConfig.type_based_config.json_data.audienceType || '',
            ...(audienceConfig.type_based_config.json_data.rules
              ? { rules: audienceConfig.type_based_config.json_data.rules }
              : {}),
            ...(audienceConfig.type_based_config.json_data.selectedClusters
              ? { selectedClusters: audienceConfig.type_based_config.json_data.selectedClusters }
              : {}),
            role: audience.data.rule,
          };
          setSelectedAudienceType(audienceConfig.type_based_config.json_data.audienceType);
          setAudienceName(audienceConfig.name);
          setAudienceDescription(audienceConfig.description);
          setAudienceIdInDraft(audienceConfig.id);
          setAudienceRole(audience.data.role);
          setAudienceProfileId(audienceConfig.audience_profiles[0].id);
          setAudienceProjectId(audience.data.id);
          setAudienceProjectName(audience.data.name);
          setSelectedAudienceData(initialSelectedAudienceData);
          audienceConfig.audience_profiles[0] && setAudienceSize(audienceConfig.audience_profiles[0].audience_size);
          audienceConfig.audience_profiles[0] && setBaseSize(audienceConfig.audience_profiles[0].base_size);
          setCurrentStep(2);
        }
        if (projectId && projectName) {
          setAudienceProjectId(projectId);
          setAudienceProjectName(projectName);
          setSelectedAudienceType(RULE_BASED);
          setCurrentStep(1);
        }
        const behaviouralDirectoryPayload = getAudienceBehaviouralDirectoryPayload(id);
        const audienceBehaviouralFeatures =
          await createAudienceService.getAudienceBehaviouralDirectory(behaviouralDirectoryPayload);
        const list = await audienceListService.getAudienceProjectList(id, '');
        const audList = getProjectList(list.data.data);
        setAudienceProjectList(audList);
        setBehaviourDirectory(audienceBehaviouralFeatures.data);
        setAudienceLoading(false);
      } catch (error) {
        setAudienceLoading(false);
        const { details, msg } = getErrorMessageFromResponse(error);
        const errorMessage = details?.error || msg || 'Failed to get audience data';
        notification.error({
          key: details?.resource_name || 'Error',
          message: errorMessage,
          duration: 5,
          placement: 'bottomRight',
        });
      }
    }
    fetchData();
  }, [audienceId]);

  return {
    audienceRecommendations,
    setAudienceRecommendations,
    getAudienceRecommendationFromAi,
    selectedAudienceData,
    setSelectedAudienceData,
    createAudiences,
    createAudienceLoading,
    audienceSize,
    baseSize,
    audienceSizeLoading,
    getAudienceSize,
    audienceName,
    audienceDescription,
    setAudienceName,
    setAudienceDescription,
    selectedAudienceType,
    setSelectedAudienceType,
    audienceLoading,
    behaviourProbabilityData,
    setBehaviourSegmentCount,
    errors,
    setErrors,
    behaviourDirectory,
    audienceRole,
    audienceProjectList,
    audienceProjectName,
    setAudienceProjectName,
    audienceProjectId,
    setAudienceProjectId,
    createdAudience,
  };
}
