import { Col, DatePicker, Form, Input, Popover, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import { InputNo } from 'src/components/basic/Input';
import LoadingSpinner from 'src/components/loadingSpinner';
import appUrls from 'src/constants/appUrls';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import './CreateMediaPlanForm.less';
import useCreateMediaPlan from './useCreateMediaPlan';

const { RangePicker } = DatePicker;

const CreateMediaPlanForm = () => {
  const {
    configureLoading,
    loading,
    form,
    onFinish,
    history,
    selectedAudience,
    audienceOptions,
    metaData,
    mediaPlanData,
    mediaPlanId,
    clientId,
  } = useCreateMediaPlan();
  const { t } = useTranslation();

  if (loading) {
    return <LoadingSpinner centered />;
  }
  const mediaPlanState = mediaPlanData?.state;
  const disableEdit = mediaPlanState === 'CREATING';
  const mediaPlanCreated = mediaPlanState === 'CREATED';
  return (
    <div className="createMediaPlanContainer">
      <NcBreadCrumb />
      <h2 style={{ marginBottom: 24 }}>{t('mediaplan.create_media_plan')}</h2>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          audience: selectedAudience ? [selectedAudience.id] : [],
          generateBy: 'LLM',
        }}
      >
        <Form.Item>
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'end',
              display: 'flex',
            }}
          >
            <PrimaryButton
              onClick={() => {
                if (mediaPlanId) {
                  history.push(appUrls.MEDIA_PLAN_AUDIENCE_INFO(clientId, mediaPlanId));
                } else {
                  form.submit();
                }
              }}
              className="small"
              loading={configureLoading}
            >
              {mediaPlanId ? t('next') : t('save_next')}
            </PrimaryButton>
          </div>
        </Form.Item>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="name"
              label={t('mediaplan.media_plan_name')}
              rules={[{ required: true, message: 'Media Plan Name is required' }]}
              extra={t('mediaplan.media_plan_name_extra')}
            >
              <Input disabled={disableEdit || mediaPlanCreated} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="audience"
              label={t('mediaplan.audience')}
              rules={[{ required: true, message: 'Please select the audience' }]}
              extra={t('mediaplan.audience_extra')}
            >
              <Select
                mode="multiple"
                showArrow
                disabled={disableEdit || mediaPlanCreated}
                maxTagCount="responsive"
                maxTagPlaceholder={(omittedValues) => (
                  <Popover
                    color="black"
                    overlayClassName="tagPopover"
                    content={
                      <div className="tagWrapper">
                        {omittedValues.map(({ label }, id) => (
                          <div className={`tagText ${(disableEdit || mediaPlanCreated) && 'tagTextDisabled'}`} key={id}>
                            {label}&nbsp;&nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span>+{omittedValues.length}</span>
                  </Popover>
                )}
              >
                {audienceOptions?.map((audience, idx) => {
                  return (
                    <Select.OptGroup
                      label={
                        <div style={{ fontSize: '14px', color: 'gray', fontWeight: 'bold' }}>
                          <div>{audience?.name}</div>
                        </div>
                      }
                      key={idx}
                    >
                      {audience?.audience_config?.map((config) => {
                        return (
                          <Select.Option value={config?.id} key={config?.id}>
                            &nbsp;&nbsp;&nbsp;{config?.name}
                          </Select.Option>
                        );
                      })}
                    </Select.OptGroup>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="goals"
              label={t('mediaplan.media_plan_goals')}
              rules={[{ required: true, message: 'Objective is required' }]}
              extra={t('mediaplan.media_plan_goals_extra')}
            >
              <Select
                mode="multiple"
                maxTagCount="responsive"
                disabled={disableEdit || mediaPlanCreated}
                maxTagPlaceholder={(omittedValues) => (
                  <Popover
                    color="black"
                    overlayClassName="tagPopover"
                    content={
                      <div className="tagWrapper">
                        {omittedValues.map(({ label }, id) => (
                          <div className={`tagText ${(disableEdit || mediaPlanCreated) && 'tagTextDisabled'}`} key={id}>
                            {label}&nbsp;&nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span>+{omittedValues.length}</span>
                  </Popover>
                )}
              >
                {metaData?.goals?.map((goals) => (
                  <Select.Option value={goals} key={goals}>
                    {goals}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="channel"
              label={t('mediaplan.channel')}
              rules={[{ required: true, message: 'Please select the channel' }]}
              extra={t('mediaplan.channel_extra')}
            >
              <Select
                mode="multiple"
                maxTagCount="responsive"
                disabled={disableEdit || mediaPlanCreated}
                maxTagPlaceholder={(omittedValues) => (
                  <Popover
                    color="black"
                    overlayClassName="tagPopover"
                    content={
                      <div className="tagWrapper">
                        {omittedValues.map(({ label }, id) => (
                          <div className={`tagText ${(disableEdit || mediaPlanCreated) && 'tagTextDisabled'}`} key={id}>
                            {label}&nbsp;&nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span>+{omittedValues.length}</span>
                  </Popover>
                )}
              >
                {metaData?.channels?.map((channels) => (
                  <Select.Option value={channels} key={channels}>
                    {channels}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="kpis"
              label={t('mediaplan.marketing_kpi')}
              rules={[{ required: true, message: 'KPI is required' }]}
              extra={t('mediaplan.marketing_kpi_extra')}
            >
              <Select
                mode="multiple"
                maxTagCount="responsive"
                disabled={disableEdit || mediaPlanCreated}
                maxTagPlaceholder={(omittedValues) => (
                  <Popover
                    color="black"
                    overlayClassName="tagPopover"
                    content={
                      <div className="tagWrapper">
                        {omittedValues.map(({ label }, id) => (
                          <div className={`tagText ${(disableEdit || mediaPlanCreated) && 'tagTextDisabled'}`} key={id}>
                            {label}&nbsp;&nbsp;&nbsp;
                          </div>
                        ))}
                      </div>
                    }
                  >
                    <span>+{omittedValues.length}</span>
                  </Popover>
                )}
              >
                {metaData?.kpis?.map((kpis) => (
                  <Select.Option value={kpis} key={kpis}>
                    {kpis}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="budget"
              label={t('mediaplan.specify_your_budget')}
              extra={t('mediaplan.specify_your_budget_extra')}
              rules={[{ required: true, message: 'Budget is required' }]}
            >
              <InputNo
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} // Format to USD with commas
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')} // Remove dollar sign and commas for parsing
                min={0}
                disabled={disableEdit || mediaPlanCreated}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={12}>
            <Form.Item
              name="dateRange"
              label={t('mediaplan.start_end_date')}
              extra={t('mediaplan.start_end_date_extra')}
              rules={[{ required: true, message: 'Date Range is required' }]}
            >
              <RangePicker style={{ width: '100%' }} disabled={disableEdit || mediaPlanCreated} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default CreateMediaPlanForm;
