import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, List } from 'antd';
import React from 'react';
import { BEHAVIOUR, DEMOGRAPHIC } from 'src/modules/audienceTool/audienceManager/constants/queryBuilderConstants';
import './browseBehaviourFeatures.style.less';
import useBrowseFeatureHook from './useBrowseFeatureHook';

const BrowseBehaviourFeaturesComponent = ({
  fieldDirectory,
  browsedSelectedItems,
  setBrowsedSelectedItems,
  ruleType,
  searchQuery,
  setSearchQuery,
}) => {
  const {
    handleRemoveItem,
    handleSearchChange,
    filteredTree,
    filteredDemographicFields,
    filteredGroupedByCategory,
    renderTree,
    renderFieldDirectoryList,
    renderFolderStructure,
  } = useBrowseFeatureHook({
    fieldDirectory,
    browsedSelectedItems,
    setBrowsedSelectedItems,
    ruleType,
    searchQuery,
    setSearchQuery,
  });

  return (
    <>
      <Input
        className="dark-theme-input"
        placeholder="Search..."
        prefix={<SearchOutlined />}
        value={searchQuery}
        onChange={handleSearchChange}
        style={{ marginBottom: '16px' }}
      />
      <div className="drawer-content" key={searchQuery.length}>
        {ruleType === BEHAVIOUR ? (
          <div className="tree-container">{renderTree(filteredTree)}</div>
        ) : ruleType === DEMOGRAPHIC ? (
          <div className="tree-container">{renderFieldDirectoryList(filteredDemographicFields)}</div>
        ) : (
          <div className="tree-container">{renderFolderStructure(filteredGroupedByCategory)}</div>
        )}
        <div className="selected-items">
          <h3>Selected Items</h3>
          <List
            dataSource={browsedSelectedItems}
            renderItem={(item) => (
              <List.Item
                key={item}
                actions={[
                  <Button
                    key={item + 'remove'}
                    type="text"
                    icon={<DeleteOutlined />}
                    onClick={() => handleRemoveItem(item)}
                  />,
                ]}
              >
                {item}
              </List.Item>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default BrowseBehaviourFeaturesComponent;
