import { Select } from 'antd';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet';
import LoadingSpinner from 'src/components/loadingSpinner';
import {
  AUDIENCE_FEATURE_PERCENTAGE,
  COUNTY,
  DMA,
  INDEX_VALUE,
  PENETRATION_PERCENTAGE,
  STATE,
} from '../../../constants/audienceDetailsConstants';
import useGeographicHook from './useGeographicHook';

const { Option } = Select;

const GeographicHeatMap = ({ audienceId }) => {
  const {
    geographicLoading,
    shadingLevel,
    setShadingLevel,
    geographicLevel,
    setGeographicLevel,
    heatMapType,
    setHeatMapType,
    getGeoData,
    style,
    onEachFeature,
    Legend,
  } = useGeographicHook({ audienceId });

  if (geographicLoading) {
    return <LoadingSpinner centered />;
  }

  return (
    <>
      <p style={{ textAlign: 'left', fontSize: '16px', marginTop: '20px', marginLeft: '20px', color: 'white' }}>
        This interactive heatmap visualizes audience engagement levels across geographic regions, using color intensity
        to represent varying index values. Higher index values are shown in warmer colors, indicating regions with
        stronger engagement, while cooler colors represent areas with lower engagement. Hover over each region to see
        specific metrics, making it easy to identify key audience areas and understand regional performance at a glance.
      </p>
      <Select
        value={shadingLevel}
        style={{ width: 200, marginBottom: '20px', marginLeft: '20px' }}
        onChange={setShadingLevel}
      >
        <Option value={10}>Deciles (10)</Option>
        <Option value={5}>Quintiles (5)</Option>
        <Option value={20}>Duodeciles (20)</Option>
        <Option value={3}>Terciles (H, M, L)</Option>
      </Select>
      <Select
        value={geographicLevel}
        style={{ width: 200, marginBottom: '20px', marginLeft: '20px' }}
        onChange={setGeographicLevel}
      >
        <Option value={DMA}>{DMA}</Option>
        <Option value={COUNTY}>{COUNTY}</Option>
        <Option value={STATE}>{STATE}</Option>
      </Select>
      <Select
        value={heatMapType}
        style={{ width: 200, marginBottom: '20px', marginLeft: '20px' }}
        onChange={setHeatMapType}
      >
        <Option value={AUDIENCE_FEATURE_PERCENTAGE}>{AUDIENCE_FEATURE_PERCENTAGE}</Option>
        <Option value={INDEX_VALUE}>{INDEX_VALUE}</Option>
        <Option value={PENETRATION_PERCENTAGE}>{PENETRATION_PERCENTAGE}</Option>
      </Select>
      <MapContainer
        center={[36, -96.5]} // Centered on the United States
        zoom={4.5}
        style={{ height: '900px', width: '100%', backgroundColor: 'transparent' }}
        zoomControl={true}
        touchZoom={false}
        doubleClickZoom={false}
        scrollWheelZoom={false}
        boxZoom={false}
        attributionControl={false}
        key={geographicLevel + '-' + heatMapType}
      >
        <TileLayer
          url="https://api.mapbox.com/styles/v1/mapbox/dark-v10/tiles/{z}/{x}/{y}"
          tileSize={512}
          zoomOffset={-1}
        />
        {getGeoData() && <GeoJSON data={getGeoData()} style={style} onEachFeature={onEachFeature} />}
        <Legend />
      </MapContainer>
    </>
  );
};

export default GeographicHeatMap;
