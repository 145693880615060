import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Select, Tooltip } from 'antd';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AutocompleteTextarea from '../../AutoCompleteTextArea';
import * as Styles from './styles';

const { Option } = Select;

const OutputColumnsForm = ({ form, columns, selectedInputColumns }) => {
  const { t } = useTranslation();
  const [hoveredRow, setHoveredRow] = useState(null);

  const validateOutputColumns = useCallback(async (_, outputColumns) => {
    if (!outputColumns?.length) {
      return Promise.reject(new Error('labeler.column_error'));
    }
  }, []);

  const availableColumns = React.useMemo(() => {
    return columns.filter((column) => !selectedInputColumns.includes(column.name));
  }, [columns, selectedInputColumns]);

  return (
    <Form.List name="outputColumns" rules={[{ validator: validateOutputColumns }]}>
      {(fields, { add, remove }, { errors }) => (
        <div className={Styles.formContainer}>
          <div className={Styles.formHeader}>
            <div className={Styles.titleContainer}>
              <h3 className={Styles.formTitle}>{t('labeler.configure_output_columns')}</h3>
              <Tooltip
                overlayInnerStyle={{
                  width: '300px',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                }}
                title={t('labeler.output_column_info')}
                placement="right"
              >
                <InfoCircleOutlined className={Styles.infoIcon} />
              </Tooltip>
            </div>
            <Button type="primary" icon={<PlusOutlined />} onClick={() => add()} className={Styles.addColumnButton}>
              {t('labeler.add_column')}
            </Button>
          </div>

          {fields.length === 0 ? (
            <div className={Styles.emptyState}>
              <p>{t('labeler.output_column_title')}</p>
            </div>
          ) : (
            <div className={Styles.columnsContainer}>
              <div className={Styles.columnHeaders}>
                <div className={Styles.headerColumn}>{t('labeler.column_caps')}</div>
                <div className={Styles.headerColumn}>{t('labeler.description_caps')}</div>
                <div className={Styles.headerAction}></div>
              </div>
              <div className={Styles.scrollableContainer}>
                {fields.map((field, index) => (
                  <div
                    className={Styles.inputColumnRow}
                    key={field.key}
                    onMouseEnter={() => setHoveredRow(index)}
                    onMouseLeave={() => setHoveredRow(null)}
                  >
                    <Form.Item
                      name={[field.name, 'name']}
                      rules={[{ required: true, message: t('labeler.column_is_required') }]}
                    >
                      <Select
                        className={Styles.columnSelect}
                        placeholder={t('labeler.output_column')}
                        showSearch
                        optionFilterProp="children"
                      >
                        {availableColumns.map((col) => (
                          <Option key={col.name} value={col.name}>
                            {col.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      name={[field.name, 'description']}
                      rules={[{ required: true, message: t('labeler.description_is_required') }]}
                    >
                      <AutocompleteTextarea
                        value={form.getFieldValue(['outputColumns', field.name, 'description'])}
                        columns={form.getFieldValue('inputColumns') || []}
                        onChange={(value) => {
                          const outputColumns = form.getFieldValue('outputColumns');
                          outputColumns[field.name].description = value;
                          form.setFieldsValue({ outputColumns });
                        }}
                        placeholder={t('labeler.output_column_placeholder')}
                      />
                    </Form.Item>

                    <Tooltip title={t('labeler.delete_column')}>
                      <DeleteOutlined
                        className={`${Styles.deleteIcon} ${hoveredRow === index ? Styles.deleteIconVisible : ''}`}
                        onClick={() => remove(field.name)}
                      />
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          )}

          {errors && <Form.ErrorList errors={errors} className={Styles.errorList} />}
        </div>
      )}
    </Form.List>
  );
};

export default React.memo(OutputColumnsForm);
