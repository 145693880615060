import { useEffect, useState } from 'react';
import { formatNumber, formatNumberWithPercentageAndCeil } from 'src/lib/utils';
import audienceListService from '../../../services/audienceListService';
import mediaPlanService from '../../../services/mediaPlanService';

const useMultiplierTabHook = ({ audienceProfileId, clientId }) => {
  const [audienceMultiplierData, setAudienceMultiplierData] = useState(null);
  const [multiplierLoading, setMultiplierLoading] = useState(false);
  const [totalMultiplierData, setTotalMultiplierData] = useState(0);
  const [sortBy, setSortBy] = useState('-index_value');
  const [currentPage, setCurrentPage] = useState(1);
  const [channelList, setChannelList] = useState([]);
  const [tacticPartnerList, setTacticPartnerList] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState('');
  const [selectedTacticPartner, setSelectedTacticPartner] = useState('');

  const getMultiplierColumns = () => [
    {
      title: 'Channel',
      key: 'channel',
      render: (text, record) => record.feature_value_config?.channel || 'N/A',
    },
    {
      title: 'Tactic Partner',
      key: 'tactic_partner',
      render: (text, record) => record.feature_value_config?.tactic_partner || 'N/A',
    },
    {
      title: 'Index',
      dataIndex: 'index_value',
      key: 'index_value',
      render: (text) => `${text === 0 ? '0' : formatNumber(text)}`,
      sorter: true,
    },
    {
      title: 'Audience Percentage',
      dataIndex: 'audience_feature_percentage',
      key: 'audience_feature_percentage',
      render: (text) => `${text === 0 ? '0%' : formatNumberWithPercentageAndCeil(text)}`,
      sorter: true,
    },
    {
      title: 'Source',
      dataIndex: 'feature_source',
      key: 'feature_source',
    },
  ];

  const handleSortChange = (pagination, filters, sorter) => {
    if (Object.keys(sorter).length > 0) {
      const order = sorter.order === 'ascend' ? sorter.field : `-${sorter.field}`;
      setSortBy(order);
    }
  };

  const getMetaData = async () => {
    try {
      const res = await mediaPlanService.getMetaData(clientId);
      setChannelList(res?.data?.channels);
      setTacticPartnerList(res?.data?.tactics_partners);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAudienceMultiplierData = async () => {
    try {
      setMultiplierLoading(true);
      const audienceData = await audienceListService.getAudienceFeatureProfiles(
        audienceProfileId,
        sortBy,
        '',
        currentPage,
        'MEDIA_ATTRIBUTION',
        undefined,
        10,
        selectedChannel,
        selectedTacticPartner
      );
      setAudienceMultiplierData(audienceData.data.data);
      setTotalMultiplierData(audienceData.data.total);
      setMultiplierLoading(false);
    } catch (error) {
      setMultiplierLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getMetaData();
    fetchAudienceMultiplierData();
  }, []);

  useEffect(() => {
    fetchAudienceMultiplierData();
  }, [audienceProfileId, currentPage, sortBy, selectedChannel, selectedTacticPartner]);

  return {
    audienceMultiplierData,
    multiplierLoading,
    totalMultiplierData,
    getMultiplierColumns,
    currentPage,
    setCurrentPage,
    handleSortChange,
    channelList,
    selectedChannel,
    setSelectedChannel,
    selectedTacticPartner,
    setSelectedTacticPartner,
    tacticPartnerList,
  };
};
export default useMultiplierTabHook;
