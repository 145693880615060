import { useState, useEffect } from 'react';
import { notification } from 'antd';
import NcApiServices from '../../services/NcApiServices';

const useAnalysisAISummaryHooks = ({ filterData, analysisOverview }) => {
  const [aiSummary, setAISummary] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchAISummary = async () => {
    if (!analysisOverview || !filterData) {
      return;
    }

    try {
      setLoading(true);
      const response = await NcApiServices.getQuadrantAISummary(analysisOverview.id, filterData);

      if (response.success) {
        setAISummary(response.data);
      } else {
        notification.error({
          message: 'Failed to fetch data',
          placement: 'bottomRight',
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: 'Error fetching data',
        description: error.message,
        placement: 'bottomRight',
      });
    }
  };

  useEffect(() => {
    fetchAISummary();
  }, [analysisOverview, filterData]);

  return {
    aiSummary,
    loading,
  };
};

export default useAnalysisAISummaryHooks;
