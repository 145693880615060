import { AutoComplete, Form, Row, Select } from 'antd';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import { formatString } from 'src/lib/utils';

const AddUserToAppForm = ({ data, metaData, setAddUserModal, addUpdateUserToMicroApp }) => {
  const { t } = useTranslation();
  const formRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const options = [...(metaData?.internal_users || []), ...(metaData?.external_users || [])].map((item) => {
    return { value: item.email, label: item.email };
  });

  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const [filteredOptions, setFilteredOptions] = useState(options);

  const onFilterOptions = (val) => {
    if (val) {
      const filtered = options.filter((item) => {
        if (item.value.includes(val)) {
          return item;
        }
      });
      setFilteredOptions(filtered);
    } else {
      setFilteredOptions(options);
    }
  };

  const checkAlreadySelected = (email) => {
    let emails = data?.data?.access?.map((item) => item.email) || [];
    if (emails.includes(email)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        <Form
          requiredMark={false}
          ref={formRef}
          onFinish={async (val) => {
            setLoading(true);
            await addUpdateUserToMicroApp(data?.appType, data?.data?.id, val);
            setAddUserModal({ visible: false, data: {} });
            setLoading(false);
          }}
          labelAlign="left"
          labelCol={{ span: 3 }}
        >
          <Form.Item
            label={t('email')}
            name="email"
            rules={[
              {
                required: true,
                message: t('email_required'),
              },
              {
                validator: (_, value) => {
                  if (value) {
                    if (!value.trim().length) {
                      return Promise.reject(new Error(t('email_required')));
                    } else {
                      const test = validateEmail(value);
                      if (test) {
                        if (!checkAlreadySelected(value)) {
                          return Promise.resolve();
                        } else {
                          return Promise.reject(new Error(t('email_already_selected')));
                        }
                      } else {
                        return Promise.reject(new Error(t('email_invalid')));
                      }
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <AutoComplete
              placeholder={t('enter_email')}
              options={filteredOptions}
              onSearch={(val) => {
                onFilterOptions(val);
              }}
            />
          </Form.Item>
          <ConditionalRender shouldRender={metaData?.micro_apps_roles?.[data?.appType]}>
            <Form.Item
              label={t('role')}
              name="role"
              rules={[
                {
                  required: true,
                  message: t('role_required'),
                },
              ]}
            >
              <Select>
                {metaData?.micro_apps_roles?.[data?.appType]?.map((item) => {
                  return (
                    <Select.Option key={item?.id} value={item?.id}>
                      {formatString(item?.role)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </ConditionalRender>
        </Form>
      </div>
      <Row justify="end">
        <SecondaryButton loading={loading} onClick={() => formRef.current.submit()}>
          {t('done')}
        </SecondaryButton>
      </Row>
    </div>
  );
};

export default AddUserToAppForm;
