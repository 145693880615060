import { DeleteOutlined } from '@ant-design/icons';
import { Col, Collapse, DatePicker, Form, Modal, Popover, Row, Select, Slider } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton, SecondaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import appUrls from 'src/constants/appUrls';
import { numberWithCommas } from 'src/lib/utils';
import NcBreadCrumb from 'src/modules/newsAndCulture/components/NcBreadCrumb';
import MediaPlanAudienceDrawer from './MediaPlanAddAudienceDrawer';
import './MediaPlanAudienceInformation.less';
import useMediaPlanAudienceInformation from './useMediaPlanAudienceInformation';

export const MediaPlanAudienceInformation = () => {
  const {
    loading,
    formRef,
    setAddAudienceModal,
    addAudienceModal,
    audienceOptions,
    audienceInfo,
    disabledDate,
    saveAndRunReccomendAudience,
    configureLoading,
    mediaPlanId,
    allocatedBudget,
    setAllocatedBudget,
    clientId,
    history,
    createdState,
    deleteMediaPlanAudience,
    deleteLoading,
  } = useMediaPlanAudienceInformation();
  const formatter = (value) => `$${numberWithCommas(value)}`;
  const { t } = useTranslation();
  if (loading) {
    return <LoadingSpinner centered />;
  }

  return (
    <div>
      <NcBreadCrumb />
      <h2>{t('mediaplan.audience_information')}</h2>
      <Row gutter={[20, 0]} justify="end" style={{ marginBottom: '1rem' }}>
        <Col>
          <SecondaryButton
            comfy
            className="small"
            onClick={() => history.push(appUrls.EDIT_MEDIA_PLAN(clientId, mediaPlanId))}
          >
            {t('back')}
          </SecondaryButton>
        </Col>
        <Col>
          <PrimaryButton loading={configureLoading} className="small" comfy onClick={() => formRef.current.submit()}>
            {audienceInfo?.mediaplan_state === 'DRAFT' ? t('save_next') : t('next')}
          </PrimaryButton>
        </Col>
      </Row>
      <Row className="header-container">
        <Col span={6}>
          <b>{t('mediaplan.media_plan_name')}:</b> {audienceInfo?.mediaplan_name}
        </Col>
        <Col span={4}>
          <b>{t('mediaplan.budget')}:</b> ${numberWithCommas(audienceInfo?.mediaplan_budget)}
        </Col>
        <Col span={4}>
          <b>{t('mediaplan.allocated_budget')}:</b> ${numberWithCommas(allocatedBudget)}
        </Col>
        <Col span={5}>
          <b>{t('mediaplan.start_date')}:</b> {dayjs(audienceInfo?.mediaplan_start_date).format('MM/DD/YYYY')}
        </Col>
        <Col span={5}>
          <b>{t('mediaplan.end_date')}:</b> {dayjs(audienceInfo?.mediaplan_end_date).format('MM/DD/YYYY')}
        </Col>
      </Row>
      <h3>{t('mediaplan.audiences')}:</h3>
      <div style={{ marginLeft: '1rem' }}>
        <Form
          initialValues={audienceInfo}
          requiredMark={false}
          ref={formRef}
          onFinish={(val) => {
            if (audienceInfo?.mediaplan_state === 'DRAFT') {
              saveAndRunReccomendAudience(val);
            } else {
              history.push(appUrls.MEDIA_PLAN_SCENARIO_PLANNING_SCREEN(clientId, audienceInfo?.mediaplan_response_id));
            }
          }}
          onValuesChange={(changedValues, allValues) => {
            let budget = 0;
            if (changedValues?.audience_mapping) {
              allValues.audience_mapping.forEach((audience) => {
                if (audience?.budget) {
                  budget += audience.budget;
                }
              });
            }
            if (budget !== allocatedBudget) {
              setAllocatedBudget(budget);
            }
          }}
        >
          <Form.Item name="audience_budget_mapping">
            <Form.List name="audience_mapping">
              {(fields, { add, remove }) => {
                return (
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldsValue }) => {
                      return (
                        <>
                          {fields.map(({ key, name, fieldKey, ...restField }, idx) => {
                            const audiences = getFieldsValue('audiences');
                            const audienceName = audiences?.audience_mapping?.[idx]?.audience_name;
                            const audienceId = audiences?.audience_mapping?.[idx]?.id;
                            return (
                              <div className="audienceItemContainer" key={key}>
                                <Collapse
                                  className={'regularCollapse'}
                                  style={{ flexGrow: '1' }}
                                  defaultActiveKey={['0']}
                                >
                                  <Collapse.Panel forceRender header={<b>{audienceName}</b>} key={idx}>
                                    <Row key={key} gutter={[20, 20]}>
                                      <Col span={24}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'json_data', 'goals']}
                                          fieldKey={[fieldKey, 'audienceObjective']}
                                          labelCol={{ span: 4 }}
                                          labelAlign="left"
                                          label={t('mediaplan.audience_objective')}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing audience objective',
                                            },
                                          ]}
                                        >
                                          <Select
                                            mode="multiple"
                                            maxTagCount="responsive"
                                            placeholder={t('mediaplan.audience_objective_placeholder')}
                                            showArrow
                                            disabled={createdState}
                                            maxTagPlaceholder={(omittedValues) => (
                                              <Popover
                                                color="black"
                                                overlayClassName="tagPopover"
                                                content={
                                                  <div className="tagWrapper">
                                                    {omittedValues.map(({ label }, id) => (
                                                      <div
                                                        className={`tagText ${createdState && 'tagTextDisabled'}`}
                                                        key={id}
                                                      >
                                                        {label}&nbsp;&nbsp;&nbsp;
                                                      </div>
                                                    ))}
                                                  </div>
                                                }
                                              >
                                                <span>+{omittedValues.length}</span>
                                              </Popover>
                                            )}
                                          >
                                            {audienceInfo?.mediaplan_goals?.map((goal, idx) => (
                                              <Select.Option key={idx} value={goal}>
                                                {goal}
                                              </Select.Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col span={24}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'json_data', 'channels']}
                                          fieldKey={[fieldKey, 'audienceChannels']}
                                          label={t('mediaplan.channels')}
                                          labelCol={{ span: 4 }}
                                          labelAlign="left"
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Channels is required.',
                                            },
                                          ]}
                                        >
                                          <Select
                                            mode="multiple"
                                            placeholder={t('mediaplan.channels_placeholder')}
                                            maxTagCount="responsive"
                                            showArrow
                                            disabled={createdState}
                                            maxTagPlaceholder={(omittedValues) => (
                                              <Popover
                                                color="black"
                                                overlayClassName="tagPopover"
                                                content={
                                                  <div className="tagWrapper">
                                                    {omittedValues.map(({ label }, id) => (
                                                      <div
                                                        className={`tagText ${createdState && 'tagTextDisabled'}`}
                                                        key={id}
                                                      >
                                                        {label}&nbsp;&nbsp;&nbsp;
                                                      </div>
                                                    ))}
                                                  </div>
                                                }
                                              >
                                                <span>+{omittedValues.length}</span>
                                              </Popover>
                                            )}
                                          >
                                            {audienceInfo?.mediaplan_channels?.map((channels, idx) => (
                                              <Select.Option key={idx} value={channels}>
                                                {channels}
                                              </Select.Option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      <Col span={12}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'dateRange']}
                                          fieldKey={[fieldKey, 'dateRange']}
                                          labelAlign="left"
                                          labelCol={{ span: 8 }}
                                          label={t('mediaplan.time')}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Missing time range',
                                            },
                                          ]}
                                        >
                                          <DatePicker.RangePicker disabledDate={disabledDate} disabled={createdState} />
                                        </Form.Item>
                                      </Col>
                                      <Col span={12}>
                                        <Form.Item
                                          {...restField}
                                          name={[name, 'budget']}
                                          fieldKey={[fieldKey, 'budget']}
                                          labelCol={{ span: 4 }}
                                          labelAlign="left"
                                          label={t('mediaplan.budget')}
                                          rules={[
                                            {
                                              required: true,
                                              message: 'Budget is required.',
                                            },
                                          ]}
                                        >
                                          <Slider
                                            style={{ marginRight: '1rem' }}
                                            min={0}
                                            // style={{ width: "45%" }}
                                            max={audienceInfo?.mediaplan_budget}
                                            disabled={createdState}
                                            tooltip={{
                                              formatter,
                                            }}
                                            marks={{
                                              [0]: `$${numberWithCommas(0)}`,
                                              [audienceInfo?.mediaplan_budget]: `$${numberWithCommas(
                                                audienceInfo?.mediaplan_budget
                                              )}`,
                                            }}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </Row>
                                  </Collapse.Panel>
                                </Collapse>
                                <ConditionalRender shouldRender={!createdState}>
                                  <DeleteOutlined
                                    style={{ marginTop: '-10px' }}
                                    className={`${deleteLoading && 'deleteAudienceIcon'}`}
                                    onClick={() => {
                                      deleteMediaPlanAudience(remove, name, audienceId);
                                    }}
                                  />
                                </ConditionalRender>
                              </div>
                            );
                          })}
                          {/* <Form.Item>
                            <SecondaryButton className="small" onClick={() => setAddAudienceModal(true)}>
                              <PlusOutlined />
                              {t('mediaplan.add_audience')}
                            </SecondaryButton>
                          </Form.Item> */}
                          <Modal
                            open={addAudienceModal}
                            onCancel={() => setAddAudienceModal(false)}
                            title={t('mediaplan.add_audience')}
                            footer={null}
                            destroyOnClose
                          >
                            <MediaPlanAudienceDrawer
                              audienceOptions={audienceOptions}
                              add={add}
                              setAddAudienceModal={setAddAudienceModal}
                              mediaPlanId={mediaPlanId}
                            />
                          </Modal>
                        </>
                      );
                    }}
                  </Form.Item>
                );
              }}
            </Form.List>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
