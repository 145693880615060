import React, { useEffect, useState } from 'react';
import ConditionalRender from 'src/components/conditionalRender';
import useNewLoginHook from 'src/modules/authentication/hooks/useNewLoginHook';
import Style from './login.style';
import LoginForm from './LoginForm';
import ResetPassword from './ResetPassword';

export default function Login() {
  const { data, tab, navigateToPassword, navigateToEmail, doLogin, loading, resetPassword } = useNewLoginHook();
  const [formData, setFormData] = useState(data);

  useEffect(() => {
    setFormData(data);
  }, [data]);

  return (
    <div className={Style.container + ' transition-all'}>
      <ConditionalRender shouldRender={tab === 'email' || tab === 'password'}>
        <LoginForm
          data={formData}
          navigateToPassword={navigateToPassword}
          displayPassword={tab === 'password'}
          navigateToEmail={navigateToEmail}
          doLogin={doLogin}
          loading={loading}
        />
      </ConditionalRender>
      <ConditionalRender shouldRender={tab === 'resetPassword'}>
        <ResetPassword data={data} loading={loading} resetPassword={resetPassword} />
      </ConditionalRender>
    </div>
  );
}
