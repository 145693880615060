import { useState, useEffect, useCallback, useMemo } from 'react';
import { notification } from 'antd';
import NcApiServices from '../../services/NcApiServices';

const useAnalysisQuadrantsBreakdownHooks = ({ filterData, analysisOverview }) => {
  const [breakdown, setBreakdown] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedAnalysisType, setSelectedAnalysisType] = useState('themes');
  const [selectedValue, setSelectedValue] = useState(null);

  const fetchBreakdown = async () => {
    if (!analysisOverview || !filterData) {
      return;
    }

    try {
      setLoading(true);
      const response = await NcApiServices.getQuadrantBreakdown(analysisOverview.id, filterData);

      if (response.success) {
        setBreakdown(response.data);
      } else {
        notification.error({
          message: 'Failed to fetch data',
          placement: 'bottomRight',
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notification.error({
        message: 'Error fetching data',
        description: error.message,
        placement: 'bottomRight',
      });
    }
  };

  const createPieChartOption = (data) => {
    return {
      darkMode: true,
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b}: {c} ({d}%)',
      },
      series: Object.keys(data).map((quadrant, index) => ({
        name: quadrant,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        left: (index % 2) * 50 + '%',
        top: Math.floor(index / 2) * 50 + '%',
        width: '50%',
        height: '50%',
        emphasis: {
          label: {
            show: true,
            fontSize: '14',
            fontWeight: 'bold',
          },
        },
        label: {
          color: '#fff',
        },
        data: Object.entries(data[quadrant] || {})
          .map(([name, value]) => ({
            name,
            value,
          }))
          .sort((a, b) => b.value - a.value),
      })),
      title: Object.keys(data).map((quadrant, index) => ({
        text: quadrant,
        left: (index % 2) * 50 + 25 + '%', // Center the title horizontally within the pie chart
        top: Math.floor(index / 2) * 50 + 47 + '%', // Position the title below the pie chart
        textAlign: 'center',
        textStyle: {
          color: '#fff',
          fontSize: 12,
        },
      })),
    };
  };

  const pieChartOption = useMemo(() => {
    if (breakdown) {
      return createPieChartOption(breakdown[selectedAnalysisType]);
    }
  }, [breakdown, selectedAnalysisType]);

  const handleClick = useCallback(
    (e) => {
      const analysisType = selectedAnalysisType.charAt(0).toUpperCase() + selectedAnalysisType.slice(1);
      setSelectedValue({ [`selected${analysisType}`]: [e.name] });
    },
    [selectedAnalysisType]
  );

  const onChartEvents = useMemo(
    () => ({
      click: (params) => {
        handleClick(params);
      },
    }),
    [handleClick]
  );

  useEffect(() => {
    fetchBreakdown();
  }, [analysisOverview, filterData]);

  return {
    breakdown,
    loading,
    createPieChartOption,
    selectedAnalysisType,
    setSelectedAnalysisType,
    onChartEvents,
    selectedValue,
    setSelectedValue,
    pieChartOption,
  };
};

export default useAnalysisQuadrantsBreakdownHooks;
