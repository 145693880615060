import { Col, Row, Typography } from 'antd';
import React from 'react';
import FlightingTable from './FlightingTable';
import './MediaPlanFlighting.less';
import useMediaPlanFlighting from './useMediaPlanFlighting';

const MediaPlanFlighting = ({ data }) => {
  const { tab, tabData, onTabChange } = useMediaPlanFlighting({ data });
  return (
    <>
      <Row gutter={[20, 20]} style={{ marginBottom: '1rem' }}>
        {data?.flighting.map((flighting, idx) => {
          return (
            <Col key={idx}>
              <div
                onClick={() => onTabChange(flighting?.business_goal, flighting?.business_goal_value)}
                className={`tabs-container ${tab === flighting?.business_goal && 'activeTab-container'}`}
              >
                <Typography.Text>{flighting?.business_goal}</Typography.Text>
              </div>
            </Col>
          );
        })}
      </Row>
      {tab && <FlightingTable tab={tab} tabData={tabData} />}
    </>
  );
};

export default MediaPlanFlighting;
