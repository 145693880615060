import { css } from "@linaria/core";

const insightsContainer = css`
  padding: 20px;
  // background-color: #f5f5f5;
`;

const summarySection = css`
  margin-bottom: 20px;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const breakdownContainer = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;


export default {
  insightsContainer,
  summarySection,
  breakdownContainer,
};
