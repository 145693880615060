import { DeleteOutlined } from '@ant-design/icons';
import { Checkbox, Col, List, Modal, Row, Select } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from 'src/components/basic';
import ConditionalRender from 'src/components/conditionalRender';
import LoadingSpinner from 'src/components/loadingSpinner';
import { formatString } from 'src/lib/utils';
import { AUDIENCE_USER_ROLE_OPTIONS } from '../../constants/constants';
import AudienceAddUserForm from './AudienceAddUserForm';
import './AudienceUsersAndPermissions.less';
import useAudienceUsersAndPermissionsHook from './useAudienceUsersAndPermissionsHook';

const AudienceUsersAndPermissions = ({ data, userLists }) => {
  const {
    loading,
    userData,
    userEmail,
    addUserModal,
    setAddUserModal,
    deleteUser,
    updateUserAccess,
    configureLoading,
    formatData,
  } = useAudienceUsersAndPermissionsHook({
    data,
  });
  const { t } = useTranslation();
  if (loading) {
    return <LoadingSpinner centered={true} />;
  }
  return (
    <div className="container">
      <Row gutter={[20, 0]} justify="end" style={{ marginBottom: '1rem' }}>
        <Col>
          <PrimaryButton className="small" comfy onClick={() => setAddUserModal(true)}>
            {t('add_user')}
          </PrimaryButton>
        </Col>
      </Row>
      <Checkbox
        disabled={configureLoading}
        checked={userData.enabledForAll}
        onChange={(e) => {
          updateUserAccess('ENABLE_ALL', e.target.checked);
        }}
        style={{ marginBottom: '1rem' }}
      >
        {t('audience.enable_for_everyone_with_client_space_access')}
      </Checkbox>
      <List
        className="list"
        loading={configureLoading}
        dataSource={userData.users || []}
        header={
          <div className="listHeader">
            <div style={{ width: '37%' }}>{t('email')}</div>
            <div style={{ width: '37%' }}>{t('name')}</div>
            <div style={{ width: '26%' }}>{t('role')}</div>
          </div>
        }
        renderItem={(item) => {
          let name = '';
          if (item?.user?.first_name) {
            name = name + item?.user?.first_name;
          }
          if (item?.user?.last_name) {
            name = name + ' ' + item?.user?.last_name;
          }
          let isOwner = false;
          if (item?.role === 'OWNER') {
            isOwner = true;
          }
          return (
            <div className="listItem">
              <div style={{ width: '37%' }}>{item?.user?.email}</div>
              <div style={{ width: '37%' }}>{name}</div>
              <div style={{ width: '26%' }} className="deleteContainer">
                <ConditionalRender shouldRender={isOwner}>{t('audience.owner')}</ConditionalRender>
                <ConditionalRender shouldRender={!isOwner}>
                  {item?.user?.email === userEmail ? (
                    t('admin')
                  ) : (
                    <Select
                      className="small"
                      value={item?.role}
                      style={{ width: '50%' }}
                      onChange={(val) => {
                        updateUserAccess('UPDATE_USER', {
                          email: item?.user?.email,
                          role: val,
                        });
                      }}
                    >
                      {AUDIENCE_USER_ROLE_OPTIONS?.map((ele) => {
                        if (ele !== 'OWNER') {
                          return (
                            <Select.Option key={ele} value={ele}>
                              {formatString(ele)}
                            </Select.Option>
                          );
                        }
                      })}
                    </Select>
                  )}
                </ConditionalRender>
                <div>
                  {isOwner || item?.user?.email === userEmail ? (
                    ''
                  ) : (
                    <DeleteOutlined onClick={() => deleteUser(item?.user?.id)} />
                  )}
                </div>
              </div>
            </div>
          );
        }}
      />
      <Modal
        title={t('add_user')}
        maskClosable={false}
        destroyOnClose
        open={addUserModal}
        onCancel={() => setAddUserModal(false)}
        footer={null}
      >
        <AudienceAddUserForm
          setAddUserModal={setAddUserModal}
          audienceId={data?.id}
          formatData={formatData}
          userLists={userLists}
        />
      </Modal>
    </div>
  );
};

export default AudienceUsersAndPermissions;
