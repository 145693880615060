import { useEffect, useState } from 'react';
import { message } from 'antd';
import { fields } from '../constants';

const useQueryBuilderHook = ({ queryData, setInstaFormData, instaFormData, UpdatedAnalysisCategories }) => {
  let initialQuery = {
    combinator: 'and',
    rules: [],
    id: 'a6373d21-c5bd-4e80-bebb-54b362b2681c',
  };

  const [query, setQuery] = useState(initialQuery);
  const [loading, setLoading] = useState(false);
  const [numberOfPosts, setNumberOfPosts] = useState(null);

  const queryFieldHeader = queryData.queryType.replace(/Queries$/, '');
  const queryTitle = `${queryFieldHeader.charAt(0).toUpperCase() + queryFieldHeader.substring(1).toLowerCase()} Query ${
    queryData?.query?.key + 1
  }`;

  let updatedFields = [];
  if (queryFieldHeader === 'news') {
    updatedFields = fields.news.map((item) => {
      if (item.name === 'news_categories') {
        return {
          ...item,
          values: UpdatedAnalysisCategories,
        };
      } else {
        return item;
      }
    });
  } else {
    updatedFields = fields[queryFieldHeader] || [];
  }

  useEffect(() => {
    if (queryData?.query?.json_query) {
      setQuery(queryData?.query?.json_query);
      setNumberOfPosts(queryData?.query?.max_count);
    }
  }, [queryData]);

  const handleQueryChange = (newQuery) => {
    setQuery(newQuery);
  };

  const handleCopy = async () => {
    try {
      if (queryFieldHeader === 'instagram') {
        await navigator.clipboard.writeText(JSON.stringify(instaFormData, null, 2));
      } else {
        await navigator.clipboard.writeText(
          JSON.stringify(
            {
              query,
              max_count: numberOfPosts,
            },
            null,
            2
          )
        );
      }
      message.success('Query copied to clipboard');
    } catch (err) {
      console.error('Failed to copy text: ', err);
      message.error('Failed to copy query');
    }
  };

  const handlePaste = async () => {
    try {
      if (queryFieldHeader === 'instagram') {
        const text = await navigator.clipboard.readText();
        const pastedQuery = JSON.parse(text);
        setInstaFormData(pastedQuery);
        message.success('Query pasted successfully');
        return;
      }
      const text = await navigator.clipboard.readText();
      const pastedQuery = JSON.parse(text);
      handleQueryChange(pastedQuery?.query);
      setNumberOfPosts(pastedQuery?.max_count);
      message.success('Query pasted successfully');
    } catch (err) {
      console.error('Failed to paste text: ', err);
      message.error("Failed to paste query. Make sure it's a valid JSON.");
    }
  };

  return {
    handleQueryChange,
    query,
    setLoading,
    loading,
    numberOfPosts,
    setNumberOfPosts,
    handleCopy,
    handlePaste,
    queryFieldHeader,
    queryTitle,
    updatedFields,
  };
};

export default useQueryBuilderHook;
