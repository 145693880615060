import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default function MarkdownCodeViewer({ children, language }) {
  const code = String(children).replace(/\n$/, '');
  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(() => {
      message.success('Code copied to clipboard!');
    });
  };
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          background: '#2F2F2F',
          padding: '0.8rem 1rem',
          borderTopLeftRadius: '7px',
          borderTopRightRadius: '7px',
        }}
      >
        {language}
        <CopyOutlined onClick={() => handleCopy(code)} />
      </div>
      <SyntaxHighlighter
        language={language}
        style={dracula}
        wrapLines={true}
        customStyle={{
          padding: '1rem',
          borderBottomLeftRadius: '7px',
          borderBottomRightRadius: '7px',
          background: '#0D0D0D',
        }}
      >
        {children}
      </SyntaxHighlighter>
    </>
  );
}
