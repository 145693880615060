import { css } from '@linaria/core';

const selectFilter = css`
  position: absolute !important;
  top: 23px;
  right: -1rem;
  z-index: 10;
  background-color: var(--color-charcoal-surface-1);
  width: 21%;
  scale: 0.8;
`;

const expandButton = css`
  position: absolute !important;
  bottom: 10px;
  right: 9px;
  z-index: 10;
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  border: solid 0.5px #ffffff2e;
  border-radius: 10px;
  background-color: var(--color-charcoal-surface-2);
  .anticon {
    padding: 5px;
  }
`;

const fullScreen = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background-color: var(--color-charcoal-surface-2);
`;

const fullScreenKnowledgeGraph = css`
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100% !important;
  height: 100% !important;
  // z-index: 9999 !important;
  // background-color: white;
`;

export default {
  selectFilter,
  fullScreen,
  fullScreenKnowledgeGraph,
  expandButton,
};
